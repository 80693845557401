import { SortBy } from '@/views/Trends/components/SortBy'
import { TrendsSearchResultSortBy, categories } from '@/views/Trends/types'
import React from 'react'
import { SelectPicker } from 'rsuite'

interface IProps {
  category: number
  setCategory: (category: number) => void
  timeRange: number
  setTimeRange: (category: number) => void
  sortBy: TrendsSearchResultSortBy
  setSortBy: (category: TrendsSearchResultSortBy) => void
}

export const TabsFilters = ({ category, setCategory, timeRange, setTimeRange, sortBy, setSortBy }: IProps) => {

  const categoriesOptions = categories.map(c => (
    {
      label: c.name,
      value: c.id,
      codes: c.codes,
      exclude: c.exclude
    }
  ));

  const timeRangeOptions = [
    { value: 1, label: '15 Days' },
    { value: 2, label: '30 Days' },
    { value: 4, label: '60 Days' }
  ]

  return (
    <div className='flex justify-between'>
      <div className='flex gap-6'>
        <div className='flex gap-2 items-center'>
          <span className="text-sm">Category</span>
          <SelectPicker
            data={categoriesOptions}
            value={category}
            onChange={setCategory}
            searchable={true}
            cleanable={true}
            appearance='subtle'
            data-testid='category-picker'
          />
        </div>
        <div className='flex gap-2 items-center'>
          <span className="text-sm">Date Range</span>
          <SelectPicker
            data={timeRangeOptions}
            value={timeRange}
            onChange={setTimeRange}
            searchable={false}
            cleanable={false}
            appearance='subtle'
            data-testid='date-range-picker'
          />
        </div>
      </div>
      <SortBy selected={sortBy} onChange={setSortBy} />
    </div>
  )
}
