import React, { useEffect, useState } from 'react'
import { ICategory } from '@/views/Trends/types'

interface IProps {
  categories: ICategory[]
  defaultCategory?: ICategory
  onCategorySelect: (selectedCategory: ICategory) => void
  disabled: boolean
}

const CategoryFilter = ({ categories, defaultCategory, onCategorySelect, disabled }: IProps) => {
  const [selectedCategory, setSelectedCategory] = useState(defaultCategory || categories[0])

  useEffect(() => {
    if (defaultCategory) {
      setSelectedCategory(defaultCategory);
    }
  }, [defaultCategory]);
  
  const changeSelectedCategory = (category: ICategory) => {
    if (category.id !== selectedCategory.id && !disabled) {
      onCategorySelect(category)
      setSelectedCategory(category)
    }
  }

  const getCategoryClasses = (categoryId: number) => {
    const isSelected = categoryId === selectedCategory.id;
    
    const baseClass = isSelected ? 'font-extrabold' : 'font-bold';
    const disabledClass = disabled
      ? isSelected
        ? 'bg-sightlyPurple/5 border-l-4 border-l-sightlyPurple/50 text-sightlyPurple/50'
        : 'text-gray-300 bg-gray-50/50'
      : isSelected
        ? 'bg-sightlyPurple/10 border-l-4 border-l-sightlyPurple text-sightlyPurple'
        : 'hover:bg-gray-100 cursor-pointer';
  
    return `${baseClass} ${disabledClass}`;
  };  

  return (
    <ol data-testid="category-filter-option-list" className='w-full h-full flex flex-col overflow-y-auto'>
      {categories.map((category) => (
        <li
          data-testid={`category-filter-option-${category.name.split(' ').join('-')}`}
          key={`category-filter-option-${category.id}`}
          className={`max-h-16 text-truncate flex items-center px-6 py-4 border border-gray-200 ${getCategoryClasses(category.id)}`}
          onClick={() => changeSelectedCategory(category)}
        >
          {category.name}
        </li>
      ))}
    </ol>
  )
}

export default CategoryFilter
