import React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { TrendsSearchResultSortBy } from '@/views/Trends/types'

type MenuItem = {
    name: string,
    value: TrendsSearchResultSortBy
}

let menuItems: MenuItem[] = [
    {
        name: 'Similarity/recommended',
        value: 'similarityRecommended'
    },
    {
        name: 'Date (newest)',
        value: 'createdAtDesc'
    }
]

export function SortBy({
    selected,
    onChange,
    buttonClass
}: {
    selected: TrendsSearchResultSortBy
    onChange: (val: TrendsSearchResultSortBy) => void
    buttonClass?: string
}) {
    return (
        <div data-testid="anticipation-boards-sortBy-button">
            <Menu
                as="div"
                className="relative inline-block text-left"
            >
                <div>
                    <Menu.Button
                        id="trendsSerchResultSortByButton"
                        className={`${buttonClass} hover:bg-slate-100 flex justify-between gap-2 rounded-md text-grey-900 px-2 py-2 text-sm font-medium border-grey-900 
border  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke={'grey'}
                            className="ml-2 -mr-1 h-5 w-5"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
                            />
                        </svg>
                        Sort By
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="z-50 absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1 ">
                            {menuItems.map((item, index) => {
                               return <Menu.Item key={`menu-item-${index}`}>
                                   {({ active }) => (
                                     <button
                                       onClick={() => onChange(item.value)}
                                       className={`${
                                         active ? 'bg-slate-100' : 'text-gray-900'
                                       } pendo-${item.value}-sort-button first-letter:group flex w-full items-center justify-between rounded-md px-2 py-2 text-sm`}
                                     >
                                         {item.name}
                                         {selected === item.value && (
                                           <CheckIcon className="text-sightly-blue mr-2 h-5 w-5" />
                                         )}
                                     </button>
                                   )}
                               </Menu.Item>
                            })}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}
