import React, { useEffect } from 'react'
import { Divider, Loader, Tooltip, Whisper } from 'rsuite'
import SearchBox from '@/components/SearchBox'
import { useGetTrendMoments, useGetTrendNarratives, useTrendById } from '../hooks/useTrends'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import SightlyButton from '@/components/Sightly/SightlyButton'
import { IMoment, INarrative } from '@/views/Trends/types'
import NoImageFoundPath from '@/assets/img/NoImageFound.svg'
import { trendTopicController } from '../controllers/TrendTopicController'

interface MomentViewSlideOverProps {
  trendId?: string
  dataType: 'Moments' | 'Narratives'
}

const TrendMomentViewSlideOver: React.FC<MomentViewSlideOverProps> = ({ trendId, dataType }) => {
  const { trendQuery } = useTrendById(trendId!)

  const [dataByTrend, setDataByTrend] = React.useState<Record<string, { data: any[]; totalPages: number }>>({})
  const [loading, setLoadingState] = React.useState<boolean>(false)

  const [search, setSearch] = React.useState<string>()

  const [dataPages, setDataPages] = React.useState<Record<string, number>>({
    [trendId!]: 1
  })

  const dataQuery =
    dataType === 'Moments'
      ? useGetTrendMoments(trendId!, search, dataPages[trendId!] || 1, 20).momentsQuery
      : useGetTrendNarratives(trendId!, dataPages[trendId!] || 1, 20).narrativesQuery

  useEffect(() => {
    if (dataType === 'Moments') {
      trendTopicController.getMomentsMethod(trendId!, search, 1, setDataByTrend, setLoadingState)
    } else {
      trendTopicController.getNarrativesMethod(trendId!, search, 1, setDataByTrend, setLoadingState)
    }
    setDataPages((prev) => ({ ...prev, [trendId!]: 1 }))
  }, [trendId, search])

  const handlePageChange = (trendId: string, newPage: number) => {
    setDataPages((prev) => ({ ...prev, [trendId]: newPage }))
    if (dataType === 'Moments') {
      trendTopicController.getMomentsMethod(trendId, search, newPage, setDataByTrend, setLoadingState)
    } else {
      trendTopicController.getNarrativesMethod(trendId, search, newPage, setDataByTrend, setLoadingState)
    }
  }

  const retrievedData = dataByTrend[trendId!]?.data || []

  const tooltip = (name: string) => (
    <Tooltip
       placement="top"
       className="max-w-[250px] p-2 rounded-md">
         {name}
     </Tooltip>
   ); 

  return (
    <div className="mt-[-2vh]">
      <Divider className="mb-[2vh]" />
      <div className="flex flex-col gap-4">
        <SearchBox
          dataTestId="trend-moment-search-box"
          handleSearch={setSearch}
          placeholder="Search by name"
          className="min-w-[25vw] w-[80%]"
        />

        <div className="my-3 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg min-w-full">
          { dataType == "Moments" ? (
            <table className="table-fixed bg-gray-50 w-full divide-y divide-gray-300">
              <thead className="text-[0.5rem] w-full">
                <tr className="text-gray-400 uppercase w-full">
                  <th className="py-3.5 w-2/12"></th>
                  <th className="text-left py-3.5 w-4/12">Trend {'>'} Moments</th>
                  <th className="py-3.5 w-2/12">Articles</th>
                  <th className="py-3.5 w-2/12">Keywords</th>
                  <th className="py-3.5 w-2/12">Videos</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {trendQuery.data ? (
                  <React.Fragment key={trendQuery.data?.data.id}>
                      <tr>
                        <td colSpan={5}>
                          <div className="pl-3 max-h-[60vh] overflow-y-auto w-full">
                            <table className="table-fixed w-full">
                              <tbody className="divide-y divide-gray-200 w-full">
                                {retrievedData.map((item: IMoment) => (
                                  <tr key={item.id} className="w-full">
                                    <td className="py-3.5 flex justify-center">
                                      <img
                                        src={item.imageUrl || NoImageFoundPath}
                                        alt={item.name}
                                        className="w-[4vw] aspect-square rounded-full object-cover"
                                        onError={(e) => {
                                          const target = e.target as HTMLImageElement;
                                          target.onerror = null;
                                          target.src = NoImageFoundPath;
                                        }}
                                      />

                                    </td>
                                    <td className="text-left pl-0.5 py-3.5 w-4/12 truncate">
                                    <Whisper placement="top" controlId="control-id-focus" trigger="hover" speaker={tooltip(item.name)}>
                                      <div className="line-clamp-2 block" data-tip={item.name}>
                                        {item.name}
                                      </div>
                                    </Whisper>
                                    </td>
                                    <td className="text-center py-3.5 w-2/12">
                                      {item.articleCount || 0}
                                    </td>
                                    <td className="text-center py-3.5 w-2/12">
                                      {item.keywordCount || 0}
                                    </td>
                                    <td className="text-center py-3.5 w-2/12">
                                      {item.videoCount || 0}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div className="flex justify-center p-4">
                              <SightlyButton
                                datatestid="load-more-moments-button"
                                block
                                id={`loadmorebutton-${trendQuery.data?.data.id}`}
                                text="Load More"
                                handleClick={() =>
                                  handlePageChange(trendQuery.data?.data.id!, dataPages[trendQuery.data?.data.id!] + 1)
                                }
                                loading={dataQuery.isLoading}
                                disabled={
                                  dataQuery.isLoading ||
                                  dataPages[trendQuery.data?.data.id!] >= dataQuery.data!.totalPages
                                }
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                  </React.Fragment>
                ) : (
                  <tr>
                    <td colSpan={5}>
                      <div className="h-full flex items-center justify-center">
                        <Loader
                          size="md"
                          content="Loading..."
                          center
                        />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          ) : (
            <table className="table-fixed bg-gray-50 w-full divide-y divide-gray-300">
            <thead className="text-[0.5rem] w-full">
              <tr className="text-gray-400 uppercase w-full">
                <th className="pl-3 text-left py-3.5 w-4/12">Narrative name</th>
                <th className="py-3.5 w-2/12">Posts</th>
                <th className="py-3.5 w-2/12">Platforms</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {trendQuery.data ? (
                <React.Fragment key={trendQuery.data?.data.id}>
                    <tr>
                      <td colSpan={3}>
                        <div className="pl-3 max-h-[60vh] overflow-y-auto w-full">
                          <table className="table-fixed w-full">
                            <tbody className="divide-y divide-gray-200 w-full">
                              {retrievedData.map((item: INarrative) => (
                                <tr
                                  key={item.id}
                                  className="w-full" 
                                >
                                 <td className="text-left pl-0.5 py-3.5 w-4/12">
                                    <Whisper placement="top" controlId="control-id-focus" trigger="hover" speaker={tooltip(item.name)}>
                                      <div className="line-clamp-2 block" data-tip={item.name}>
                                        {item.name}
                                      </div>
                                    </Whisper>
                                    </td>
                                  <td className="text-center py-3.5 w-2/12">
                                    {item.postCount || 0}
                                  </td>
                                  <td className="text-center py-3.5 w-2/12">
                                    {item.sources.length || 0}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div className="flex justify-center p-4">
                            <SightlyButton
                              datatestid="load-more-moments-button"
                              block
                              id={`loadmorebutton-${trendQuery.data?.data.id}`}
                              text="Load More"
                              handleClick={() =>
                                handlePageChange(trendQuery.data?.data.id!, dataPages[trendQuery.data?.data.id!] + 1)
                              }
                              loading={dataQuery.isLoading}
                              disabled={
                                dataQuery.isLoading ||
                                dataPages[trendQuery.data?.data.id!] >= dataQuery.data!.totalPages
                              }
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                </React.Fragment>
              ) : (
                <tr>
                  <td colSpan={3}>
                    <div className="h-full flex items-center justify-center">
                      <Loader
                        size="md"
                        content="Loading..."
                        center
                      />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  )
}

export default TrendMomentViewSlideOver
