import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { ChevronUpIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { RepeatFrequencyEnumType } from '../reportGenerator-types'


const REPEAT_FREQUENCY_OPTIONS = [
  { label: 'Day', value: 'day' },
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' }
]

function ScheduleRepeatEvery(props: { 
  repeatInterval: number,
  setRepeatInterval: React.Dispatch<React.SetStateAction<number>>,
  repeatFrequency: RepeatFrequencyEnumType,
  setRepeatFrequency: React.Dispatch<React.SetStateAction<RepeatFrequencyEnumType>>,
}) {
  const { repeatInterval, setRepeatInterval, repeatFrequency, setRepeatFrequency } = props

  return (
    <div
        className="mb-9 sm:px-1 text-grey-900 w-full flex flex-row items-center"
        data-testid="repeat-every"
    >
        <div className="mb-2 font-medium" id='pendo-act-mgr-assignees-filter'>Repeat every</div>
        <div className="flex items-center mx-4">
            <input 
                className="w-[52px] h-[42px] p-[12px_10px] gap-[10px] rounded-[8px] bg-gray-200 hover:bg-gray-300 border-none text-center" 
                value={repeatInterval}
                onChange={(e) => setRepeatInterval(parseInt(e.target.value))}
                min={1}
                step={1}
                data-testid="repeat-interval-input"
            />
            <div className="flex flex-col ml-4">
                <ChevronUpIcon
                    className="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                    onClick={() => setRepeatInterval(repeatInterval + 1)}
                    data-testid="repeat-interval-up-icon"
                />
                <ChevronDownIcon
                    className="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                    onClick={() => repeatInterval > 1 && setRepeatInterval(repeatInterval - 1)}
                    data-testid="repeat-interval-down-icon"
                />
            </div>
        <div className="relative">
            <select
                className="ml-4 w-[117px] gap-0 rounded-[6px] border-t border-l border-b border-gray-300 shadow-[0px_1px_2px_0px_#0000000D]"
                value={repeatFrequency}
                onChange={(e) => setRepeatFrequency(e.target.value as RepeatFrequencyEnumType)}
                data-testid="repeat-frequency-select"
            >
                {REPEAT_FREQUENCY_OPTIONS.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
                </select>
            </div>
        </div>
    </div>
  )
}

export default ScheduleRepeatEvery
