import React, { useEffect } from 'react';
import { Tabs } from 'flowbite-react';
import SankeyGraphComponent from '@/views/Trends/components/SankeyGraphComponent';
import ArticleResultsList from '@/views/Trends/TrendDetails/components/ArticleResultsList';
import SocialPostResultsList from '@/views/Trends/TrendDetails/components/SocialPostResultsList';
import {ArticleResultsResponse, IArticle, ISanKeyGraph, NarrativeResultsResponse, INarrative } from '@/views/Trends/types';

interface DetailDisplaySectionsProps {
  articles: IArticle[];
  socialPosts: INarrative[];
  sanKeyData?: {
    nodes: {
      type: string;
      name: string;
    }[];
    links?: {
      value: number;
      source: number;
      target: number;
    }[];
  };
}

const DetailDisplaySections = ({ articles, socialPosts, sanKeyData }: { 
  articles: IArticle[], 
  socialPosts: INarrative[],
  sanKeyData?: {
    nodes: {
      type: string;
      name: string;
    }[];
    links?: {
      value: number;
      source: number;
      target: number;
    }[];
  };
 }) => {

  const sampleArticleResponse: ArticleResultsResponse = {
    articles: articles,
    meta: {
      total: articles.length,
      page: 1
    }
  }

  const narrativesResponse: NarrativeResultsResponse = {
    narratives: socialPosts,
    meta: {
      total: socialPosts.length,
      page: 1
    }
  }


  return (
    <div className='w-full h-full'>
      <Tabs aria-label="Default tabs"
        style="underline"
        theme={{
          tablist: {
            tabitem: {
              base: 'flex items-center justify-center p-4 text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 focus:ring-0  focus:outline-none rounded-t-lg border-b-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-600 border-bottom-color:sightlyPurple',
            },
          },
        }}
      >
       
          <Tabs.Item active title={"Trend Breakdown"} data-testid="detail-page-trend-breakdown-tab">
            <div>
            {sanKeyData !== undefined && (
              <SankeyGraphComponent data={sanKeyData} />
            )}
            </div>
          </Tabs.Item>
        <Tabs.Item active title={"Articles"} data-testid="detail-page-articles-tab">
          <div>
            <ArticleResultsList articles={sampleArticleResponse.articles} meta={sampleArticleResponse.meta} />
          </div>
        </Tabs.Item>
        <Tabs.Item active title={"Social Posts"} data-testid="detail-page-social-posts-tab">
          <div>
            <SocialPostResultsList narratives={narrativesResponse.narratives} meta={narrativesResponse.meta}/>
          </div>
        </Tabs.Item>
      </Tabs>
    </div>
  )
}

export default DetailDisplaySections;
