import { listBuilderAxios } from "@/axiosInstances"
import { AnalyzeTrendParams, IArticle, IBrand, IMomentTimeline, INarrative, IPeople, ITrend, ITrendMetric, articleSchema, brandsSchema, momentTimelineSchema, narrativeSchema, peopleSchema, trendMetricSchema, ISanKeyGraph, graphDataSchema  } from "@/views/Trends/types"

export const trends = {
    get: async (search?: string) => {
      let url = `/trend-analysis/trends${search ? `?prompt=${search}` : ''}`
      return listBuilderAxios.get<ITrend[]>(url)
    },

    getById: async (trendId: string) => {
      const url = `/trend-analysis/trends/${trendId}`
      return listBuilderAxios.get<ITrend>(url)
    },

    getPeople: async (trendId: string) => {
      const url = `/trend-analysis/trends/${trendId}/people`
      const result = await listBuilderAxios.get<IPeople[]>(url)
      return result.data.map(person => peopleSchema.parse(person))
    },

    getBrands: async (trendId: string) => {
      const url = `/trend-analysis/trends/${trendId}/brands`
      const result = await listBuilderAxios.get<IBrand[]>(url)
      return result.data.map(brand => brandsSchema.parse(brand)) },

    getNarratives: async (trendId: string) => {
      const url = `/trend-analysis/trends/${trendId}/narratives`
      const result = await listBuilderAxios.get<INarrative[]>(url)
      return narrativeSchema.parse(result.data)
    },

    getMoments: async (trendId: string) => {
      const url = `/trend-analysis/trends/${trendId}/moments`
      const result = await listBuilderAxios.get<INarrative[]>(url)
      return narrativeSchema.parse(result.data)
    },

    getArticles: async (trendId: string) => {
      const url = `/trend-analysis/trends/${trendId}/moment-articles`
      const result = await listBuilderAxios.get<IArticle[]>(url)
      return result.data.map(article => articleSchema.parse(article))
    },
    
    getSankeyData: async (trendId: string) => {
      const url = `/trend-analysis/trends/${trendId}/sankey`
      const result = await listBuilderAxios.get<ISanKeyGraph>(url)
      return graphDataSchema.parse(result.data)
    },

    getMomentTimeline: async (trendId: string) => {
      const url = `/trend-analysis/trends/${trendId}/cluster-timeline`
      const result = await listBuilderAxios.get<IMomentTimeline>(url)
      return momentTimelineSchema.parse(result.data)
    },

    getSocialPosts: async (trendId: string) => {
      const url = `/trend-analysis/trends/${trendId}/narrative-posts`
      const result = await listBuilderAxios.get<INarrative[]>(url)
      return result.data.map(socialPost => narrativeSchema.parse(socialPost))
    },

    getArticlesMetrics: async (trendId: string) => {
      const url = `/trends/${trendId}/metrics?type=articles`
      const result = await listBuilderAxios.get<ITrendMetric[]>(url)
      return result.data.map(trendMetric => trendMetricSchema.parse(trendMetric))
    },

    getSentimentScore: async (trendId: string) => {
      const url = `/trend-analysis/trends/${trendId}/sentiment-score`
      const result = await listBuilderAxios.get<Number>(url)
      return result.data;
    },

    analyzeTrend: async (params: AnalyzeTrendParams) => {
      const url = `/analysis/analyze`
      return listBuilderAxios.post(url, params)
    }
}