import { IABCategory } from '@/views/Engage/Segments/schemas/SegmentSchema';


export enum SortOrder {
    ASC = 'asc',
    DESC = 'desc',
    EMPTY = ''
  }

export type SortState = {
  column: string;
  direction: SortOrder;
};

export interface SegmentConfiguration {
    name: string,
    description: string,
    keywords: string[],
    iabCategories: IABCategory[],
}