import React, { useEffect, useRef, useState } from 'react';
import { RelevanceClassificationBadge } from '@/views/Discover/Moments/v2/components/RelevanceClassificationBadge';
import { TopicTrendCardType, RelevanceClassification } from '@/views/Trends/types';
import { formatDateToShortMonthDayYear } from '@/utils';
import NoImageFoundPath from '@/assets/img/NoImageFound.svg';
import { routes } from '@/routes';
import { useNavigate } from '@tanstack/react-location';

interface IProps {
  id: string;
  type: TopicTrendCardType;
  title: string;
  description: string;
  relevance: RelevanceClassification;
  date: string;
  image?: string;
  lazy?: boolean;
}

const TopicTrendCard: React.FC<IProps> = ({
  id,
  type,
  title,
  description,
  relevance,
  date,
  image,
  lazy = false,
}) => {
  const navigate = useNavigate();
  const showRelevance = false;
  const [isVisible, setIsVisible] = useState(!lazy);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!lazy || isVisible) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) observer.observe(ref.current);

    return () => observer.disconnect();
  }, [lazy, isVisible]);

  return (
    <div
      ref={ref}
      data-testid="topic-trend-card"
      className='text-white rounded-md aspect-square overflow-hidden drop-shadow-lg cursor-pointer'
      onClick={() => navigate({ to: type === 'Topic' ? `${routes.app.trends.topicDetails.path}/${id}` : `${routes.app.trends.trendDetails.path}/${id}` })}
    >
      {isVisible && (
        <>
          <div
            data-testid="topic-trend-card-image"
            className="relative h-1/2 bg-cover bg-center rounded-t-md flex flex-col justify-between"
            style={{ backgroundImage: `url(${image || NoImageFoundPath})` }}
            >
            <div className="px-4 pt-4">
              {showRelevance && (<div className="bg-white py-1 px-2 rounded-lg w-fit">
                <RelevanceClassificationBadge relevanceClassification={relevance} />
              </div>)}
            </div>
            <div className="px-4 pb-1 text-xs font-medium bg-gradient-to-t from-[#130240] flex gap-2">
              <span data-testid="topic-trend-card-type">{type}</span>
              <span> • </span>
              <span data-testid="topic-trend-card-date">
                {formatDateToShortMonthDayYear(date)}
              </span>
            </div>
          </div>
          <div className="h-1/2 px-4 pb-4 bg-[#130240] rounded-b-md flex flex-col">
            <span data-testid="topic-trend-card-title" className="text-base font-bold">
              {title}
            </span>
            <p
              data-testid="topic-trend-card-description"
              className="font-semibold text-white/60 text-xs xl:text-sm leading-relaxed line-clamp-2 2xl:line-clamp-3"
            >
              {description}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default TopicTrendCard;
