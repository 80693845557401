import noFoundIcon from '@/assets/img/socialPosts/noConversationIcon.svg';
import React from 'react';

interface NoFoundMessageProps {
    title: string;
    subtitle: string;
}

export default function NoFoundMessage({ title, subtitle }: NoFoundMessageProps) {
    return <div
        data-testid="no-data-found"
        className="h-[50%] place-self-center flex justify-center items-center flex-col w-full"
    >
        <img
            src={noFoundIcon}
            alt="No Found Icon"
            className="mb-6 mr-2"
        />
        <div className="text-2xl font-semibold text-sightlyPurple">
            {title}
        </div>
        <div className="text-xl text-sightlyPurple">
            {subtitle}
        </div>
    </div>
}
