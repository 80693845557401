import React from 'react';
import { SearchResult } from '@/views/Trends/types';
import { capitalizeFirstLetter } from '@/utils';

interface ITabCountData {
    tab: SearchResult;
    count: number;
}

interface IProps {
    tabsCountData: ITabCountData[];
}

const TotalsOverview = ({ tabsCountData }: IProps) => {
    const getClasses = (index: number) => {
        const classes = (() => {
            switch (index) {
                case 0:
                    return 'w-2/3 bg-[#4F46E5]';
                case 1:
                    return 'w-1/2 bg-[#7B61FF]';
                default:
                    return 'col-span-1 bg-[#48C294]';
            }
        })();
        return classes;
    };

    const data = [tabsCountData.slice(0, 2), tabsCountData.slice(2)];

    const CountData = ({
        tabCountData,
        index,
    }: {
        tabCountData: ITabCountData;
        groupIndex: number;
        index: number;
    }) => (
        <div
            className={`rounded-3xl flex py-2 flex-col justify-center items-center
                ${getClasses(index)}`}
            data-testid={`count-data-${index}`}>
            <p className='text-sm lg:text-base text-center'>
                <span data-testid={`tab-${index}`}>{capitalizeFirstLetter(tabCountData.tab)}s</span><br></br>
                <span data-testid={`count-${index}`} className="text-lg lg:text-xl font-bold">{tabCountData.count}</span>
            </p> 
        </div>
    );

    return (
        <div className="w-full h-full text-white flex flex-col items-center justify-center gap-2">
            {data.map((group, groupIndex) => (
                <div
                    className={`w-full ${groupIndex % 2 === 0 ? 'flex flex-col gap-2' : 'grid grid-cols-2 gap-4'} items-center`}
                    key={`totals-overview-group-${groupIndex}`}
                    data-testid={`totals-overview-group-${groupIndex}`}
                >
                    {group.map((tabCountData, index) => (
                        <CountData
                            key={`totals-overview-group-${groupIndex}-data-${index}`}
                            tabCountData={tabCountData}
                            groupIndex={groupIndex}
                            index={groupIndex * 2 + index}
                            data-testid={`totals-overview-group-${groupIndex}-data-${index}`}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
};

export default TotalsOverview;
