
import React, { useMemo, useState } from "react";
import { Modal } from "rsuite";
import ScheduleEmail from "@/views/ReportGenerator/components/ScheduleEmail";
import ScheduleCustomSubjectAndMessage from "@/views/ReportGenerator/components/ScheduleCustomSubjectAndMessage";
import ScheduleDateTime from "@/views/ReportGenerator/components/ScheduleDateTime";
import ScheduleRepeatEvery from "@/views/ReportGenerator/components/ScheduleRepeatEvery";
import ScheduleOnDay from "@/views/ReportGenerator/components/ScheduleOnDay";

import { reportGeneratorApi } from '@/views/ReportGenerator/reportGenerator-api';
import { useMutation } from "@tanstack/react-query";
import Tooltip from "@/components/TailwindTooltip";
import { DaysOfWeekEnumType, RepeatFrequencyEnumType, ReportScheduleSchema } from "../reportGenerator-types";

interface IProps {
    isVisible: boolean;
    setIsVisible: (visible: boolean) => void;
}


export default function ScheduleReportModal({ isVisible, setIsVisible }: IProps) {
    const [emails, setEmails] = useState<string[]>([])

    const [customEmail, setCustomEmail] = useState<boolean>(false)
    const [customEmailSubject, setCustomEmailSubject] = useState<string>('')
    const [customEmailMessage, setCustomEmailMessage] = useState<string>('')
        
    const [startDate, setStartDate] = useState<Date>(new Date())
    const [selectedTimezone, setSelectedTimezone] = useState<string>('America/New_York')

    const [repeatInterval, setRepeatInterval] = useState<number>(1)
    const [repeatFrequency, setRepeatFrequency] = useState<RepeatFrequencyEnumType>('week')

    const [daysOfWeek, setDaysOfWeek] = useState<DaysOfWeekEnumType[]>([])

    const initialMeridian = new Date().getHours() >= 12 ? 'PM' : 'AM';
    const [meridian, setMeridian] = useState<'AM' | 'PM'>(initialMeridian);

    const resetScheduleData = () => {
        setCustomEmail(false)
        setEmails([])
        setStartDate(new Date())
        setSelectedTimezone('America/New_York')
        setRepeatInterval(1)
        setRepeatFrequency('week')
        setDaysOfWeek([])
    }

    const handleCancelSchedule = () => {
        setIsVisible(false)
        resetScheduleData()
    }

    const scheduleReportData = useMemo(() => ({
        emails,
        ...(customEmail && { subject: customEmailSubject }),
        ...(customEmail && { message: customEmailMessage }),
        startDate,
        selectedTimezone,
        repeatInterval,
        repeatFrequency,
        daysOfWeek,
    }), [emails, customEmail, customEmailSubject, customEmailMessage, startDate, selectedTimezone, repeatInterval, repeatFrequency, daysOfWeek]);

    const { mutate: scheduleReport } = useMutation({
        mutationFn: () => {
            return reportGeneratorApi.setReportSchedule(scheduleReportData);
        },
    });

    const handleApplySchedule = () => {
        setIsVisible(false);

        scheduleReport();

        resetScheduleData();
    }
    
    const toggleCustomEmail = () => {
        setCustomEmail(!customEmail)
        setCustomEmailSubject('')
        setCustomEmailMessage('')
    }

    const hasEnoughData = ReportScheduleSchema.safeParse(scheduleReportData);

    const errorMessage = "Please complete all fields to apply the schedule.";

    return (
        <Modal show={isVisible} size="lg" onHide={handleCancelSchedule} data-testid="schedule-report-modal">
            <Modal.Header className="px-1">
                <Modal.Title>Schedule Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div
                    className="mb-9"
                    data-testid="email-and-custom-subject-and-message"
                >
                    <ScheduleEmail
                        emails={emails}
                        setEmails={setEmails}
                    />
                    <ScheduleCustomSubjectAndMessage
                        customEmail={customEmail}
                        setCustomEmail={setCustomEmail}
                        customEmailSubject={customEmailSubject}
                        setCustomEmailSubject={setCustomEmailSubject}
                        customEmailMessage={customEmailMessage}
                        setCustomEmailMessage={setCustomEmailMessage}
                        toggleCustomEmail={toggleCustomEmail}
                    />
                </div>

                <ScheduleDateTime
                    startDate={startDate}
                    setStartDate={setStartDate}
                    selectedTimezone={selectedTimezone}
                    setSelectedTimezone={setSelectedTimezone}
                    meridian={meridian}
                    setMeridian={setMeridian}
                />

                <ScheduleRepeatEvery
                    repeatInterval={repeatInterval}
                    setRepeatInterval={setRepeatInterval}
                    repeatFrequency={repeatFrequency}
                    setRepeatFrequency={setRepeatFrequency}
                />

                <ScheduleOnDay
                    daysOfWeek={daysOfWeek}
                    setDaysOfWeek={setDaysOfWeek}
                />
            </Modal.Body>
            <Modal.Footer>
                <button
                    className={`px-4 py-2 rounded-md text-purple-600 hover:text-purple-700 mr-5`}
                    onClick={handleApplySchedule}
                    data-testid="cancel-button"
                >
                    Cancel
                </button>
                <button
                    className={`px-4 py-2 rounded-md text-white ${!hasEnoughData.success
                        ? 'bg-gray-400 cursor-not-allowed'
                        : 'bg-purple-600 hover:bg-purple-700'
                        }`}
                    onClick={handleApplySchedule}
                    data-testid="apply-button"
                    disabled={!hasEnoughData.success}
                >
                    <Tooltip
                        disabled={hasEnoughData.success}
                        content={
                            <div
                                data-testid="apply-button-tooltip"
                            >
                                {errorMessage}
                            </div>
                        }
                    >
                        <div>
                            Apply
                        </div>
                    </Tooltip>
                </button>
            </Modal.Footer>
        </Modal>
    );
}
