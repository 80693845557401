import React from 'react'

const AdGroupRow = (props: {
  adGroup: any
}) => {
  const { adGroup } = props
  return (
    <tr
      key={adGroup.id}
      className={'bg-gray-50 fade-in animate-in'}
      data-testid="adgroup-row"
      id="adgroup-row"
    >
      <td></td>
       <td className={'whitespace-nowrap py-4 text-sm font-medium truncate flex gap-2 pl-2'}>
        <div
          data-testid="adgroup-id-text"
          id="adgroup-id-text"
          className="truncate"
        >
          {adGroup.adgroupId}
        </div>
      </td>
      <td
        data-testid="adgroup-row-name"
        id="adgroup-row-name"
        className="text-sm text-gray-500 whitespace-nowrap"
      >
        {adGroup.adgroupName}
      </td>
    </tr>
  )
}

export default AdGroupRow
