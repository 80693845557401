import { useQuery } from '@tanstack/react-query';
import { rqKeys } from '@/ReactQueryKeyFactory';
import { api } from '@/api/api';

const getLinkedInsertionOrderIds = async (brandProfileId: number | undefined) => {
    if (!brandProfileId) return []
    const ios = await api.brandProfile.ios.getIds(brandProfileId)

    return ios || []
}

export default function useBrandProfileInsertionOrderIds(brandProfileId: number | undefined) {
    const queryResult = useQuery(['useBrandProfileInsertionOrderIds', brandProfileId], () => getLinkedInsertionOrderIds(brandProfileId), {
        enabled: !!brandProfileId
    })

    return queryResult
}
