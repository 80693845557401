import React from 'react'
import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox'
import { ResponsiveContainer, AreaChart, XAxis, Area, Tooltip as RechartTooltip } from 'recharts'
import { Link } from '@tanstack/react-location'
import { accentColor } from '@/assets/jss/colorConstants'
import { INarrative, IAreaChartData } from "@/views/Discover/Boards/types"
import PlatformIcon from "@/views/Discover/Boards/components/conversations/PlatformIcon"
import Chip, { ChipSize } from "@/views/Discover/Boards/components/conversations/Chip"
import ChartTooltip from './ChartTooltip'
import { SmallBadge } from '@/components/SmallClosableBadge'
import Tooltip from '@/components/TailwindTooltip'

interface IProps {
  narrative: INarrative,
  areaChartData: IAreaChartData[]
  narrativeIsLoading: boolean
  checked: boolean
  handleSelectNarrative: Function
  toggleSocialNarrativePanel: Function
}

const SocialNarrativeCard: Function = React.memo(
  ({ narrative, areaChartData, narrativeIsLoading, checked, handleSelectNarrative, toggleSocialNarrativePanel }: IProps) => {
    const top3Platforms = narrative.sourceTypeCountJson.sort((a, b) => b.postCount - a.postCount).slice(0, 3)

    return (
      <div
        data-testid="anticipation-boards-social-narrative-card"
        key={narrative.id}
        className="hover:z-50 animate-in fade-in rounded-xl bg-white px-2 xl:px-0 py-4 border-[0.5px] border-gray-200 drop-shadow-lg grid min-w-fit w-full grid-cols-1 divide-y xl:grid-cols-5 xl:divide-y-0 xl:divide-x divide-slate-300 h-full"
      >
        {/* Left section */}
        <div className="flex flex-row col-span-3 gap-6 px-4 py-2 xl:py-0">
          <div
            data-testid="anticipation-boards-social-narrative-card-checkbox"
            className="h-fit w-fit place-self-start">
            <SightlyCheckbox
              id={narrative.id}
              disabled={narrativeIsLoading}
              checked={checked}
              handleChange={(bool: boolean) => {
                handleSelectNarrative(bool, narrative)
              }}
            />
          </div>
          <div className="flex flex-col gap-2 cursor-pointer"
            onClick={() => toggleSocialNarrativePanel(narrative.id)}
          >
            <div>
              <span data-testid="anticipation-boards-social-narrative-card-title" className="text-xs font-bold xl:text-sm line-clamp-1">{narrative.name}</span>
            </div>
            <div data-testid="anticipation-boards-social-narrative-card-themes"
              className="flex flex-row flex-wrap gap-2">
              {narrative.themeList.slice(0, 3).map((theme, index) => {
                return (
                  <Chip key={index} dataTestId={`anticipation-boards-social-narrative-card-themes-${theme}`} text={theme} size={ChipSize.SMALL}></Chip>)
              })}
              {narrative.themeList.length > 3 && (
                <Tooltip content={narrative.themeList.slice(4).join(', ')}>
                  <div className="text-xs">
                    <SmallBadge
                      dataTestId={'anticipation-boards-social-narrative-card-extra-themes'}
                      onClose={() => undefined}
                      text={`+${narrative.themeList.length - 3}`}
                      disabled={true}
                      bgColor='bg-purple-100'
                      border='border border-purple-600 text-purple-600'
                    />
                  </div>
                </Tooltip>

              )}
            </div>
            <div className='flex flex-col justify-between grow'>
              {narrative?.topPostJSON && <div
                data-testid="anticipation-boards-social-narrative-card-top-post"
                className="grow grid grid-cols-[1.75rem_minmax(0,_1fr)] gap-2">
                <PlatformIcon platformName={narrative.topPostJSON.sourceTypeList[0]} width="100%"></PlatformIcon>
                <p data-testid="anticipation-boards-social-narrative-card-top-post-description" className="line-clamp-2 lg:line-clamp-3 text-gray-500 md:text-[.7rem] lg:text-[.8rem]">
                  {narrative.topPostJSON.post}
                </p>
              </div>}
              <Link
                className='mt-2'
                data-testid="anticipation-boards-social-narrative-card-view-more-posts"
                style={{ color: accentColor, fontWeight: 600, fontSize: 10 }}
                to={''}
              >
                View more posts
              </Link>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 col-span-2 px-8 py-2 xl:py-0">
          {/* Middle section */}
          <div data-testid="anticipation-boards-social-narrative-card-total-posts" className="flex flex-col justify-between col-span-2 px-4">
            <div>
              <p className="font-bold text-gray-500 text-[.8rem]">Total posts<br></br><span className="text-xl font-bold text-gray-500">{narrative.postCount}</span></p>
            </div>
            <div className='aspect-[1/0.25]'>
              <ResponsiveContainer>
                <AreaChart
                  margin={{ bottom: -10 }}
                  data={areaChartData}
                >
                  <XAxis
                    dataKey="date"
                    axisLine={false}
                    tickLine={false}
                    style={{
                      fontSize: '8px'
                    }}
                  />
                  <Area
                    type="linear"
                    dataKey="posts"
                    stroke={'#651AEB'}
                    fill={'#ECE3FC'}
                  />
                  <RechartTooltip content={<ChartTooltip />} allowEscapeViewBox={{ x: true, y: true }} wrapperStyle={{ zIndex: 1000 }} />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
          {/* Right section */}
          <div data-testid="anticipation-boards-social-narrative-card-top-platforms" className="col-span-1">
            <p className="font-bold text-gray-500 text-[.8rem]">Top platforms</p>
            <p></p>
            <div className="flex flex-col gap-2 text-xs font-bold text-gray-500">
              {top3Platforms.map((topPlatform, index) => {
                return (
                  <div key={index} className="flex flex-row items-center gap-4">
                    <PlatformIcon platformName={topPlatform.sourceType} width="1.25rem"></PlatformIcon>
                    <p data-testid={`anticipation-boards-social-narrative-card-top-platform-${topPlatform.sourceType.replace(/[^a-zA-Z0-9]/g, '')}-post-count`}>{topPlatform.postCount} posts</p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
)

export default SocialNarrativeCard
