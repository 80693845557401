import ResultCardList from "@/views/Trends/SearchResults/components/ResultCardList";
import { ITrend } from "@/views/Trends/types";
import React, { useEffect, useState } from "react";

interface TrendTabProps {
    data: ITrend[];
    isLoading: boolean;
    page: number;
    pageSize: number;
    setPage: Function;
}

export const TrendTab: React.FC<TrendTabProps> = ({
    data,
    isLoading,
    page,
    pageSize,
    setPage,
}) => {

    const [results, setResults] = useState<ITrend[]>([]);

    useEffect(() => {
        if (page === 1) {
            setResults(data);
        } else {
            setResults((prevResults) => {
                const newResults = [...prevResults, ...data];
                return Array.from(new Map(newResults.map((item) => [item.id, item])).values());
            });
        }
    }, [data, page]);

    const handleLoadMore = () => {
        setPage((prevPage: number) => prevPage + 1);
    };

    const paginatedResults = results.slice(0, page * pageSize);

    return (
        <ResultCardList
            results={paginatedResults}
            type="trend"
            isLoading={isLoading}
            page={page}
            totalItems={results.length}
            pageSize={pageSize}
            handlePageChange={handleLoadMore}
        />
    );
};
