import ChartTooltip from "@/views/Discover/Boards/components/conversations/ChartTooltip";
import React from "react";
import { Area, AreaChart, Tooltip as RechartTooltip, ResponsiveContainer, XAxis } from 'recharts';
import { IMetricsPerDay } from "@/views/Trends/types";

interface IProps {
  lineChartData: IMetricsPerDay[],
}

const TopicTrendTimelineChart: React.FunctionComponent<IProps> = React.memo(
  ({ lineChartData }) => {
    return (
      <ResponsiveContainer>
        <AreaChart
          margin={{ bottom: -10 }}
          data={lineChartData}
        >
          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={false}
            style={{
              fontSize: '10px'
            }}
          />
          <Area
            type="linear"
            dataKey="count"
            stroke={'#651AEB'}
            fill={'transparent'}
          />
          <RechartTooltip content={<ChartTooltip />} allowEscapeViewBox={{ x: true, y: true }} wrapperStyle={{ zIndex: 1000 }} />
        </AreaChart>
      </ResponsiveContainer>
    )
  })

export default TopicTrendTimelineChart