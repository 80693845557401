import NoImageFoundPath from '@/assets/img/NoImageFound.svg';
import Tooltip from '@/components/TailwindTooltip';
import { Board } from '@/views/Discover/Boards/useBoards';
import { IMoment } from '@/views/Trends/types';
import dayjs from 'dayjs';
import React from 'react';
import { Line, LineChart, XAxis, YAxis } from 'recharts';
import { Dropdown, Icon, IconButton, Popover, Whisper } from 'rsuite/lib';

interface IProps {
  moment: IMoment,
  handleSelect: (result: IMoment) => void
}

const ResultMomentCard: Function = React.memo(
  ({
    moment,
    handleSelect
  }: IProps) => {

    return (
      <div
        data-testid="anticipation-boards-moment-card"
        className="cursor-pointer hover:bg-gray-50 relative flex items-center my-4 bg-white shadow gap-y-2 animate-in fade-in rounded-xl"
        onClick={() => handleSelect(moment)}
      >

        <div
          className="flex items-center flex-grow"
        >
          <img
            data-testid="anticipation-boards-moment-card-image"
            src={moment.imageUrl}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null 
              currentTarget.src = NoImageFoundPath
            }}
            alt={`image:${moment.name}`}
            className="object-cover w-32 h-32 rounded-l-xl"
          />

          <div className="flex items-center grow justify-evenly">
            <div className="flex flex-col justify-center flex-1 w-2/3 pl-4 ">
              {/* Moment Title*/}
              <div
                id={`trendTitle-${moment.id}`}
                className="flex-wrap min-w-0 overflow-hidden text-base font-semibold text-gray-900 break-words"
              >
                <span data-testid="anticipation-boards-moment-card-headline">
                  {moment.name.substring(0, 90)}{moment.name.length > 89 ? '...' : ''}
                </span>
                {moment.minPublishedAt && (
                  <div data-testid="moment-card-first-published-date" className="mt-1 text-xs text-gray-400">
                    Published date: {dayjs(moment.minPublishedAt.toString()).format('MMMM D, YYYY')}
                  </div>
                )}
              </div>
            </div>

            <div className="ml-6">
              <Tooltip
                theme={'light'}
                content={
                  <div
                    className="p-4 text-left"
                  >
                    <p>
                      Daily article count for the last 30 days after the moment has been published, indicating the current velocity of the moment.
                    </p>
                    <div className="flex justify-between w-full mt-4 row">
                      <span>
                        First published
                      </span>
                      <span data-testid="moment-card-first-published-date-tooltip" className="font-bold">
                        {dayjs(moment.minPublishedAt.toString()).format('MMMM D, YYYY')}
                      </span>
                    </div>
                    <hr />
                    {moment.articleCountPerDay && (<div className="flex justify-between w-full my-2 row">
                      <span>
                        Last 24 hr. article count
                      </span>
                      <span className="font-bold">
                        {moment.articleCountPerDay[moment.articleCountPerDay.length - 1]?.count}
                      </span>
                    </div>)}
                  </div>
                }
              >
                <div data-testid="moment-card-article-count-chart-div">
                  <LineChart
                    margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                    width={90} height={40} data={moment.articleCountPerDay}
                  >
                    <XAxis dataKey="name" tick={false} width={1} height={0} />
                    <YAxis tick={false} width={0} domain={['dataMin', 'dataMax']} />
                    <Line type="monotone" dataKey="story_count" stroke="#7748f9" dot={false} />
                  </LineChart>
                </div>
              </Tooltip>
            </div>

            <div className="flex w-1/3 select-none justify-evenly">
              <div>
                <p
                  data-testid="anticipation-boards-moment-card-articles"
                  className="pl-1 text-sm font-bold text-highlightPurple"
                >
                  {moment.articleCount}
                </p>
                <p>Articles</p>
              </div>
              <div>
                <p
                  data-testid="anticipation-boards-moment-card-videos"
                  className="pl-1 text-sm font-bold text-highlightPurple"
                >
                  {moment.videoCount}
                </p>
                <p>Videos</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
)

export default ResultMomentCard;
