import React, { useEffect, useState } from 'react'
import { Table, Popover, Dropdown, IconButton, Icon } from 'rsuite';
const { HeaderCell, Cell } = Table;
import { TableColumnJs } from '@/utils';
import Whisper from "rsuite/lib/Whisper";
import { accentColor } from '@/assets/jss/colorConstants';
import { useQuery } from "@tanstack/react-query";
import { TablePagination } from './TablePagination';
import { useNavigate } from '@tanstack/react-location';
import { Notification } from 'rsuite';
import { routes } from '@/routes';
import { InformationModal } from '@/views/Engage/Segments/components/InformationModal';
import LoadingModal from '@/views/Engage/Segments/components/LoadingModal';
import { segmentsController } from '@/views/Engage/Segments/controller/SegmentController';
import { SortOrder } from '@/views/Engage/Segments/types/SegmentsTypes';
import { SortableTableHeader } from './SortableTableHeader';
import { SortState } from '@/views/Engage/Segments/types/SegmentsTypes';
import useUser from '@/hooks/useUser';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';
import { perms } from '@/staticData/permissions';

interface IProps {
    /** Table data. */
    tableData: any,
    /** Account ID. */
    accountId: number,
    /** User permissions. */
    userPermissions?: any
}

/**
 * Component for displaying a table of segments.
 * @prop {any} tableData Table data.
 * @prop {number} accountId Account ID.
 *  @prop {any} userPermissions User permissions.
 */

const tableHeaderStyle = {
    fontWeight: 600,
    fontSize: '16px',
    color: '#000000',
    backgroundColor: '#FAFAFA'
}
const tableStyle = {
    border: '1px solid #ccc',
    borderRadius: '4px 4px 0 0'
}

export const SegmentsViewTable = ({ accountId, userPermissions }: IProps) => {
    const limit = 2;

    const [page, setPage] = useState(1);
    const [sortState, setSortState] = useState<SortState>({
        column: '',
        direction: SortOrder.EMPTY
    });
    const [orderBy, setOrderBy] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
    const [loadingTitleModal, setLoadingTitleModal] = useState('');
    const [titleInfomationModal, setTitleInfomationModal] = useState('');
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const navigate = useNavigate();

    const hasPermissionUpdate = userCanPermissionProductQuota({
        requiredPermissionValue: perms.SEGMENT_UPDATE,
        userPermissions: userPermissions,
        checkType: PermissionCheckType.PERMISSION_CHECK
    })
    const hasPermissionDelete = userCanPermissionProductQuota({
        requiredPermissionValue: perms.SEGMENT_DELETE,
        userPermissions: userPermissions,
        checkType: PermissionCheckType.PERMISSION_CHECK
    })


    const { data: queryData, refetch } = useQuery(
        ['getSegments', accountId, page, limit, orderBy, sortState.direction],
        () => segmentsController.getSegments({
            queryKey: ['getSegments', accountId, page, limit, orderBy, sortState.direction]
        }),
        {
            keepPreviousData: true,
        }
    );

    const [tableData, setTableData] = useState(queryData);

    useEffect(() => {

        setTableData(queryData);

    }, [queryData]);

    const showErrorModal = (title: string, messages: string[]) => {
        setTitleInfomationModal(title);
        setErrorMessages(messages);
        setIsErrorModalVisible(true);
    }


    const handleEdit = async (rowData: any) => {
        navigate({
            to: routes.app.segments.update.path,
            search: (prev) => ({
                ...prev,
                segmentId: rowData['id'],
            })
        });
    }

    const handleDelete = async (rowData: any) => {
        try {
            setLoadingTitleModal('Deleting Segment')
            setIsLoading(true)

            const result = await segmentsController.handleRemoveSegment(rowData['id']);

            if (result) {
                Notification.open({
                    title: 'Success',
                    description: <div>The segment was successfully deleted</div>
                });

                const updatedTableData = tableData?.results.filter((row: any) => row.id !== rowData['id']);

                if (updatedTableData?.length === 0 && page > 1) {

                    if (tableData && tableData.totalCount !== undefined) {
                        tableData.totalCount = tableData.totalCount - 1;
                    }

                    setPage(prevPage => Math.max(prevPage - 1, 1));

                } else {

                    await refetch();
                }

            } else {
                showErrorModal('Error', ['Error while deleting the segment.'])
            }

        } catch (error) {
            showErrorModal('Error', ['Error while deleting the segment.'])
            console.log(error);

        } finally {
            setIsLoading(false)
        }
    }

    return (

        <div>
            <Table minHeight={500} data={tableData?.results ?? []} style={tableStyle} autoHeight={true} data-testid="segments-table-view">
                <TableColumnJs align="center" fixed flexGrow={1}>
                    <HeaderCell style={tableHeaderStyle} data-testid="segments-table-view-name-header">
                        <SortableTableHeader
                            title="Name"
                            columnName="name"
                            sortState={sortState}
                            setSortState={setSortState}
                            setOrderBy={setOrderBy}
                        />
                    </HeaderCell>
                    <Cell data-testid="segments-table-view-name">
                        {(rowData: any) => {
                            return (
                                rowData.name
                            );
                        }}
                    </Cell>
                </TableColumnJs>

                <TableColumnJs align="center" fixed flexGrow={1}>
                    <HeaderCell style={tableHeaderStyle} data-testid="segments-table-view-description-header">
                        <SortableTableHeader
                            title="Description"
                            columnName="description"
                            sortState={sortState}
                            setSortState={setSortState}
                            setOrderBy={setOrderBy}
                        />
                    </HeaderCell>
                    <Cell data-testid="segments-table-view-modified">
                        {(rowData: any) => {
                            return (
                                rowData.description
                            );
                        }}
                    </Cell>
                </TableColumnJs>

                <TableColumnJs width={75}>
                    <HeaderCell style={tableHeaderStyle} data-testid="segments-table-view-header"></HeaderCell>
                    <Cell data-testid="segments-table-view-actions">
                        {(rowData: any, rowIndex: number) => {
                            return (
                                <div
                                    data-testid={`div-segments-actions-dropdown-${rowIndex}`}
                                    className="more"
                                >
                                    { (hasPermissionUpdate || hasPermissionDelete) && <Whisper
                                        data-testid="segments-table-view-whisper"
                                        placement="topEnd"
                                        trigger="click"
                                        speaker={
                                            <Popover full>
                                                <Dropdown.Menu >
                                                   { hasPermissionUpdate && <Dropdown.Item
                                                        data-testid='div-segments-edit-button'
                                                        onClick={() => handleEdit(rowData)}>
                                                        Update
                                                    </Dropdown.Item>}
                                                    { hasPermissionDelete && <Dropdown.Item
                                                        data-testid='div-segments-delete-button'
                                                        onClick={() => handleDelete(rowData)}>
                                                        Delete
                                                    </Dropdown.Item>}
                                                </Dropdown.Menu>
                                            </Popover>
                                        }
                                    >
                                        <IconButton
                                            data-testid={`segments-table-view-whisper-more-${rowIndex}`}
                                            size="lg"
                                            appearance="default"
                                            icon={
                                                <Icon
                                                    icon="more"
                                                    color={accentColor}
                                                    rotate={90}
                                                />
                                            }
                                        />
                                    </Whisper>}
                                </div>
                            );
                        }}
                    </Cell>
                </TableColumnJs>
            </Table>
            <div className='mt-2 mb-2 w-full flex justify-end'>
                <TablePagination
                    activePage={page}
                    total={tableData?.totalCount ?? 0}
                    onChangePage={setPage}
                    limit={limit}
                />
            </div>
            <InformationModal title={titleInfomationModal} informationMessages={errorMessages} isInformatioModalVisible={isErrorModalVisible} setIsInformatioModalVisible={setIsErrorModalVisible} />
            <LoadingModal loading={isLoading} title={loadingTitleModal} />
        </div>
    );
}
