import React, { useRef, useState } from 'react'
import { Fragment } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'

type valueType = string | number | null | undefined

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

interface IProps {
    label?: string
    options: any
    value: valueType
    width?: number
    labelKey: string
    valueKey: string
    onChange: Function
    disabled?: boolean
    loading?: boolean
    searchable?: boolean
    size?: 'regular' | 'small'
}

const BoardSelectSearch = ({
    label,
    options = [],
    value,
    width,
    labelKey,
    valueKey,
    onChange,
    disabled,
    loading,
    size = 'regular'
}: IProps) => {
    const [query, setQuery] = useState('')
    const selectedItem = React.useMemo(() => {
        if (!options || !value) {
            return {}
        }
        return options.filter((option: any) => option[valueKey] == value)[0] || {}
    }, [value, options])

    const comboBtn = useRef<HTMLButtonElement>(null);
    
    const onSearchInputFocus = (open: boolean) => !open && comboBtn.current?.click();

    const filteredOptions =
        query === ''
            ? options
            : options.filter((option: { brandProfileName: string }) => {
                return option.brandProfileName.toLowerCase().includes(query.toLowerCase())
            })

    return (
        <Combobox
            value={selectedItem}
            onChange={(val) => {
                onChange(val[valueKey])
            }}
            disabled={loading || disabled}
        >
            {({ open }) => (
                <div className="block" data-testid={"brand-profile-search"}>
                    <div
                        className="relative"
                        style={{ width: width || undefined }}
                    >
                        <label className={`relative ${size === 'small' ? 'text-xs': ''}`}>
                            <span className='absolute top-[-9px] left-2 z-10 bg-white pl-[3px] pr-[10px]'>{label}</span>
                            <div className='flex flex-row focus:border-sightly-blue focus:ring-sightly-blue relative w-full cursor-default rounded-md border border-gray-300 bg-white py-[11px] px-[12px] text-left shadow-sm sm:text-sm disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500'>
                                <Combobox.Input
                                    data-testid={"brand-profile-input"}
                                    className={`w-full border-none ${size === 'small' ? 'text-sm h-4' : 'text-base'} leading-5 p-0 text-gray-900 focus:ring-0`}
                                    displayValue={(option: { brandProfileName: string }) => {
                                        return (option?.brandProfileName)
                                    }}
                                    onChange={(event) => setQuery(event.target.value)}
                                    onFocus={() => onSearchInputFocus(open)}
                                />
                                <Combobox.Button 
                                    onClick={ () => { setQuery('')}} 
                                    className={`inset-y-0 right-0 flex items-center ${size === 'small' ? 'pl-1' : 'pl-2' }`}
                                    data-testid={"brand-profile-button"}
                                    ref={comboBtn}
                                >
                                    <ChevronDownIcon
                                        className={`${size === 'small' ? 'h-3 w-3' : 'h-5 w-5'} text-gray-400`}
                                        aria-hidden="true"
                                    />
                                </Combobox.Button>
                            </div>
                        </label>
                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Combobox.Options className={`w-full absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${ size === 'small' ? 'text-sm sm:text-xs' : 'text-base sm:text-sm'}`}>
                                {filteredOptions.map((option: any) => {
                                    return (
                                        <Combobox.Option
                                            key={option[valueKey]}
                                            className={({ active }) =>
                                                classNames(
                                                    active ? 'text-white bg-sightly-blue-light' : 'text-gray-900',
                                                    'relative cursor-default select-none py-2 pl-3 pr-9 text-left'
                                                )
                                            }
                                            value={option}
                                            data-testid={"brand-profile-option"}
                                        >
                                            {({ selected, active }) => (
                                                <>
                                                    <span
                                                        className={classNames(
                                                            selected ? 'font-semibold' : 'font-normal',
                                                            'block truncate'
                                                        )}
                                                    >
                                                        {option[labelKey]}
                                                    </span>

                                                    {selected ? (
                                                        <span
                                                            className={classNames(
                                                                active ? 'text-slate-500' : 'text-slate-400',
                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                            )}
                                                        >
                                                            <CheckIcon
                                                                className={size === 'small' ? 'h-3 w-3' : 'h-5 w-5'}
                                                                aria-hidden="true"
                                                            />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Combobox.Option>
                                    )
                                })}
                            </Combobox.Options>
                        </Transition>
                    </div>
                </div>
            )}
        </Combobox>
    )
}

export default BoardSelectSearch
