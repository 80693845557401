import { listBuilderAxios } from "@/axiosInstances";
import { IArticle, IBrand, IMomentTimeline, INarrative, IPeople, ITopic, ITopicMetric, articleSchema, brandsSchema, momentTimelineSchema, narrativeSchema, peopleSchema, topicMetricSchema, ISanKeyGraph, graphDataSchema  } from "@/views/Trends/types";

export const topics = {
    get: async (search?: string) => {
      let url = `/trend-analysis/topics${search ? `?prompt=${search}` : ''}`
      return listBuilderAxios.get<ITopic[]>(url);
    },


    getById: async (topicId: string) => {
      const url = `/trend-analysis/topics/${topicId}`
      return listBuilderAxios.get<ITopic>(url)
    },

    getPeople: async (topicId: string) => {
      const url = `/trend-analysis/topics/${topicId}/people`
      const result = await listBuilderAxios.get<IPeople[]>(url)
      return result.data.map(person => peopleSchema.parse(person))
    },

    getBrands: async (topicId: string) => {
      const url = `/trend-analysis/topics/${topicId}/brands`
      const result = await listBuilderAxios.get<IBrand[]>(url)
      return result.data.map(brand => brandsSchema.parse(brand))
    },

    getNarratives: async (topicId: string) => {
      const url = `/trend-analysis/topics/${topicId}/narratives`
      const result = await listBuilderAxios.get<INarrative[]>(url)
      return narrativeSchema.parse(result.data)
    },

    getMoments: async (topicId: string) => {
      const url = `/trend-analysis/topics/${topicId}/moments`
      const result = await listBuilderAxios.get<INarrative[]>(url)
      return narrativeSchema.parse(result.data)
    },

    getArticles: async (topicId: string) => {
      const url = `/trend-analysis/topics/${topicId}/moment-articles`
      const result = await listBuilderAxios.get<IArticle[]>(url)
      return result.data.map(article => articleSchema.parse(article))
    },

    getSankeyData: async (topicId: string) => {
      const url = `/trend-analysis/topics/${topicId}/sankey`
      const result = await listBuilderAxios.get<ISanKeyGraph>(url)
      return graphDataSchema.parse(result.data)
    },

    getMomentTimeline: async (topicId: string) => {
      const url = `/trend-analysis/topics/${topicId}/cluster-timeline`
      const result = await listBuilderAxios.get<IMomentTimeline>(url)
      return momentTimelineSchema.parse(result.data)
    },

    getSocialPosts: async (topicId: string) => {
      const url = `/trend-analysis/topics/${topicId}/narrative-posts`
      const result = await listBuilderAxios.get<INarrative[]>(url)
      return result.data.map(socialPost => narrativeSchema.parse(socialPost))
    },

    getArticleMetrics: async (topicId: string) => {
      const url = `/topics/${topicId}/metrics?type=articles`
      const result = await listBuilderAxios.get<ITopicMetric[]>(url)
      return result.data.map(topicMetric => topicMetricSchema.parse(topicMetric))
    },

    getSentimentScore: async (topicId: string) => {
      const url = `/trend-analysis/topics/${topicId}/sentiment-score`
      const result = await listBuilderAxios.get<Number>(url)
      return result.data;
    }
}
