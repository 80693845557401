import { listBuilderAxios } from "@/axiosInstances"
import { INarrative, narrativeSchema } from "@/views/Trends/types"

export const narratives = {
    get: async (search?: string) => {
      let url = `/trend-analysis/narratives${search ? `?prompt=${search}`: ''}`

      return listBuilderAxios.get<INarrative[]>(url)
    },
    getById: async (narrativeId: number) => {
      const url = `/trend-analysis/narratives/${narrativeId}`
      const result = await listBuilderAxios.get<INarrative>(url)
      return narrativeSchema.parse(result.data)
    }
}
