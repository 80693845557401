import React from 'react'
import { ChevronDownIcon, ChevronRightIcon, TrashIcon } from '@heroicons/react/24/outline'
import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox'
import { formatDateyyyyMMdd } from '@/utils'
import CampaignStatus from './CampaignStatus'

const CampaignRow = (props: {
  campaign: any,
  expandedCampaigns: Set<number> | null
  selectedCampaigns: Set<number>
  setExpandedCampaigns: React.Dispatch<React.SetStateAction<Set<number> | null>>
  handleDelete: (campaignId: number) => void
  handleSelect: (campaignId: number) => void
}) => {
  const {
    campaign,
    expandedCampaigns,
    selectedCampaigns,
    setExpandedCampaigns,
    handleDelete,
    handleSelect
  } = props

  const toggleCampaign = (campaignId: number) => 
    setExpandedCampaigns((prev) => {
      const next = new Set(prev)
      next?.has(campaignId) ? next.delete(campaignId) : next.add(campaignId)
      return next
    })

  return (
    <tr data-testid="activations-campaigns-results-row-table" id="activations-campaigns-results-row-table" >
      <td className="w-16">
        <div className="flex gap-1 pl-3">
          <div
            className="cursor-pointer"
            data-testid="activations-campaigns-results-table-expand-btn"
            id="activations-campaigns-results-table-expand-btn"
            onClick={() => toggleCampaign(campaign.id)}
          > 
            {expandedCampaigns?.has(campaign.id) ? (
              <ChevronDownIcon
                height={20}
                width={20}
              />
            ) : (
              <ChevronRightIcon
                height={20}
                width={20}
              />
            )}
          </div>
          <SightlyCheckbox
            dataTestId="campaign-select-all-checkbox"
            handleChange={() => handleSelect(campaign.id)}
            id="selectAllCampaigns"
            checked={selectedCampaigns.has(campaign.id)}
          />
        </div>
      </td>
      <td
        className="py-4 pr-6 text-sm font-semibold text-left text-gray-600 truncate whitespace-nowrap"
        data-testid={`activations-campaigns-results-table-id-column-${campaign.id}`}
        id={`activations-campaigns-results-table-id-column-${campaign.id}`}
      >
        {campaign.id}
      </td>
      <td
        className="py-4 pr-6 text-sm font-semibold text-left text-gray-600 truncate whitespace-nowrap"
        data-testid={`activations-campaigns-results-table-name-column-${campaign.id}`}
        id={`activations-campaigns-results-table-name-column-${campaign.id}`}
      >
        {campaign.campaignName}
      </td>
      <td
        className="text-sm text-gray-500 whitespace-nowrap"
        data-testid={`activations-campaigns-results-table-advertisers-column-${campaign.id}`}
        id={`activations-campaigns-results-table-advertisers-column-${campaign.id}`}
      >
        {campaign.advertiserName}
      </td>
      <td 
        className="w-20 text-sm text-gray-500 whitespace-nowrap"
        data-testid={`activations-campaigns-results-table-create-time-column-${campaign.id}`}
        id={`activations-campaigns-results-table-create-time-column-${campaign.id}`}
      >
        {formatDateyyyyMMdd(new Date(campaign.createTime))}
      </td>
      <td className="text-sm text-gray-500 whitespace-nowrap w-14">
        <div className="flex items-center flex-shrink-0 gap-2 mt-4 sm:mt-0">
          <div
            className="flex -space-x-1 "
            data-testid="activations-todo-assignees"
            id="activations-todo-assignees"
          >
            <CampaignStatus
              active={campaign.operationStatus.toUpperCase() === 'ENABLE'}
              dataTestId={`campaign-results-table-status-column-${campaign.id}`}
            />
          </div>
        </div>
      </td>
      <td className="relative w-12 py-3 pl-3 pr-4 sm:pr-6">
        <div className="flex gap-1 w-12" data-testid="activations-campaigns-results-table-delete-column">
          <TrashIcon
            className="h-4 w-4 text-gray-600 cursor-pointer"
            data-testid="delete-campaign"
            id="delete-campaign"
            onClick={() => handleDelete(campaign.id)}
          />
        </div>
      </td>
    </tr>
  )
}

export default CampaignRow
