import { listBuilderAxios } from "@/axiosInstances"
import { momentSchema, IMoment } from "@/views/Trends/types"

export const moments = {
    get: async (search?: string) => {
      let url = `/trend-analysis/moments${search ? `?prompt=${search}`: ''}`

      return listBuilderAxios.get<IMoment[]>(url)
    },
    getById: async (momentId: number) => {
      const url = `/trend-analysis/moments/${momentId}`
      const result = await listBuilderAxios.get(url)
      return momentSchema.parse(result.data)
    }
}
