import React, { useState, useRef, useMemo } from 'react'
import useAssignees, { AssigneesSchemaType } from '@/views/Discover/Moments/v2/TargetMoment/hooks/useAssignees'
import { MyLocationGenerics } from '@/classes/utils'
import { useSearch } from '@tanstack/react-location'
import { CheckPicker, Icon } from 'rsuite'
import useUser from '@/hooks/useUser'
import { isValidEmail } from '@/validations'

function ScheduleEmail(props: {
  emails: string[], 
  setEmails: React.Dispatch<React.SetStateAction<string[]>>,
}) {
  const { accountId } = useSearch<MyLocationGenerics>()
  const { assigneesQuery: emailsWithUser } = useAssignees(accountId, true)

  const { emails, setEmails } = props

  const [emailNotFound, setEmailNotFound] = useState(false)
  const [emailSearchValue, setEmailSearchValue] = useState('')
  const checkPickerRef = useRef<any>(null)

  const [emailsWithoutUser, setEmailsWithoutUser] = useState<AssigneesSchemaType>([])
  
  const assignees = useMemo(() => 
    [...(emailsWithUser.data || []), ...emailsWithoutUser],
    [emailsWithUser.data, emailsWithoutUser]
  )

  const handleAddEmailAndClose = (email: string) => {
    setEmailsWithoutUser(prev => [...prev, {
      email,
      fullName: email,
      userId: 0,
      userType: 'Custom'
    }])
    setEmails(prev => [...prev, email])
    checkPickerRef.current?.close()
  }

  return (
    <div
      className="sm:px-1 text-grey-900 w-1/2"
      data-testid="email-container"
    >
      <div className="mb-2 font-medium" id='pendo-act-mgr-assignees-filter'>Email</div>
        <CheckPicker
          ref={checkPickerRef}
          data={assignees || []}
          data-testid="email-check-picker"
          renderMenu={(menu: React.ReactNode) => {
            if (!assignees) {
              return (
                <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                  <Icon
                    icon="spinner"
                    spin
                  />{' '}
                  Loading...
                </p>
              )
            }
            if(assignees.length === 0){
              return (
                <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
                  No Results Found
                </p>
              )
            }
            return menu
          }}
          searchable={!!assignees}
          labelKey="fullName"
          valueKey="email"
          groupBy="userType"
          appearance="default"
          placement="autoVerticalStart"
          block
          value={emails}
          onChange={(emailsList: string[]) => {
            setEmailsWithoutUser(prev => prev.filter(item => emailsList.includes(item.email)))
            setEmails((prev: string[]) => emailsList)
          }}
          onClean={() => {
            setEmails((prev: string[]) => [])
          }}
          onClose={() => {
            setEmailNotFound(false);
          }}
          onSearch={(value: string) => {
            const email = assignees?.find(({email}: {email: string}) => email.includes(value))
            if (!email && isValidEmail(value)) {
              setEmailNotFound(true);
              setEmailSearchValue(value)
            } else {
              setEmailNotFound(false);
              setEmailSearchValue('')
            }
          }}
          renderExtraFooter={() => {
            if (emailNotFound) {
              return (
                <div className="flex justify-end"> 
                  <button
                    className={`px-3 py-2 rounded-md text-purple-600 hover:text-purple-700 mr-1 my-1`}
                    onClick={() => {
                      handleAddEmailAndClose(emailSearchValue)
                    }}
                    data-testid="add-email-button"
                  >
                    Add email
                  </button>
                </div>
              )
            }
            return null;
          }}
        />
    </div>
  )
}

export default ScheduleEmail
