import { MyLocationGenerics } from '@/classes/utils'
import { InfoPage } from '@/components/InfoPage'
import { LoadingPage } from '@/components/LoadingPage'
import { SightlyInput } from '@/components/Sightly/SightlyFormElements/SightlyInput'
import SightlyGradientCard from '@/components/Sightly/SightlyGradientCard'
import Tabs from '@/components/Tabs/Tabs'
import useBrandProfiles from '@/hooks/brandProfile/useBrandProfiles'
import Overview from '@/views/Trends/SearchResults/components/Overview'
import { MomentTab } from '@/views/Trends/SearchResults/components/Tabs/MomentTab'
import { NarrativeTab } from '@/views/Trends/SearchResults/components/Tabs/NarrativeTab'
import { TopicTab } from '@/views/Trends/SearchResults/components/Tabs/TopicTab'
import { TrendTab } from '@/views/Trends/SearchResults/components/Tabs/TrendTab'
import { useGenerateSummaryData } from '@/views/Trends/hooks/useAnalysis'
import { useNarrativeSearch } from '@/views/Trends/hooks/useNarratives'
import { useTopicTrendMoments } from '@/views/Trends/hooks/useTopicTrendMoments'
import { useTopicSearch } from '@/views/Trends/hooks/useTopics'
import { useTrendSearch } from '@/views/Trends/hooks/useTrends'
import { DataTypes, ICurrentTabData, IMoment, INarrative, ISearchResult, ITopic, ITrend, SearchResult, TrendsSearchResultSortBy, categories, searchResultValues } from '@/views/Trends/types'
import { PaperAirplaneIcon, SparklesIcon } from '@heroicons/react/20/solid'
import { useNavigate, useSearch } from '@tanstack/react-location'
import { useFlag } from '@unleash/proxy-client-react'
import React, { useEffect, useState } from 'react'

import { PATH_TRENDS_SEARCH } from '@/routes'
import { TabsFilters } from '@/views/Trends/SearchResults/components/TabsFilters'
import BrandProfileSelect from '@/views/Trends/components/BrandProfileSelect'
import { getLast60Days } from '@/utils/dateRanges'
import { capitalizeFirstLetter } from '@/utils'

export const TrendsResult = () => {
  const navigate = useNavigate();
  const trendsEnabled = useFlag('enable_trends_feature');
  if (!trendsEnabled) navigate({ to: '/app/discover/moments/v2' });

  const tabs = searchResultValues  

  const [activeTab, setActiveTab] = useState(tabs[1]) // set default active tab to Trends
  const [activeTabData, setActiveTabData] = useState<ICurrentTabData>()
  const [tabsCountData, setTabsCountData] = useState<{ tab: SearchResult, count: number }[]>()

  const { searchTerm } = useSearch<MyLocationGenerics>();
  const [search, setSearch] = useState<string>(searchTerm ?? '');

  const {
    data: brandProfiles
  } = useBrandProfiles({
    submittedOnly: true
  });

  const [brandProfileId, setBrandProfileId] = useState<number>();

  useEffect(() => {
    if (brandProfiles && brandProfiles.length > 0) {
      setBrandProfileId(brandProfiles[0].brandProfileId)
    } else {
      setBrandProfileId(undefined)
    }
  }, [brandProfiles])

  const pageSize = 10;

  const [callSummaryOnSearch, setCallSummaryOnSearch] = useState(false);
  const [noDataForSummary, setNoDataForSummary] = useState(false);

  const [topics, setTopics] = useState<ITopic[]>([]);
  const [topicPage, setTopicPage] = useState(1);
  const { topicsData, topicsError, isLoadingTopics } = useTopicSearch(search);

  const [trends, setTrends] = useState<ITrend[]>([]);
  const [trendPage, setTrendPage] = useState(1);
  const { trendsData, trendsError, isLoadingTrends } = useTrendSearch(search);

  const [moments, setMoments] = useState<IMoment[]>([]);
  const [momentPage, setMomentPage] = useState(1);
  const { momentsData, momentsError, isLoadingMoments } = useTopicTrendMoments(search);

  const [narratives, setNarratives] = useState<INarrative[]>([]);
  const [narrativePage, setNarrativePage] = useState(1);
  const { narrativesData, narrativesError, isLoadingNarratives } = useNarrativeSearch(search);

  // Filters
  const [themeOptions, setThemeOptions] = useState<{ label: string; value: string }[]>()

  const [category, setCategory] = useState<number>(-1)
  const [theme, setTheme] = useState<string>('All Themes')
  const [timeRange, setTimeRange] = useState<[Date, Date]>(getLast60Days())
  const [sortBy, setSortBy] = useState<TrendsSearchResultSortBy>('createdAtDesc')

  useEffect(() => {
    if (searchTerm !== undefined && searchTerm !== search) {
      setSearch(searchTerm);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (
      isLoadingTopics ||
      isLoadingTrends ||
      isLoadingMoments ||
      isLoadingNarratives
    ) return

    if (!(topicsData || trendsData || momentsData || narrativesData)) {
      setNoDataForSummary(true);
      return
    }

    setCallSummaryOnSearch(true);

    setActiveTabData((prev) => {
      if (prev?.tab === activeTab) {
        return prev;
      }
      switch (activeTab) {
        case 'topic':
          return { tab: activeTab, data: topicsData || [] };
        case 'trend':
          return { tab: activeTab, data: trendsData || [] };
        case 'moment':
          return { tab: activeTab, data: momentsData || [] };
        case 'narrative':
          return { tab: activeTab, data: narrativesData || [] };
        default:
          return prev;
      }
    });

    const totals = [
      { tab: 'topic', query: topicsData },
      { tab: 'trend', query: trendsData },
      { tab: 'moment', query: momentsData },
      { tab: 'narrative', query: narrativesData },
    ].map(({ tab, query }) => ({
      tab: tab as SearchResult,
      count: query?.length || 0,
    }));

    setTabsCountData((prev) => {
      const isEqual = JSON.stringify(prev) === JSON.stringify(totals);
      return isEqual ? prev : totals;
    });
  }, [
    activeTab,
    topicsData,
    trendsData,
    momentsData,
    narrativesData,
    isLoadingTopics,
    isLoadingTrends,
    isLoadingMoments,
    isLoadingNarratives,
  ]);

  const handleTabChange = (tab: string) => {
    setActiveTab(tab as SearchResult)
  }

  const momentsToGenerate = momentsData?.slice(0, 20) || [];
  const narrativesToGenerate = narrativesData?.slice(0, 20) || [];
  const trendsToGenerate = trendsData?.slice(0, 20) || [];
  const topicsToGenerate = topicsData?.slice(0, 20) || [];

  const { summaryData, isLoadingSummary } = useGenerateSummaryData(
    momentsToGenerate,
    narrativesToGenerate,
    trendsToGenerate,
    topicsToGenerate,
    callSummaryOnSearch
  );

  const sortData = (data: any[], sortBy: TrendsSearchResultSortBy) => {
    if (!data) return [];

    switch (sortBy) {
      case "createdAtDesc":
        return data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      case "similarityRecommended":
      default:
        return data;
    }
  };

  const filterData = (data: ISearchResult[], type: string) => {
    let res = data
      .filter((d) => {
        if (!("updatedAt" in d || "maxPublishedAt" in d)) return true

        let updatedAtDate
        if ("updatedAt" in d) {
          if (!d.updatedAt) return true

          updatedAtDate = typeof d.updatedAt === "string" ? new Date(d.updatedAt) : d.updatedAt;
        } else {
          updatedAtDate = new Date(d.maxPublishedAt)
        }

        return updatedAtDate >= timeRange[0] && updatedAtDate <= timeRange[1];
      })

    if (type !== DataTypes.Narratives) {
      if (category !== -1) {
        const categoryCodes = new Set(categories.find((c) => c.id === category)?.codes)
        if (categoryCodes.size > 0) {
          res = res.filter((d) => {
            if ("categoryCodes" in d) {
              return d.categoryCodes.some(item => categoryCodes.has(item));
            } else if ("categories" in d) {
              return d.categories?.map((c) => c.taxonomyCode).some(item => categoryCodes.has(item)) ?? false;
            }
            return false
          });
        }
      }
    } else if (theme !== 'All Themes') {
      res = res.filter((d) => {
        if ("themesList" in d) {
          return d.themesList?.includes(theme) ?? false
        }
        return false
      });
    }
    return res;
  };

  const useSortedData = (filter: number | string, setData: Function, type: string, data?: any[], ) => {
    useEffect(() => {
      if (!data) return;

      const filtered = filterData(data, type);
      setData(sortData(filtered, sortBy));
    }, [filter, timeRange, sortBy, data]);
  }
  
  useSortedData(category, setTopics, DataTypes.TopicTrendMoment, topicsData);
  useSortedData(category, setTrends, DataTypes.TopicTrendMoment, trendsData);
  useSortedData(category, setMoments, DataTypes.TopicTrendMoment, momentsData);
  useSortedData(theme, setNarratives, DataTypes.Narratives, narrativesData);  

  useEffect(() => {
    if (narrativesData) {
      const uniqueThemes = new Set<string>();

      narrativesData.forEach((d) => {
        d.themesList.forEach((theme) => uniqueThemes.add(theme));
      });

      const allThemes = {
        label: 'All Themes',
        value: 'All Themes'
      }

      const options = [allThemes, ...Array.from(uniqueThemes).map((theme) => ({
        label: theme,
        value: theme,
      }))]

      setThemeOptions(options);
    }
  }, [narrativesData]);


  const handleSearch = (searchTerm: string) => {
    navigate({
      to: PATH_TRENDS_SEARCH,
      search: { searchTerm }
    });
  }

  return (
    <div className='px-12 py-8 h-[90%] bg-white rounded-md mx-auto flex flex-col gap-4'>
      <div className="flex justify-between items-center">
        <div className='flex gap-6' data-testid="search-bar">
          <p className='text-2xl'>Explore</p>
          <SightlyInput
            data-testid="search-input"
            id={'explore-search-input'}
            defaultValue={search || searchTerm || 'Search'}
            width={'70%'}
            height={'60%'}
            hasButton={true}
            onChange={handleSearch}
            buttonIcon={<PaperAirplaneIcon />}
          />
        </div>
        {brandProfiles && brandProfiles.length > 0 && <BrandProfileSelect
          value={brandProfileId}
          options={brandProfiles}
          label={"Brand Profile"}
          labelKey="brandProfileName"
          valueKey="brandProfileId"
          onChange={setBrandProfileId}
          size='small'
        />}
      </div>
      <div className='w-full h-full flex animate-in fade-in'>
        <div className="w-2/3 flex flex-col pr-4 border-r border-gray-200">
          <div className='flex flex-col gap-2'>
            <p className='text-2xl'>{search || searchTerm}</p>
            <SightlyGradientCard>
              <p className="text-highlightPurple text-sm font-bold">
                <SparklesIcon
                  className="h-5 w-5 text-highlightPurple inline mr-2"
                  aria-hidden="true"
                />
                Summary
              </p>
              {isLoadingTopics || isLoadingTrends || isLoadingMoments || isLoadingNarratives || isLoadingSummary ? (
                <div className='my-8'>
                  <LoadingPage message='Generating summary' />
                </div>
              ) : (
                <div className='my-2 h-[10vh] overflow-auto'>
                  <p className="text-sm">
                    {
                      noDataForSummary ? 'No data to generate summary.'
                        : summaryData ? summaryData.summary
                          : 'Summary could not be generated. Please refresh the page to try again. If the problem persists, please contact support.'
                    }
                  </p>
                </div>
              )}
            </SightlyGradientCard>
          </div>
          <div className='h-[70%] flex flex-col gap-6'>
            <div className="w-full flex flex-col gap-4">
              <Tabs
                width={'100%'}
                marginRight={40}
                active={activeTab}
                onChange={(active: string) => {
                  handleTabChange(active)
                }}
              >
                {tabs.map((tab) => (
                  <div
                    className="flex flex-row items-center"
                    key={tab}
                    data-testid={`tab-${tab}`}
                  >
                    <div>{capitalizeFirstLetter(tab)}</div>
                  </div>
                ))}
              </Tabs>
              <TabsFilters
                activeTab={activeTab}
                category={category}
                setCategory={setCategory}
                theme={theme}
                setTheme={setTheme}
                themeOptions={themeOptions || []}
                timeRange={timeRange}
                setTimeRange={setTimeRange}
                sortBy={sortBy}
                setSortBy={setSortBy} />
            </div>
            <div className='h-full flex overflow-y-auto'>
              <>
                {activeTab === 'topic' && (
                  <TopicTab 
                    data={topics} 
                    error={topicsError}
                    isLoading={isLoadingTopics} 
                    page={topicPage} 
                    pageSize={pageSize} 
                    setPage={setTopicPage} />
                )}

                {activeTab === 'trend' && (
                  <TrendTab 
                    data={trends} 
                    error={trendsError}
                    isLoading={isLoadingTrends} 
                    page={trendPage} 
                    pageSize={pageSize} 
                    setPage={setTrendPage} />
                )}

                {activeTab === 'moment' && (
                  <MomentTab
                    data={moments}
                    error={momentsError}
                    isLoading={isLoadingMoments}
                    page={momentPage}
                    pageSize={pageSize}
                    setPage={setMomentPage}
                    category={category}
                    brandProfileId={brandProfileId}
                    brandProfileSelectOptions={brandProfiles} />
                )}

                {activeTab === 'narrative' && (
                  <NarrativeTab
                    data={narratives}
                    error={narrativesError}
                    isLoading={isLoadingNarratives}
                    page={narrativePage}
                    pageSize={pageSize}
                    setPage={setNarrativePage} />
                )}
              </>
            </div>
          </div>
        </div>
        <div className='w-1/3 flex flex-col gap-4 pl-4'>
          {isLoadingTopics || isLoadingTrends || isLoadingMoments || isLoadingNarratives ? (
            <LoadingPage message={'Loading overview'} />
          ) : activeTabData && tabsCountData ?
            (
              <Overview
                currentTabData={activeTabData}
                tabsCountData={tabsCountData} />
            ) : (
              <InfoPage message={'No overview'} />
            )
          }
        </div>
      </div>
    </div>
  )
}
