import React from 'react'
import HeaderGroup from '@/views/TikTok/Campaigns/components/HeaderGroup'
import CampaignRow from '@/views/TikTok/Campaigns/components/CampaignRow'
import AdGroupRow from '@/views/TikTok/Campaigns/components/AdGroupRow'

interface IProps {
    campaigns: any
    setCampaignsToBeDeleted: (campaignId: number) => void
    setBulkCampaignsToBeDeleted: (campaignIds: Set<number>) => void
}

const ImportedCampaignsTable = ({ campaigns, setCampaignsToBeDeleted, setBulkCampaignsToBeDeleted }: IProps) => {
    const [expandedCampaigns, setExpandedCampaigns] = React.useState<Set<number> | null>(null)
    const [selectedCampaigns, setSelectedCampaigns] = React.useState<Set<number>>(new Set())

    const handleExpandAll = () => {
        if (!expandedCampaigns || expandedCampaigns.size !== campaigns.length) {
            setExpandedCampaigns(new Set(campaigns.map((campaign: any) => campaign.id)))
        } else {
            setExpandedCampaigns(null)
        }
    }

    const handleSelectAll = () => {
        if (selectedCampaigns.size === campaigns.length) {
            setSelectedCampaigns(new Set())
        } else {
            setSelectedCampaigns(new Set(campaigns.map((campaign: any) => campaign.id)))
        }
    }

    const handleDelete = (campaignId: number) => {
        setCampaignsToBeDeleted(campaignId)
    }

    const handleDeleteSelected = () => {
        setBulkCampaignsToBeDeleted(selectedCampaigns)
    }

    const handleSelect = (campaignId: number) => {
        setSelectedCampaigns((prev) => {
            const next = new Set(prev)
            next.has(campaignId) ? next.delete(campaignId) : next.add(campaignId)
            return next
        })
    }

    const campaignsCount = campaigns.length

    return (
        <>
<div className="relative my-3 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
      <table className="w-full overflow-y-auto border-collapse divide-y divide-gray-300 table-fixed" data-testid="activations-campaigns-results-table">
        <HeaderGroup {...{ campaigns, expandedCampaigns, selectedCampaigns, campaignsCount, handleExpandAll, handleSelectAll, handleDeleteSelected }} />
        <tbody className="bg-white divide-y divide-gray-200">
          {campaigns.map((campaign: any) => (
            <tr key={campaign.id}>
              <td colSpan={7}>
                <table
                  className="w-full table-fixed"
                  data-testid="campaign-row"
                >
                  <TableColGroup />
                  <tbody>
                    <CampaignRow
                      campaign={campaign}
                      expandedCampaigns={expandedCampaigns}
                      selectedCampaigns={selectedCampaigns}
                      setExpandedCampaigns={setExpandedCampaigns}
                      handleSelect={handleSelect}
                      handleDelete={handleDelete}
                    />
                    {expandedCampaigns?.has(campaign.id) && (
                      <tr
                        data-testid="campaign-row-wrapper"
                        key={campaign.id + 'campaign-wrapper'}
                      >
                        <td colSpan={7}>
                          <table
                            className="w-full table-fixed"
                            data-testid="adgroup-row-table"
                          >
                            <thead className="bg-gray-50">
                                <tr>
                                    <th
                                    scope="col"
                                    className="relative w-16"
                                    >
                                        <div className="flex gap-1 pl-2">
                                        </div>
                                    </th>
                                    <th
                                        scope="col"
                                        className="py-3 text-sm font-semibold text-left text-gray-600"
                                    >
                                            AdGroup Id
                                    </th>
                                    <th
                                    scope="col"
                                    className="py-3 text-sm font-semibold text-left text-gray-600"
                                    >
                                        AdGroup Name
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                              {campaign.adGroups?.map((adGroup: any) => (
                                <AdGroupRow
                                  adGroup={adGroup}
                                />
                              ))}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
        </>
    )
}

const TableColGroup = () => (
    <colgroup>
      <col className="relative px-4 w-16" />
      <col className="py-4 pr-3" />
      <col className="py-4 pr-3" />
      <col className="px-3 py-3" />
      <col className="px-3 py-3 w-28" />
      <col className="px-3 py-3 w-14" />
      <col className="relative w-12 py-3 pl-3 pr-4 sm:pr-6" />
    </colgroup>
  )

export default ImportedCampaignsTable