import DateRangeSection from '@/components/Sightly/SightlyFormElements/DateRangeSection';
import { PATH_TRENDS_SEARCH } from '@/routes';
import { ranges } from '@/views/ReportGenerator/components/formSections/boardsDateRange-types';
import CategoryFilter from '@/views/Trends/components/CategoryFilter';
import SearchBar from '@/views/Trends/components/SearchBar';
import TopicTrendCardList from '@/views/Trends/components/TopicTrendCardList';
import { useTopic } from '@/views/Trends/hooks/useTopics';
import { useTrend } from '@/views/Trends/hooks/useTrends';
import { ICategory, TopicTrendCardType, allCategories, categories } from '@/views/Trends/types';
import { useNavigate } from '@tanstack/react-location';
import { useFlag } from '@unleash/proxy-client-react';
import { subDays } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Placeholder, RadioGroup } from 'rsuite';

const Trends = () => {
  const navigate = useNavigate();
  const trendsEnabled = useFlag('enable_trends_feature');
  if (!trendsEnabled) navigate({ to: '/app/discover/moments/v2' });

  const [category, setCategory] = useState<ICategory>(allCategories)
  const [filteringEnabled, setFilteringEnabled] = useState(false)
  const [cardType, setCardType] = useState<TopicTrendCardType>('Topic')
  const [dateRange, setDateRange] = useState<[Date, Date]>(
    ranges.find(range => range.label === 'Last 7 Days')?.value()!
  );

  const endDate = dateRange ? dateRange[1] : new Date();
  const startDate = dateRange ? dateRange[0] : subDays(endDate, 60);

  const { topicsQuery, isLoadingTopics } = useTopic();

  const { trendsQuery, isLoadingTrends } = useTrend();

  useEffect(() => {
    const topicsLoaded = cardType === 'Topic' && !isLoadingTopics
    const trendsLoaded = cardType === 'Trend' && !isLoadingTrends
    if (topicsLoaded || trendsLoaded) {
      setFilteringEnabled(true)
    }
  }, [cardType, isLoadingTopics, isLoadingTrends])

  useEffect(() => {
    const topicsLoaded = cardType === 'Topic' && !isLoadingTopics
    const trendsLoaded = cardType === 'Trend' && !isLoadingTrends
    if (topicsLoaded || trendsLoaded) {
      setFilteringEnabled(true)
    }
  }, [cardType, isLoadingTopics, isLoadingTrends])

  const handleSearch = (searchTerm: string) => {
    navigate({
      to: PATH_TRENDS_SEARCH,
      search: { searchTerm }
    });
  }

  const handleCategorySelect = (selectedCategory: ICategory) => {
    if (!(isLoadingTopics && isLoadingTrends)) {
      setCategory(selectedCategory);
    }
  }

  const handleChangeCardsType = (selectedCardType: TopicTrendCardType) => {
    if (selectedCardType === cardType) return;
    setCardType(selectedCardType);
  }

  const handleChangeDateRange = (selectedRange: [Date, Date]) => {
    if (selectedRange === dateRange) return;
    setDateRange(selectedRange);
  }

  return (
    <div className='p-2 h-full bg-white'>
      <div className="flex flex-col h-full">
        <SearchBar
          label={'Explore what’s trending, now'}
          height={'20vh'}
          onSearch={handleSearch} />
        <div className='h-full overflow-hidden flex pt-4'>
          <div className='w-1/5 h-full'>
            {categories ? (
              <CategoryFilter
                defaultCategory={allCategories}
                categories={categories}
                onCategorySelect={handleCategorySelect}
                disabled={!filteringEnabled} />
            ) : (
              <Placeholder.Graph
                className="h-[100%!important] w-[100%!important]"
                active
              />
            )}
          </div>
          <div className='w-4/5 pt-4 px-4 bg-gray-50 flex flex-col gap-4'>
            <div className='flex flex-row justify-between items-center'>
              <RadioGroup name="radio-group-inline" inline defaultValue={cardType}>
                <div className='ml-2 flex gap-4'>
                  {(['Topic', 'Trend'] as TopicTrendCardType[]).map((type) => {
                    return (
                      <div
                        data-testid={`trend-topic-overview-select-${type}`}
                        key={type}
                        className={`rounded-full py-3 px-5 cursor-pointer ${cardType === type ? 'text-white bg-sightlyPurple' : 'text-gray-400 bg-gray-200 hover:text-gray-500 hover:bg-gray-300'
                          }`}
                        onClick={() => handleChangeCardsType(type)}
                      >
                        <span className='font-extrabold'>{type}</span>
                      </div>
                    );
                  })}
                </div>
              </RadioGroup>
              <span data-testid="topic-trend-overview-date-range-picker-wrapper">
                <DateRangeSection
                  onSelectDateRange={handleChangeDateRange}
                  ranges={ranges}
                  rangeValue={[startDate, endDate]}
                />
              </span>
            </div>
            <div className='overflow-y-auto'>
              {category && (
                cardType === 'Topic' ? (
                  <TopicTrendCardList
                    cardType={'Topic'}
                    query={topicsQuery}
                    isLoading={isLoadingTopics}
                    category={category}
                    startDate={startDate}
                    endDate={endDate}
                  />
                ) : (
                  <TopicTrendCardList
                    cardType={'Trend'}
                    query={trendsQuery}
                    isLoading={isLoadingTrends}
                    category={category}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Trends;