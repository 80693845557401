import { MyLocationGenerics } from '@/classes/utils'
import { InfoPage } from '@/components/InfoPage'
import { LoadingPage } from '@/components/LoadingPage'
import VerticalTabs from '@/components/VerticalTabs'
import PlatformsOverview from '@/views/Discover/Boards/Narratives/OverviewSidePanel/PlatformsOverview'
import ThemesOverview from '@/views/Discover/Boards/Narratives/OverviewSidePanel/ThemesOverview'
import SocialOverviewTabContents from '@/views/Discover/Boards/Narratives/SocialNarrativePanel/SocialOverviewTabContents'
import SocialPostsTabContents from '@/views/Discover/Boards/Narratives/SocialNarrativePanel/SocialPostsTabContents'
import useSocialClusterById from '@/views/Discover/Boards/Narratives/SocialNarrativePanel/useSocialClusterById'
import {
  SOCIAL_NARRATIVE_PANEL_DEFAULT_TAB_ID,
  SOCIAL_NARRATIVE_PANEL_TAB_OPTIONS
} from '@/views/Discover/Boards/Narratives/constants'
import { ArrowLeftIcon } from '@heroicons/react/20/solid'
import { useMatch, useSearch } from '@tanstack/react-location'
import React from 'react'

export const NarrativeDetails = () => {
  const {
    params: { id }
  } = useMatch<MyLocationGenerics>()

  const {
    tab
  } = useSearch<MyLocationGenerics>();

  const [activeTabId, setActiveTabId] = React.useState<string>(tab || SOCIAL_NARRATIVE_PANEL_DEFAULT_TAB_ID)

  const { socialClusterData, socialClusterIsLoading } = useSocialClusterById(id)

  const renderTabContent = () => {
    switch (activeTabId) {
      case SOCIAL_NARRATIVE_PANEL_TAB_OPTIONS[0].id:
        return <SocialOverviewTabContents overview={socialClusterData?.overview} />
      case SOCIAL_NARRATIVE_PANEL_TAB_OPTIONS[1].id:
        return <SocialPostsTabContents posts={socialClusterData?.posts} />
      case SOCIAL_NARRATIVE_PANEL_TAB_OPTIONS[2].id:
        return <PlatformsOverview platformsOverview={socialClusterData?.platforms} />
      case SOCIAL_NARRATIVE_PANEL_TAB_OPTIONS[3].id:
        return <ThemesOverview themesOverview={socialClusterData?.themes} />
      default:
        return <InfoPage message='The selected tab does not exist.' />
    }
  }

  return (
    <div className='w-full h-full'>
      <div className="flex pl-6 py-4 gap-4 text-xl items-center ">
        <ArrowLeftIcon data-testid='narrative-details-back-button' stroke='bold' className='h-7 w-7 font-bold cursor-pointer' onClick={() => window.history.back()} />
        {!socialClusterData ? (
          <div className='!font-bold'>
            <LoadingPage message="Narrative" />
          </div>
        ) : (
          <div>
            <span data-testid='narrative-details-title' className="font-bold">Narrative:</span> {socialClusterData.name}
          </div>
        )}
      </div>
      <div className={`flex ${!socialClusterData ? 'h-full' : ''} min-h-[82vh] gap-3 bg-gray-200`}>
        <VerticalTabs
          active={activeTabId}
          onChange={(activeTabId: string) => {
            setActiveTabId(activeTabId)
          }}
          disabled={!socialClusterData}
        >
          {SOCIAL_NARRATIVE_PANEL_TAB_OPTIONS.map(({ name, id }) => (
            <div
              data-testid={`narrative-details-tab-${id}`}
              className="pr-6 text-sm break-words"
              key={id}
            >
              {name}
            </div>
          ))}
        </VerticalTabs>

        <div className="w-full px-2 py-4 h-full">
          <div className='h-full bg-white rounded-md'>
            { !socialClusterData ?
              <LoadingPage message="Loading narrative data" />
              : renderTabContent()
            }
          </div>
        </div>
      </div>
    </div>
  )
}