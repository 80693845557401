import { MyLocationGenerics } from '@/classes/utils'
import { InfoPage } from '@/components/InfoPage'
import { LoadingPage } from '@/components/LoadingPage'
import { SightlyInput } from '@/components/Sightly/SightlyFormElements/SightlyInput'
import SightlyGradientCard from '@/components/Sightly/SightlyGradientCard'
import Tabs from '@/components/Tabs/Tabs'
import useBrandProfiles from '@/hooks/brandProfile/useBrandProfiles'
import BoardSelectSearch from '@/views/Discover/Boards/components/BoardSelectSearch'
import Overview from '@/views/Trends/SearchResults/components/Overview'
import { MomentTab } from '@/views/Trends/SearchResults/components/Tabs/MomentTab'
import { NarrativeTab } from '@/views/Trends/SearchResults/components/Tabs/NarrativeTab'
import { TopicTab } from '@/views/Trends/SearchResults/components/Tabs/TopicTab'
import { TrendTab } from '@/views/Trends/SearchResults/components/Tabs/TrendTab'
import { useGenerateSummaryData } from '@/views/Trends/hooks/useAnalysis'
import { useNarrativeSearch } from '@/views/Trends/hooks/useNarratives'
import { useTopicTrendMoments } from '@/views/Trends/hooks/useTopicTrendMoments'
import { useTopicSearch } from '@/views/Trends/hooks/useTopics'
import { useTrendSearch } from '@/views/Trends/hooks/useTrends'
import { ICurrentTabData, IMoment, INarrative, ITopic, ITrend, SearchResultTabs, TrendsSearchResultSortBy } from '@/views/Trends/types'
import { SparklesIcon } from '@heroicons/react/20/solid'
import { useNavigate, useSearch } from '@tanstack/react-location'
import { useFlag } from '@unleash/proxy-client-react'
import React, { useEffect, useState } from 'react'
import { TabsFilters } from './SearchResults/components/TabsFilters'

export const TrendsResult = () => {
  const navigate = useNavigate();
  const trendsEnabled = useFlag('enable_trends_feature');
  if (!trendsEnabled) navigate({ to: '/app/discover/moments/v2' });

  const [brandProfileId, setBrandProfileId] = useState<number>(-1);
  const tabs: SearchResultTabs[] = ['Topic', 'Trend', 'Moment', 'Narrative'];

  const [activeTab, setActiveTab] = useState(tabs[0])
  const [activeTabData, setActiveTabData] = useState<ICurrentTabData>()
  const [tabsCountData, setTabsCountData] = useState<{ tab: SearchResultTabs, count: number }[]>()

  const [debouncedSearch, setDebouncedSearch] = useState('');

  const { searchTerm } = useSearch<MyLocationGenerics>();
  const [search, setSearch] = useState<string>(searchTerm ?? '');

  const {
    data: brandProfiles
  } = useBrandProfiles({
    submittedOnly: false
  });

  const pageSize = 10;

  const [callSummaryOnSearch, setCallSummaryOnSearch] = useState(false);

  const [topics, setTopics] = useState<ITopic[]>([]);
  const [defaultTopics, setDefaultTopics] = useState<ITopic[]>([]);
  const [topicPage, setTopicPage] = useState(1);
  const { topicsQuery, isLoadingTopics } = useTopicSearch(search);

  const [trends, setTrends] = useState<ITrend[]>([]);
  const [defaultTrends, setDefaultTrends] = useState<ITrend[]>([]);
  const [trendPage, setTrendPage] = useState(1);
  const { trendsQuery, isLoadingTrends } = useTrendSearch(search);

  const [moments, setMoments] = useState<IMoment[]>([]);
  const [defaultMoments, setDefaultMoments] = useState<IMoment[]>([]);
  const [momentPage, setMomentPage] = useState(1);
  const { momentsQuery, isLoadingMoments } = useTopicTrendMoments(search);

  const [narratives, setNarratives] = useState<INarrative[]>([]);
  const [defaultNarratives, setDefaultNarratives] = useState<INarrative[]>([]);
  const [narrativePage, setNarrativePage] = useState(1);
  const { narrativesQuery, isLoadingNarratives } = useNarrativeSearch(search);

  const [isLoadingTopicsResults, setIsLoadingTopicsResults] = useState(true)
  const [isLoadingTrendsResults, setIsLoadingTrendsResults] = useState(true)
  const [isLoadingMomentsResults, setIsLoadingMomentsResults] = useState(true)
  const [isLoadingNarrativesResults, setIsLoadingNarrativesResults] = useState(true)

  // Filters
  const [category, setCategory] = useState<number>(-1)
  const [timeRange, setTimeRange] = useState<number>(1)
  const [sortBy, setSortBy] = useState<TrendsSearchResultSortBy>('similarityRecommended')

  useEffect(() => {
    if (searchTerm !== undefined && searchTerm !== search) {
      setSearch(searchTerm);
    }
  }, [searchTerm]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearch(debouncedSearch);
    }, 500);

    return () => clearTimeout(handler);
  }, [debouncedSearch]);

  useEffect(() => {
    if (topicsQuery?.data && trendsQuery?.data && momentsQuery?.data && narrativesQuery?.data) {
      setCallSummaryOnSearch(true);

      // Update activeTabData only if the active tab changes
      setActiveTabData((prev) => {
        if (prev?.tab === activeTab) {
          return prev;
        }
        switch (activeTab) {
          case 'Topic':
            return { tab: activeTab, data: topicsQuery.data };
          case 'Trend':
            return { tab: activeTab, data: trendsQuery.data };
          case 'Moment':
            return { tab: activeTab, data: momentsQuery.data };
          case 'Narrative':
            return { tab: activeTab, data: narrativesQuery.data };
          default:
            return prev;
        }
      });

      const totals: { tab: SearchResultTabs; count: number }[] = [
        { tab: 'Topic', query: topicsQuery },
        { tab: 'Trend', query: trendsQuery },
        { tab: 'Moment', query: momentsQuery },
        { tab: 'Narrative', query: narrativesQuery },
      ].map(({ tab, query }) => ({
        tab: tab as SearchResultTabs,
        count: query.data.length || 0,
      }));

      setTabsCountData(totals);
    }
  }, [
    activeTab,
    topicsQuery?.data,
    trendsQuery?.data,
    momentsQuery?.data,
    narrativesQuery?.data,
  ]);

  useEffect(() => {
    if (!isLoadingTopics && topicsQuery?.data) {
      setDefaultTopics(topicsQuery.data)
      setTopics(topicsQuery.data)
      setTopics(topicsQuery.data)
      setIsLoadingTopicsResults(false)
    }
  }, [isLoadingTopics])

  useEffect(() => {
    if (!isLoadingTrends && trendsQuery?.data) {
      setDefaultTrends(trendsQuery.data)
      setTrends(trendsQuery.data)
      setIsLoadingTrendsResults(false)
    }
  }, [isLoadingTrends])

  useEffect(() => {
    if (!isLoadingMoments && momentsQuery?.data) {
      setDefaultMoments(momentsQuery.data)
      setMoments(momentsQuery.data)
      setIsLoadingMomentsResults(false)
    }
  }, [isLoadingMoments])

  useEffect(() => {
    if (!isLoadingNarratives && narrativesQuery?.data) {
      setDefaultNarratives(narrativesQuery.data)
      setNarratives(narrativesQuery.data)
      setIsLoadingNarrativesResults(false)
    }
  }, [isLoadingNarratives])

  const handleSearch = (val: string) => setDebouncedSearch(val);

  const handleTabChange = (tab: string) => {
    setActiveTab(tab as SearchResultTabs)
  }

  const momentsToGenerate = momentsQuery?.data?.slice(0, 20) || [];
  const narrativesToGenerate = narrativesQuery?.data?.slice(0, 20) || [];
  const trendsToGenerate = trendsQuery?.data?.slice(0, 20) || [];
  const topicsToGenerate = topicsQuery?.data?.slice(0, 20) || [];

  const { summaryData, isLoadingSummary } = useGenerateSummaryData(
    momentsToGenerate,
    narrativesToGenerate,
    trendsToGenerate,
    topicsToGenerate,
    callSummaryOnSearch
  );

  const sortData = (data: any[], sortBy: TrendsSearchResultSortBy, defaultData: any[]) => {
    if (!data) return [];

    switch (sortBy) {
      case "createdAtDesc":
        return [...data].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      case "similarityRecommended":
      default:
        return defaultData;
    }
  };

  useEffect(() => {
    setIsLoadingTopicsResults(true);
    setIsLoadingTrendsResults(true);
    setIsLoadingMomentsResults(true);
    setIsLoadingNarrativesResults(true);

    // Process data based on the active tab first
    switch (activeTab) {
      case "Topic":
        setTopics(sortData(topicsQuery?.data || [], sortBy, defaultTopics));
        setIsLoadingTopicsResults(false);
        break;

      case "Trend":
        setTrends(sortData(trendsQuery?.data || [], sortBy, defaultTrends));
        setIsLoadingTrendsResults(false);
        break;

      case "Moment":
        setMoments(sortData(momentsQuery?.data || [], sortBy, defaultMoments));
        setIsLoadingMomentsResults(false);
        break;

      case "Narrative":
        setNarratives(sortData(narrativesQuery?.data || [], sortBy, defaultNarratives));
        setIsLoadingNarrativesResults(false);
        break;

      default:
        break;
    }

    if (activeTab !== "Topic") {
      setTopics(sortData(topicsQuery?.data || [], sortBy, defaultTopics));
      setIsLoadingTopicsResults(false);
    }
    if (activeTab !== "Trend") {
      setTrends(sortData(trendsQuery?.data || [], sortBy, defaultTrends));
      setIsLoadingTrendsResults(false);
    }
    if (activeTab !== "Moment") {
      setMoments(sortData(momentsQuery?.data || [], sortBy, defaultMoments));
      setIsLoadingMomentsResults(false);
    }
    if (activeTab !== "Narrative") {
      setNarratives(sortData(narrativesQuery?.data || [], sortBy, defaultNarratives));
      setIsLoadingNarrativesResults(false);
    }
  }, [category, timeRange, sortBy])

  return (
    <div className='px-12 py-8 h-[90%] bg-white rounded-md mx-auto flex flex-col gap-4'>
      <div className="flex justify-between items-center">
        <div className='flex gap-6' data-testid="search-bar">
          <p className='text-2xl'>Explore</p>
          <SightlyInput
            placeholder="Search"
            onChange={handleSearch}
            id="search"
            hasSearchIcon
            maxLength={140}
            data-testid="search-input"
          />
        </div>
        <BoardSelectSearch
          value={brandProfiles && brandProfiles[0].brandProfileId}
          options={brandProfiles}
          label={"Brand Profile"}
          labelKey="brandProfileName"
          valueKey="brandProfileId"
          onChange={setBrandProfileId}
          size='small'
        />
      </div>
      <div className='w-full h-full flex animate-in fade-in'>
        <div className="w-2/3 flex flex-col pr-4 border-r border-gray-200">
          <div className='flex flex-col gap-2'>
            <SightlyGradientCard>
              <p className="text-highlightPurple text-sm font-bold">
                <SparklesIcon
                  className="h-5 w-5 text-highlightPurple inline mr-2"
                  aria-hidden="true"
                />
                Summary
              </p>
              {isLoadingSummary ? (
                <div className='my-8'>
                  <LoadingPage message='Generating summary' />
                </div>
              ) : (
                <div className='my-2 h-[10vh] overflow-auto'>
                  {summaryData ? (
                    <p className="text-sm">
                      {summaryData?.summary || 'Summary could not be generated. Please refresh the page to try again. If the problem persists, please contact support.'}
                    </p>
                  ) : (
                    <p className="text-sm">
                      {summaryData?.summary || 'No data to generate a summary.'}
                    </p>
                  )}</div>)}
            </SightlyGradientCard>
          </div>
          <div className='h-[70%] flex flex-col gap-2'>
            <div className="w-full flex flex-col gap-4">
              <Tabs
                width={'100%'}
                marginRight={40}
                active={activeTab}
                onChange={(active: string) => {
                  handleTabChange(active)
                }}
              >
                {tabs.map((tab) => (
                  <div
                    className="flex flex-row items-center"
                    key={tab}
                    data-testid={`tab-${tab}`}
                  >
                    <div>{tab}</div>
                  </div>
                ))}
              </Tabs>
              <TabsFilters
                category={category}
                setCategory={setCategory}
                timeRange={timeRange}
                setTimeRange={setTimeRange}
                sortBy={sortBy}
                setSortBy={setSortBy} />
            </div>
            <div className='h-full flex overflow-y-auto'>
              <>
                {activeTab === 'Topic' && (
                  <TopicTab data={topics} isLoading={isLoadingTopicsResults} page={topicPage} pageSize={pageSize} setPage={setTopicPage} />
                )}

                {activeTab === 'Trend' && (
                  <TrendTab data={trends} isLoading={isLoadingTrendsResults} page={trendPage} pageSize={pageSize} setPage={setTrendPage} />
                )}

                {activeTab === 'Moment' && (
                  <MomentTab
                    data={moments}
                    isLoading={isLoadingMomentsResults}
                    page={momentPage}
                    pageSize={pageSize}
                    setPage={setMomentPage}
                    brandProfileId={brandProfileId} />
                )}

                {activeTab === 'Narrative' && (
                  <NarrativeTab
                    data={narratives}
                    isLoading={isLoadingNarrativesResults}
                    page={narrativePage}
                    pageSize={pageSize}
                    setPage={setNarrativePage}
                    brandProfileId={brandProfileId} />
                )}
              </>
            </div>
          </div>
        </div>
        <div className='w-1/3 flex flex-col gap-4 pl-4'>
          {isLoadingTopics || isLoadingTrends || isLoadingMoments || isLoadingNarratives ? (
            <LoadingPage message={'Loading overview'} />
          ) : activeTabData && tabsCountData ?
            (
              <Overview
                currentTabData={activeTabData}
                tabsCountData={tabsCountData} />
            ) : (
              <InfoPage message={'No overview'} />
            )
          }
        </div>
      </div>
    </div>
  )
}
