import React from 'react';
import { IArticle } from '@/views/Trends/types';
import { ClockIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import NoImageFoundPath from '@/assets/img/NoImageFound.svg';

dayjs.extend(relativeTime);

interface ArticleCardProps {
  article: IArticle
}

const ArticleResultCard = ({ article }:ArticleCardProps) => {
  const noImage = '/static/media/NoImageFound.8a746d82a5c36e8a94d3ea656f497279.svg';
  const imageSrc = (article.imageUrl === noImage || Boolean(article.imageUrl) === false) ? NoImageFoundPath : article.imageUrl;

  return <div className="flex items-center p-4">
    <img src={imageSrc ?? ''} alt={article.title} className="w-20 h-20 mr-4" />
    <div className="flex flex-col">
      <h3 className="text-lg font-semibold">{article.title}</h3>
      <p className="text-gray-500">
        <ClockIcon className="h-4 mr-1 inline-block" />
        <span>
          {article.sourceName}
        </span>
        <span className="text-gray-400 text-sm ml-1">
          {dayjs(article.publishedAt).fromNow()}
        </span>
      </p>
    </div>
  </div>
};

export default ArticleResultCard;