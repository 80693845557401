import {
  ReportTemplate,
  REPORT_TYPE,
  Option,
  TEMPLATE_TYPE,
  templateGenericDimensionOptions,
  templateMetricOptions,
  templateConversionOptions,
  GENERIC_DIMENSION,
  METRIC,
  CONVERSION,
  GenericDimensionOption,
} from "@/views/ActivationReports/types/activation-report.types";

export enum AGE_DIMENSION {
  AGE_RANGE = 'ageRange',
  DEVICE = 'device',
}

interface AgeDimensionOption extends Omit<GenericDimensionOption, 'value'> {
  name: string
  value: GENERIC_DIMENSION | AGE_DIMENSION
}


export interface AgeReport extends Omit<ReportTemplate, 'columns' | 'dimensions'> {
  columns: (GENERIC_DIMENSION | AGE_DIMENSION | METRIC | CONVERSION)[],
  dimensions: (GenericDimensionOption | AgeDimensionOption)[]
}

export const dimensionOptions: Option<AgeDimensionOption> = {
  [AGE_DIMENSION.AGE_RANGE]: {
    name: 'Age Range',
    value: AGE_DIMENSION.AGE_RANGE
  },
  [AGE_DIMENSION.DEVICE]: {
    name: 'Device',
    value: AGE_DIMENSION.DEVICE
  },
}


export const baseAgeReport: AgeReport = {
  name: 'Age Report',
  description: '',
  type: REPORT_TYPE.DETAIL,
  templateType: TEMPLATE_TYPE.AGE,
  columns: [],
  dimensions: [
    ...templateGenericDimensionOptions,
    ...Object.keys(dimensionOptions).map(key => dimensionOptions[key])
  ],
  metrics: templateMetricOptions,
  conversions: templateConversionOptions
}

export const ageRangeReportTemplate: AgeReport = {
  ...baseAgeReport,
  name: 'Age Range Report',
  columns: [
    METRIC.STAT_DATE,
    GENERIC_DIMENSION.CUSTOMER_ID,
    GENERIC_DIMENSION.INSERTION_ORDER_NAME,
    GENERIC_DIMENSION.AD_FORMAT,
    AGE_DIMENSION.AGE_RANGE,
    METRIC.IMPRESSIONS,
    METRIC.CPM,
    METRIC.VIEWS,
    METRIC.CLICKS,
    METRIC.CTR,
    METRIC.COST,
    METRIC.VCR,
    METRIC.QUARTILE_25,
    METRIC.QUARTILE_50,
    METRIC.QUARTILE_75,
    METRIC.QUARTILE_100,
  ],
}
