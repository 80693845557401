import React from 'react';
import { LoadingPage } from '@/components/LoadingPage';
import MomentVideo from './MomentVideo';
import { ClusterType, MomentVideoType } from './../types';
import SightlyButton from '@/components/Sightly/SightlyButton';

interface MomentVideosProps {
    moment: ClusterType;
    videos: MomentVideoType[];
    isLoading: boolean;
    page: number;
    pageSize: number;
    totalItems: number;
    handlePageChange: (newPage: number) => void;
}

const MomentVideos = ({
    videos,
    moment,
    isLoading,
    page,
    pageSize,
    totalItems,
    handlePageChange
}: MomentVideosProps) => {
    const currentCount = videos.length;

    return (
        <div data-testid="moment-modal-body-videos" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div data-testid="moment-modal-videos-count">
                {moment.videoCount === 0 && !isLoading && (
                    <div
                        style={{
                            height: 28,
                            paddingRight: 8,
                            borderRadius: 3,
                            backgroundColor: '#F2FAFD',
                            paddingTop: 3,
                            paddingLeft: 8,
                            fontWeight: 500,
                            fontSize: 14,
                            lineHeight: '22px',
                            marginTop: 24
                        }}
                    >
                        Currently we have no Videos for this Moment.
                    </div>
                )}

                {moment.videoCount > 0 && !isLoading && (
                  <div data-testid="moment-modal-videos-count"
                       className="flex justify-center h-7 pr-2 rounded-md pt-1 pl-2 font-medium text-sm leading-[22px]">
                      Showing {currentCount} of {totalItems} videos.
                  </div>
                )}

                <div
                    id="videosTab"
                    data-testid="moment-modal-videos-tab"
                    style={{
                        overflowY: 'auto',
                        marginTop: 24,
                        textAlign: 'left'
                    }}
                >
                    {isLoading && videos.length == 0 ? (
                        <LoadingPage message="Fetching videos" />
                    ) : (
                        <>
                            {videos.map((video) => (
                                <MomentVideo
                                    key={video.videoId}
                                    video={video}
                                    clusterId={moment.clusterId}
                                />
                            ))}
                        </>
                    )}
                </div>
            </div>

            {videos.length != 0 &&  (
                <div className="flex justify-center w-[540px] py-4">
                    <SightlyButton
                        datatestid="load-more-moments-button"
                        block
                        id="loadmorebutton"
                        text="Load More"
                        handleClick={() => handlePageChange(page + 1)}
                        loading={isLoading}
                        disabled={page >= Math.ceil(totalItems / pageSize)}
                    />
                </div>
            )}
        </div>
    );
};

export default MomentVideos;
