import React from "react";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { DateRangePicker } from "rsuite";
import { ranges } from '@/views/ReportGenerator/reportGenerator-types';
import { ActivationReportConfiguration } from "@/views/ActivationReports/types/activation-report.types";
import { log } from "console";


interface IProps {
  handleOnChange: Function
  formValues: ActivationReportConfiguration
}


export default function DateRange({ handleOnChange, formValues }: IProps) {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault("UTC");

  const utcToLocal = (utcDate: Date): Date => {
    return dayjs.utc(utcDate).hour(12).minute(0).second(0).toDate();
  };


  return (
    <div data-testid='div-report-generator-select-date-range'>
      <h5 className="mt-3 mb-2 text-base">Date Range</h5>
      <div className="relative">
        <DateRangePicker
          ranges={ranges}
          value={[utcToLocal(formValues.startDate), utcToLocal(formValues.endDate)]}
          onChange={(dates) => handleOnChange(dates)}
          placeholder="Select Date(s)"
          placement="autoVerticalStart"
          cleanable={false}
        />
      </div>
    </div>
  )
}
