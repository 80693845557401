import SlideOver from '@/components/SlideOver';
import React, { useState, useEffect, useCallback } from 'react'
import { Layer, Rectangle, ResponsiveContainer, Sankey, Tooltip, TooltipProps } from 'recharts';
import { useSearch } from '@tanstack/react-location';
import TrendMomentViewSlideOver from '@/views/Trends/components/TrendMomentSlideover';
import TopicMomentViewSlideOver from '@/views/Trends/components/TopicMomentSlideover';
import { Placeholder } from 'rsuite';
import { LoadingPage } from '@/components/LoadingPage';
import { SearchResult } from '@/views/Trends/types';

interface IProps {
    dataNarratives: any,
    dataMoments: any,
    loading: boolean
    trendId?: string,
    topicId?: string,
}

const SankeyGraphComponent = ({ dataNarratives, dataMoments, loading, trendId, topicId }: IProps) => {
    const search = useSearch();
    const [showSlideOver, setShowSlideOver] = React.useState(false)

    // Effect to set showSlideOver state based on the presence of "slideover" param in the URL and the selectedType
    React.useEffect(() => {
        // if slideover was triggered for trends and we're on trend-details page or it was triggered for topics and we're on topic-details page
        if (search.slideover === 'trend' && trendId || search.slideover === 'topic' && topicId) {
            setShowSlideOver(true);
        } else {
            setShowSlideOver(false);
        }

        if (search.selectedType && (search.selectedType === "Moments" || search.selectedType === "Narratives")) {
            setSelectedType(search.selectedType as "Moments" | "Narratives");
        }
    }, [search.slideover]);

    // Effect to update the URL based on the showSlideOver state and the selectedType
    React.useEffect(() => {
        if (showSlideOver) {
            window.history.replaceState(null, '', `${window.location.pathname}?slideover=${trendId ? 'trend' : 'topic'}&selectedType=${selectedType}`);
        } else {
            window.history.replaceState(null, '', `${window.location.pathname}`);
        }
    }, [showSlideOver]);

    const SANKEY_BASE_NODE_COLOR = 'rgba(79, 70, 229, 1)';
    const SANKEY_GREEN_NODE_COLOR = 'rgba(72, 194, 148, 1)';

    const SANKEY_BASE_FILL_COLOR = 'rgba(0, 136, 254, 0.5)';
    const SANKEY_BASE_FILL_COLOR_HOVER = 'rgba(0, 125, 204, 0.5)';

    const SANKEY_BASE_FILL_COLOR_GREEN = 'rgba(72, 194, 148, 0.3)';
    const SANKEY_BASE_FILL_COLOR_HOVER_GREEN = 'rgba(72, 194, 148, 0.5)';

    interface NodeProps {
        x: number;
        y: number;
        width: number;
        height: number;
        index: number;
        type: string;
        payload: {
            name: string;
            value: number;
        };
        containerWidth: number;
        onNodeLayout?: (nodeProps: any) => void;
    }

    interface CustomLinkProps {
        sourceX: number;
        targetX: number;
        sourceY: number;
        targetY: number;
        sourceControlX: number;
        targetControlX: number;
        linkWidth: number;
        index: number;
        type: SearchResult;
    }

    const Link: React.FC<CustomLinkProps> = ({
        sourceX,
        targetX,
        sourceY,
        targetY,
        sourceControlX,
        targetControlX,
        linkWidth,
        index,
        type
    }) => {
        let baseColor = SANKEY_BASE_FILL_COLOR;
        let hoverColor = SANKEY_BASE_FILL_COLOR_HOVER;

        switch (type) {
            case 'topic':
                baseColor = SANKEY_BASE_FILL_COLOR;
                hoverColor = SANKEY_BASE_FILL_COLOR_HOVER;
                break;
            case 'trend':
                baseColor = SANKEY_BASE_FILL_COLOR;
                hoverColor = SANKEY_BASE_FILL_COLOR_HOVER;
                break;
            case 'moment':
                baseColor = SANKEY_BASE_FILL_COLOR_GREEN;
                hoverColor = SANKEY_BASE_FILL_COLOR_HOVER_GREEN;
                break;
            default:
                baseColor = SANKEY_BASE_FILL_COLOR;
                hoverColor = SANKEY_BASE_FILL_COLOR_HOVER;
                break;
        }

        const [fill, setFill] = useState(baseColor);

        return (
            <Layer key={`CustomLink${index}`}>
                <path
                    d={`
            M${sourceX},${sourceY + linkWidth / 2}
            C${sourceControlX},${sourceY + linkWidth / 2}
              ${targetControlX},${targetY + linkWidth / 2}
              ${targetX},${targetY + linkWidth / 2}
            L${targetX},${targetY - linkWidth / 2}
            C${targetControlX},${targetY - linkWidth / 2}
              ${sourceControlX},${sourceY - linkWidth / 2}
              ${sourceX},${sourceY - linkWidth / 2}
            Z
          `}
                    fill={fill}
                    strokeWidth="0"
                    onMouseEnter={() => setFill(hoverColor)}
                    onMouseLeave={() => setFill(baseColor)}
                />
            </Layer>
        );
    };


    const Node: React.FC<NodeProps> = React.memo(({
        x,
        y,
        width,
        height,
        index,
        type,
        payload,
        containerWidth,
        onNodeLayout
    }) => {
        useEffect(() => {
            if (onNodeLayout) {
                onNodeLayout({ x, y, width, height, index });
            }
        }, [x, y, width, height, index, onNodeLayout]);

        let baseColor = SANKEY_BASE_NODE_COLOR;

        switch (type) {
            case 'topic':
                baseColor = SANKEY_BASE_NODE_COLOR;
                break;
            case 'trend':
                baseColor = SANKEY_BASE_NODE_COLOR;
                break;
            case 'moment':
                baseColor = SANKEY_GREEN_NODE_COLOR;
                break;
            default:
                break;
        }

        return (
            <Layer key={`custom-node-${payload.name}`}>
                <Rectangle
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    fill={baseColor}
                    fillOpacity="1"
                />
                {(type === "Moment" || type === "narrative") && (
                    <text
                        textAnchor="start"
                        x={x + width + 6}
                        y={y + height / 2 + 4}
                        fontSize="12"
                        fill="#333"
                        fillOpacity="0.8"
                    >
                        {splitText(payload.name, 60).map((line, i) => (
                            <tspan key={payload.name} x={x + width + 6} dy={i === 0 ? 0 : 14}>
                                {line}
                            </tspan>
                        ))}
                    </text>
                )}
            </Layer>
        );
    });
    
    const [selectedType, setSelectedType] = useState<"Moments" | "Narratives">("Moments");

    const handleTypeChange = (type: React.SetStateAction<"Moments" | "Narratives">) => {
        if (loading) return

        setSelectedType(type);
    };

    // Store the layout (x, y, width, height) of each node as they are rendered
    const [nodePositions, setNodePositions] = useState<any[]>([]);

    // This will hold the rightmost node for positioning
    const [rightmostNode, setRightmostNode] = useState<{ x: number, y: number, width: number, height: number }>({ x: 0, y: 0, width: 0, height: 0 });

    // Callback used by each Node to bubble up node layout data
    const onNodeLayout = React.useCallback((newProps: any) => {
        setNodePositions((prev) => {
            const existingIndex = prev.findIndex((p) => p.index === newProps.index);
            if (existingIndex !== -1) {
                const oldProps = prev[existingIndex];
                // Check if coordinates or size changed "enough" to justify state update
                const isUnchanged =
                    Math.abs(oldProps.x - newProps.x) < 0.5 &&
                    Math.abs(oldProps.y - newProps.y) < 0.5 &&
                    Math.abs(oldProps.width - newProps.width) < 0.5 &&
                    Math.abs(oldProps.height - newProps.height) < 0.5;

                if (isUnchanged) {
                    return prev; // no change, no new state
                }

                const updated = [...prev];
                updated[existingIndex] = newProps;
                return updated;
            }
            return [...prev, newProps];
        });
    }, []);

    // Recompute the rightmost node and store it in state
    useEffect(() => {
        if (nodePositions.length === 0) return;
        const rightmost = nodePositions.reduce(
            (max, node) => {
                const nodeRight = node.x + node.width;
                return nodeRight > max.rightPos ? { node, rightPos: nodeRight } : max;
            },
            { node: null, rightPos: -Infinity }
        );

        if (rightmost.node) {
            setRightmostNode(rightmost.node);
        }
    }, [nodePositions]);

    return (
        <div className="flex flex-col justify-center w-full relative mt-12">
            <div className="flex flex-col gap-[2vh] justify-center items-end" >
                <div 
                    className='flex gap-[1vw]'
                    // Position the type selection buttons based on the rightmost node
                    style={{
                        position: 'absolute',
                        left: rightmostNode.x + rightmostNode.width + 8,
                        top: rightmostNode.y - 70,
                        color: '#00AEEF',
                        cursor: 'pointer',
                        display: rightmostNode.x > 0 ? 'flex' : 'none'
                    }}
                >
                    <button disabled={loading} data-testid="sankey-overview-moments-tab"
                        onClick={() => handleTypeChange('Moments')}
                        className={`px-4 py-2 rounded-full text-sm font-medium transition ${loading ? 'bg-gray-100 text-gray-300' :
                            selectedType === 'Moments'
                                ? 'bg-purple-500 text-white'
                                : 'bg-gray-200 text-black'
                            }`}
                    >
                        Moments
                    </button>
                    <button disabled={loading} data-testid="sankey-overview-narratives-tab"
                        onClick={() => handleTypeChange('Narratives')}
                        className={`px-4 py-2 rounded-full text-sm font-medium transition ${loading ? 'bg-gray-100 text-gray-300' :
                            selectedType === 'Narratives'
                                ? 'bg-purple-500 text-white'
                                : 'bg-gray-200 text-black'
                            }`}
                    >
                        Narratives
                    </button>
                </div>
            </div>

            { loading ?
                <>
                    <LoadingPage message={'Loading sankey chart'}/>
                    <Placeholder.Graph active />
                </>
            :
                <div className="flex justify-center h-[50vh]">
                    <ResponsiveContainer width="100%" height="100%">
                        <Sankey
                            margin={{ top: 30, bottom: 30, right: 600 }}
                            data={selectedType === 'Moments' ? dataMoments : dataNarratives}
                            nodeWidth={20}
                            nodePadding={50}
                            linkCurvature={0.5}
                            iterations={30}
                            link={(props) => (
                                <Link
                                    {...props}
                                    linkWidth={Math.min(props.payload.source.dy, props.payload.target.dy)}
                                    type={props.payload.target.type}
                                    opacity={0.7}
                                />
                            )}
                            node={(props) => (
                                <Node
                                    {...props}
                                    type={props.payload.type}
                                    className="fill-[#696cff] stroke-white stroke-2"
                                    onNodeLayout={onNodeLayout}
                                />
                            )}
                        >
                            <Tooltip content={<CustomTooltip />} />
                        </Sankey>
                    </ResponsiveContainer>
                </div>
            }

            {rightmostNode.x > 0 && (
                <span
                // Place the "View all" text right below the rightmost node of the Sankey graph
                style={{
                    position: 'absolute',
                    left: rightmostNode.x + rightmostNode.width + 8,
                    bottom: rightmostNode.y - 50,
                    color: '#00AEEF',
                    cursor: 'pointer',
                    display: rightmostNode.x > 0 ? 'block' : 'none'
                }}
                onClick={(e) => {
                    e.preventDefault()
                    if (loading) return
                    setShowSlideOver(true)
                }}
                >
                View all {selectedType}
                </span>
            )}

            <SlideOver
                show={showSlideOver}
                onHide={() => setShowSlideOver(false)}
                headerTitle={`View ${selectedType}`}
                headerForegroundColor="text-black"
                headerBackgroundColor="bg-white"
                showToggleFullScreenButton={false}
                maxOffset="60%">

                <div className="px-5 h-full">
                    {trendId ? (
                        <TrendMomentViewSlideOver trendId={trendId} dataType={selectedType} />
                    ) : (
                        <TopicMomentViewSlideOver topicId={topicId} dataType={selectedType} />
                    )}
                </div>
            </SlideOver>
        </div>
    );

}

const CustomTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const data = payload[0].payload;

        if (data && data.payload) {
            const { source, target } = data.payload;

            if (source && target) {
                const label = target.type === "Trend" ? "Trend:" : "Moment:";
                const name = target.name;
                const color = target.type === "Trend" ? "bg-[#5192ca]" : "bg-[#48C294]";

                return (
                    <div className="flex flex-row items-center bg-[#f9f9f9] rounded-lg p-2.5 shadow-md">
                        <div className={`w-4 h-4 ${color} rounded-full m-2`}></div>
                        <div className="flex flex-row max-w-md flex-wrap">
                            <p className="mr-2 font-bold underline">{label}</p>
                            <p className="m-0">{name}</p>
                        </div>
                    </div>
                );
            } else {
                let label = '';
                let color = 'bg-[#5192ca]';

                switch (data.payload.type) {
                    case 'topic':
                        label = 'Topic:'
                        color = 'bg-[#5192ca]';
                        break;
                    case 'trend':
                        label = 'Trend:'
                        color = 'bg-[#5192ca]';
                        break;
                    case 'moment':
                        label = 'Moment:'
                        color = 'bg-[#48C294]';
                        break;
                    default:
                        label = ''
                        break;
                }

                return (
                    <div className="flex flex-row items-center bg-[#f9f9f9] rounded-lg p-2.5 shadow-md">
                        <div className={`w-4 h-4 ${color} rounded-full m-2`}></div>
                        <div className="flex flex-row max-w-md flex-wrap">
                            <p className="mr-2 font-bold underline">{label}</p>
                            <p className="m-0">{data.payload.name}</p>
                        </div>
                    </div>
                );
            }
        }
    }

    return null;
};

const splitText = (text: string, maxLength: number) => {
    const words = text.split(' ');
    const lines = [];
    let currentLine = '';

    words.forEach((word: string) => {
        if ((currentLine + word).length > maxLength) {
            lines.push(currentLine.trim());
            currentLine = word + ' ';
        } else {
            currentLine += word + ' ';
        }
    });

    if (currentLine.trim()) {
        lines.push(currentLine.trim());
    }

    return lines;
};

export default SankeyGraphComponent