import {
    versionObj,
    nonUploadedVideoStatsObj,
    uploadedStatsObj,
    nonUploadedChannelStatsObj,
    versionFilter,
    uploadedVersionFilter,
    postUploadedListVersionResult,
    postListVersionResult
} from '@/schemas/Engage/Lists/schemas';
import { routes } from '@/routes';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { listBuilderAxios } from '@/axiosInstances.js';
import { logError } from '@/utils';

var fileDownload = require('js-file-download');

export const postListService = async data => {
    const list = data.list
    const brandProfileId = data.brandProfileId
    let url = `/brand-profile/${brandProfileId}/smart-list`

    const response = await listBuilderAxios({
        method: 'POST',
        url: url,
        data: list
    })

    if (response.status === 200) {
        postListVersionResult.validate(response.data).catch(function(err) {
            logError(err, {
                info: 'after posting this list version, we received different data from the api than expected after posting a list'
            })
        })

        return response.data
    }
}

export const postUploadedListService = async data => {
    const list = data.list
    const brandProfileId = data.brandProfileId
    let url = `/brand-profile/${brandProfileId}/upload/smart-list`

    const result = await listBuilderAxios({
        method: 'POST',
        data: list,
        url: url
    })

    postUploadedListVersionResult.validate(result.data).catch(function(err) {
        logError(err, {
            info: 'after posting this list version, we received different data from the api than expected after postUploadedList()'
        })
    })

    return result
}

export const saveVersionFilterService = async args => {
    let { versionId, filters } = args
    let url = `/smart-list/version/${versionId}/filter`
    const result = await listBuilderAxios({
        method: 'PATCH',
        data: filters,
        url: url
    })

    return result
}

export const patchListSensitivityService = async args => {
    const { sensitivity, smartListId } = args

    let url = `/smart-list/${smartListId}/block-veto`

    const result = await listBuilderAxios({
        method: 'PATCH',
        data: {
            blockVeto: sensitivity
        },
        url: url
    })

    return result
}

export const fetchVersionService = async versionId => {
    let url = `/smart-list/version/${versionId}`

    const result = await listBuilderAxios({
        method: 'GET',
        url: url
    }).catch(err => {
        if (err.response.status === 404) {
            alert('We are unable to find this smartlist, we will now redirect you.')
            window.location.href = routes.app.engage.lists.lists.path
            return
        } else {
            logError(err)
        }
    })

    versionObj.validate(result.data).catch(function(err) {
        logError(err, {
            info: 'we received different data from the api than expected after fetchVersionService()'
        })
    })

    if (result.data.uploaded) {
        uploadedVersionFilter.validate(result.data.filter).catch(function(err) {
            logError(err, {
            info: 'we received different data from the api than expected after fetchVersionService()'
        })
        })
    } else {
        versionFilter.validate(result.data.filter).catch(function(err) {
            logError(err, {
            info: 'we received different data from the api than expected after fetchVersionService()'
        })
        })
    }

    if (result.data.uploaded) {
        uploadedStatsObj.validate(result.data.stats).catch(function(err) {
            logError(err, {
                info: 'we received different data from the api than expected while fetching list stats'
            })
        })
    }

    if (!result.data.uploaded && result.data.isVideo) {
        nonUploadedVideoStatsObj.validate(result.data.stats).catch(function(err) {
            logError(err, {
                info: 'we received different data from the api than expected while fetching list stats'
            })
        })
    }

    if (!result.data.uploaded && !result.data.isVideo) {
        nonUploadedChannelStatsObj.validate(result.data.stats).catch(function(err) {
            logError(err, {
                info: 'we received different data from the api than expected while fetching list stats'
            })
        })
    }

    if (result.data.filter.actionIds === null) {
        result.data.filter.actionIds = []
    }

    return result.data
}

export const deleteVersionDataItemService = async args => {
    let versionId = args.versionId
    let id = args.id
    let url = `/smart-list/version/${versionId}/data/${id}`
    return await listBuilderAxios.delete(url)
}

export const patchVersionDataService = async args => {
    let url = `/smart-list/version/${args.versionId}/data`
    let params = args.data
    return await listBuilderAxios.patch(url, params)
}

export const patchListNameService = async payload => {
    let url = `/smart-list/${payload.smartListId}`

    const result = await listBuilderAxios.patch(url, {
        smartListName: payload.smartListName
    })
    if (result.status != 200) {
        logError(result, {
            info: 'non 200 response when PATCHing smart list patchListNameService'
        })
    }
    return result
}

const downloadExcelListValidation = Yup.object().shape({
    versionId: Yup.number()
        .strict(true)
        .required(),
    smartListName: Yup.string().required(),
    sampleType: Yup.string(),
    itemCount: Yup.number()
})

export const downloadExcelListService = async payload => {
    downloadExcelListValidation.validate(payload).catch(function(err) {
        logError(err, {
            info: 'sent wrong data to downloadExcelList'
        })
    })

    let url = `/smart-list/version/${payload.versionId}/download`

    const result = await listBuilderAxios({
        method: 'POST',
        url: url,
        responseType: 'blob',
        data: {
            sampleType: payload.sampleType,
            itemCount: payload.itemCount
        }
    })

    fileDownload(result.data, `${payload.smartListName}.xlsx`)
}

export const postGarmFeedbackService = async params => {
    toast.success('Thanks for the feedback!')
    const { versionId, garmIds, videoId } = params

    const args = {
        garmIds: garmIds,
        videoId: videoId
    }
    let url = `/smart-list/version/${versionId}/garm-feedback`

    const result = await listBuilderAxios({
        method: 'POST',
        url: url,
        data: args
    })

    if (result.status != 200) {
        toast.error('error post feedback. please try again later')
    }
    return result
}
