import {
    MagnifyingGlassIcon
} from '@heroicons/react/20/solid';
import React, { useEffect, useState } from 'react';
import { Input, InputGroup } from 'rsuite';
import { FormError } from './SightlyFormError';
import { FormLabel } from './SightlyFormLabel';

interface IProps {
    id: string;
    hasSearchIcon?: boolean;
    hasButton?: boolean;
    buttonIcon?: React.ReactElement;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    error?: string;
    width?: string | number;
    height?: string | number;
    autoFocus?: boolean;
    prefix?: string;
    textarea?: boolean;
    required?: boolean;
    value?: any;
    defaultValue?: any;
    onChange: (val: any) => void;
    onBlur?: () => void;
    dataTestId?: string;
    maxLength?: number;
}

export const SightlyInput = ({
    label,
    placeholder,
    disabled = undefined,
    hasSearchIcon,
    hasButton,
    buttonIcon,
    id,
    error,
    width,
    height,
    autoFocus,
    prefix,
    required,
    defaultValue,
    value,
    textarea,
    onChange,
    onBlur,
    dataTestId,
    maxLength
}: IProps) => {
    const [inputValue, setInputValue] = useState(defaultValue || '');

    useEffect(() => {
        if (value !== undefined) {
            setInputValue(value);
        }
    }, [value]);

    const primaryClassName = `font-normal block w-full border-gray-300 placeholder:font-light placeholder:text-slate-400 focus:border-sightly-blue focus:ring-sightly-blue text-sm disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500`;

    const onChangeValue = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        if (!hasButton) {
            onChange(newValue);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && hasButton) {
            e.preventDefault();
            onChange(inputValue);
        }
    };

    return (
        <div
            data-testid={dataTestId}
            style={{
                textAlign: 'left',
                position: 'relative',
                width: width ? width : undefined,
                height: height ? height : undefined
            }}
        >
            <div className='w-full h-full'>
                {label && (
                    <FormLabel
                        id={id}
                        text={label}
                        required={required}
                    />
                )}
                <InputGroup 
                    inside={hasSearchIcon}
                    className={primaryClassName}
                    disabled={disabled}
                    autoFocus={autoFocus}
                    onChange={onChangeValue}
                    onBlur={onBlur}>
                    {prefix && (
                        <InputGroup.Addon>{prefix}</InputGroup.Addon>
                    )}

                    {hasSearchIcon && (
                        <InputGroup.Addon className='flex items-center justify-center h-full !py-0'>
                            <MagnifyingGlassIcon
                                className="h-5 w-5 text-gray-400"
                            />
                        </InputGroup.Addon>
                    )}

                    {textarea ? (
                        <Input as="textarea"
                            id={id}
                            rows={3} 
                            placeholder={disabled ? '' : placeholder}
                            value={inputValue}
                            maxLength={maxLength}
                            onKeyDown={handleKeyDown}
                        />
                    ) : (
                        <Input placeholder={disabled ? '' : placeholder}
                            value={inputValue}
                            maxLength={maxLength}
                            onKeyDown={handleKeyDown}
                        />
                    )}

                    {hasButton && (
                        <InputGroup.Button style={{ backgroundColor: 'white' }}
                            onClick={() => onChange(inputValue)}>
                            <div className="flex items-center justify-center p-1.5 rounded-full h-8 w-8 bg-gray-200 hover:bg-gray-100 text-gray-400 hover:text-gray-300">
                                {buttonIcon}
                            </div>
                        </InputGroup.Button>
                    )}
                </InputGroup>
            </div>
            <FormError
                id={id}
                error={error}
            />
        </div>
    );
};
