import EditUser from '@/views/Users/EditUser'
import CreateUser from '@/views/Users/CreateUser'
import { OnboardingHub } from '@/views/OnboardingHub'
import Lists from '@/views/Engage/Lists/Lists.js'
import { Users } from '@/views/Settings/Users/page'
import BrandProfiles from '@/views/BrandProfiles/BrandProfiles.js'
import { MyProfile } from '@/views/Settings/MyProfile/page'
import { AccountDetails } from '@/views/Settings/AccountDetails/page'
import UploadList from '@/views/Engage/Lists/UploadList'
import CreateList from '@/views/Engage/Lists/CreateList'
import { Login } from '@/views/Login/page'
import Opinions from '@/views/Admin/Opinions.js'
import OpinionEdit from '@/views/Admin/OpinionEdit'
import CreateOpinion from '@/views/Admin/CreateOpinion.js'
import OpinionTypes from '@/views/Admin/OpinionTypes.js'
import Measure from '@/views/Discover/Measure'
import Reporting from '@/views/Discover/Reporting'
import Permissions from '@/views/Admin/Permissions.js'
import Roles from '@/views/Admin/Roles.js'
import BrandProfile from '@/views/BrandProfiles/BrandProfile/BrandProfile'
import IabCategories from '@/views/Admin/IabCategories'
import EvergreenChannels from '@/views/Admin/EvergreenChannels'
import CreateEvergreenChannels from '@/views/Admin/CreateEvergreenChannels'
import ChannelListBuilder from '@/views/Engage/Lists/ListBuilder/ChannelListBuilder'
import VideoListBuilder from '@/views/Engage/Lists/ListBuilder/VideoListBuilder'
import ListBuilderPlaceholder from '@/views/Engage/Lists/ListBuilder/components/ListBuilderPlaceholder'
import { MomentIntelligence } from '@/views/Discover/MomentIntelligence'
import { SocialTopics } from '@/views/Discover/SocialTopics'
import { ScenariosAdmin } from '@/views/Admin/Scenarios/ScenariosAdmin'
import ReportGeneratorComponent from '@/views/ReportGenerator/ReportGeneratorView'
import ReportComponent from '@/views/ReportGenerator/ReportGeneratorForm'
import { Signup } from '@/views/Signup/page'
import { ResetPassword } from '@/views/ResetPassword/page'
import { CheckYourEmail } from '@/views/CheckYourEmail/page'
import { ChangePassword } from '@/views/ChangePassword/page'
import { StripeCheckout } from '@/views/Stripe/checkout/page'
import { StripePricing } from '@/views/Stripe/pricing/page'
import { EditOpinionType } from '@/views/Admin/EditOpinionType'
import { Integrations } from '@/views/Integrations/page'
import Trends from '@/views/Trends/Trends'
import { Hashtags } from '@/views/TikTok/Hashtags/page'
import { ActivationReportMainView } from '@/views/ActivationReports/ActivationReportMainView'
import { ActivationReportsNewReport } from '@/views/ActivationReports/ActivationReportsNewReport'
import { TrendDetails } from '@/views/Trends/TrendDetails'
import { TrendsResult } from '@/views/Trends/SearchResults'
import { TopicDetails } from '@/views/Trends/TopicDetails'
import { TargetViewSummaryReportView } from '@/views/TargetViewSummaryReport/TargetViewSummaryReportView'
import { ViewSegments } from '@/views/Engage/Segments/ViewSegments';
import { CreateSegment } from '@/views/Engage/Segments/CreateSegment';
import { UpdateSegment } from '@/views/Engage/Segments/UpdateSegment';

import { NarrativeDetails } from '@/components/Details/NarrativeDetails'

export const PATH_MOMENTS_V2_SETTINGS_REDESIGN = '/app/discover/moments/v2/settings/redesign'
export const PATH_MOMENTS_V2 = '/app/discover/moments/v2'
export const PATH_MOMENTS_V2_CREATE_CUSTOM = '/app/discover/moments/v2/boards/create/custom'
export const PATH_MOMENTS_V2_EDIT_CUSTOM = '/app/discover/moments/v2/boards/edit/custom'
export const PATH_MOMENTS_V2_AI = '/app/discover/moments/v2/boards/create/ai'
export const PATH_MOMENTS_V2_AI_EDIT = '/app/discover/moments/v2/boards/edit/ai'

export const PATH_BRAND_PROFILES = '/app/brandProfiles'
export const PATH_ENGAGE_LISTS = '/app/engage/lists'
export const PATH_SEGMENTS = '/app/segments'
export const PATH_SEGMENTS_CREATE = '/app/segments/create'
export const PATH_SEGMENTS_UPDATE = '/app/segments/update'
export const PATH_MOMENT_INTELLIGENCE = '/app/momentIntelligence'
export const PATH_SOCIAL_TOPICS_DASHBOARD = '/app/socialTopics'
export const PATH_REPORTING = '/reporting'
export const DISCOVER_REPORTING = '/app/discover/reporting'
export const PATH_ADMIN = '/admin'
export const PATH_ADMIN_ROLES = '/app/admin/roles'

export const PATH_SETTINGS_PROFILE = '/app/settings/profile'

export const PATH_INTEGRATIONS = '/app/integrations'
export const PATH_TIKTOK_HASHTAGS = '/app/tiktok/hashtags'

export const PATH_ACTIVATIONS = '/app/discover/activations/google-ads'
export const PATH_ACTIVATIONS_CAMPAIGNS = '/app/discover/activations/campaigns'
export const PATH_ACTIVATIONS_TIKTOK = '/app/discover/activations/tiktok'

const explore = '/app/explore'
export const PATH_TRENDS = `${explore}/overview`
export const PATH_TRENDS_SEARCH = `${explore}/search`
export const PATH_TREND_DETAILS = `${explore}/trend-details`
export const PATH_TOPIC_DETAILS = `${explore}/topic-details`


const details = '/app/details'
export const PATH_MOMENT_DETAILS = `${details}/moment`
export const PATH_NARRATIVE_DETAILS = `${details}/narrative`

export const PATH_TO_ONBOARDING_HUB = '/app/onboardingHub'
export const LOGIN_PATH = '/login'

export const PATH_ACTIVATION_REPORT = '/app/activation-reports'
export const PATH_ACTIVATION_REPORT_NEW_REPORT = '/app/activation-reports/new'

export const PATH_TARGETVIEW_SUMMARY_REPORT = '/app/summary-report'
export const routes = {
  login: {
    path: LOGIN_PATH,
    component: Login,
    name: 'Log In'
  },
  stripe: {
    path: '/stripe',
    checkout: {
      path: '/stripe/checkout',
      name: 'Stripe Checkout',
      component: StripeCheckout
    },
    pricing: {
      path: '/stripe/pricing',
      name: 'Stripe Pricing',
      component: StripePricing
    }
  },
  signup: {
    path: '/signup',
    component: Signup,
    name: 'Signup'
  },
  resetPassword: {
    path: '/resetPassword',
    component: ResetPassword,
    name: 'Reset Password'
  },
  checkYourEmail: {
    path: '/checkYourEmail/:email',
    component: CheckYourEmail,
    name: 'Check Your Email'
  },
  changePassword: {
    path: '/changePassword/:userId/:token',
    component: ChangePassword,
    name: 'Change Password'
  },
  reporting: {
    path: PATH_REPORTING,
    component: ReportGeneratorComponent,
    newReport: {
      path: '/reporting/new',
      name: 'Report',
      component: ReportComponent
    }
  },
  app: {
    path: '/app',
    onboardingHub: {
      path: PATH_TO_ONBOARDING_HUB,
      name: 'Onboarding Hub',
      component: OnboardingHub
    },
    activationReport: {
      path: PATH_ACTIVATION_REPORT,
      component: ActivationReportMainView,
      new: {
        path: PATH_ACTIVATION_REPORT_NEW_REPORT,
        name: 'New Activation Report',
        component: ActivationReportsNewReport
      }
    },
    targetViewSummaryReport: {
      path: PATH_TARGETVIEW_SUMMARY_REPORT,
      component: TargetViewSummaryReportView
    },
    engage: {
      lists: {
        lists: {
          path: PATH_ENGAGE_LISTS,
          component: Lists,
          name: 'SmartLists'
        },
        uploadList: {
          path: '/app/engage/lists/uploadList',
          component: UploadList,
          name: 'Upload List'
        },
        createList: {
          path: '/app/engage/lists/createList',
          component: CreateList,
          name: 'Create List'
        },
        listBuilder: {
          path: '/app/engage/lists/listBuilder/:versionId',
          name: 'List Builder Placeholder',
          component: ListBuilderPlaceholder
        },

        channelListBuilder: {
          path: '/app/engage/lists/channelListBuilder/:versionId/:viewOnly',
          name: 'List Builder',
          component: ChannelListBuilder
        },
        videoListBuilder: {
          path: '/app/engage/lists/videoListBuilder/:versionId/:viewOnly',
          name: 'List Builder',
          component: VideoListBuilder
        },  
      },
    },
    segments: {
      viewSegments: {
        path: PATH_SEGMENTS,
        name: 'Segments',
        component: ViewSegments
      },
      create: {
        path: PATH_SEGMENTS_CREATE,
        name: 'New Segment',
        component: CreateSegment
      },
      update: {
        path: PATH_SEGMENTS_UPDATE,
        name: 'Update Segment',
        component: UpdateSegment
      }
    },
    discover: {
      measure: {
        path: '/app/discover/measure',
        name: 'Measure',
        component: Measure
      },
      reporting: {
        path: '/app/discover/reporting',
        name: 'Reporting',
        component: Reporting
      }
    },
    momentIntelligence: {
      path: PATH_MOMENT_INTELLIGENCE,
      name: 'Moment Intelligence',
      component: MomentIntelligence
    },
    // socialTopics: {
    //   path: PATH_SOCIAL_TOPICS_DASHBOARD,
    //   name: 'Social Topics',
    //   component: SocialTopics
    // },
    brandProfiles: {
      path: PATH_BRAND_PROFILES,
      name: 'Brand Profiles',
      component: BrandProfiles,
      brandProfile: {
        path: '/app/brandProfiles/brandProfile/:brandProfileId',
        name: 'Brand Profile',
        component: BrandProfile
      }
    },
    tiktok: {
      hashtags: {
        path: PATH_TIKTOK_HASHTAGS,
        name: 'TikTok Hashtags',
        component: Hashtags
      }
    },
    settings: {
      profile: {
        path: PATH_SETTINGS_PROFILE,
        name: 'Profile',
        component: MyProfile
      },
      account: {
        path: '/app/settings/account',
        name: 'Account',
        component: AccountDetails
      },
      users: {
        path: '/app/settings/users',
        component: Users,
        edit: {
          path: '/app/settings/users/edit/:user',
          name: 'Edit',
          component: EditUser
        },
        create: {
          path: '/app/settings/users/create',
          name: 'Create',
          component: CreateUser
        }
      }
    },
    integrations: {
      path: PATH_INTEGRATIONS,
      name: 'Integrations',
      component: Integrations
    },
    trends: {
      path: PATH_TRENDS,
      name: 'Explore',
      component: Trends,
      trendDetails: {
        path: PATH_TREND_DETAILS,
        name: 'Trend Detail',
        component: TrendDetails
      },
      results: {
        path: PATH_TRENDS_SEARCH,
        name: 'Explore Result',
        component: TrendsResult
      },
      topicDetails: {
        path: PATH_TOPIC_DETAILS,
        name: 'Topic Detail',
        component: TopicDetails
      }
    },
    details: {
      narrativeDetails: {
        path: PATH_NARRATIVE_DETAILS,
        name: 'Narrative Detail',
        component: NarrativeDetails
      }
    }
  },
  admin: {
    path: PATH_ADMIN,
    scenariosV2: {
      path: '/app/admin/scenarios',
      name: 'Brand Profiles Scenarios',
      component: ScenariosAdmin
    },
    opinions: {
      path: '/app/admin/opinions',
      name: 'Brand Profiles Opinions',
      component: Opinions,
      create: {
        path: '/app/admin/opinions/create',
        name: 'Create',
        component: CreateOpinion
      },
      edit: {
        path: '/app/admin/opinions/edit/:opinionId',
        name: 'Edit',
        component: OpinionEdit
      },
      types: {
        path: '/app/admin/opinions/types',
        name: 'Opinion Types',
        component: OpinionTypes,
        edit: {
          path: '/app/admin/opinions/types/edit/:opinionTypeId',
          name: 'Edit Opinion Type',
          component: EditOpinionType
        }
      }
    },
    permissions: {
      path: '/app/admin/permissions',
      name: 'Permissions',
      component: Permissions
    },
    roles: {
      path: PATH_ADMIN_ROLES,
      name: 'Roles',
      component: Roles
    },
    iabCategories: {
      path: '/app/admin/iabCategories',
      name: 'IAB Categories',
      component: IabCategories
    },
    evergreenChannels: {
      path: '/app/admin/evergreenChannels',
      name: 'Evergreen Channels',
      component: EvergreenChannels,
      create: {
        path: '/app/admin/evergreenChannels/create',
        name: 'Create',
        component: CreateEvergreenChannels
      }
    }
  }
}
