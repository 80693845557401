import React from 'react';
import { ConversationPreviewCard } from '@/views/Discover/Boards/components/ConversationPreviewCard';
import { TotalPreviewsCard } from "@/views/Discover/Boards/components/TotalPreviewsCard";
import { ConversationPreviewType } from '@/views/Discover/Boards/types';
import Loader from 'rsuite/lib/Loader';
import NoFoundMessage from './NoFoundMessage';

import { SightlyEmptyState } from '@/components/Sightly/SightlyEmptyState';

interface IProps {
  previews: ConversationPreviewType[];
  isLoading?: boolean;
  previewsCount: number;
  previewsCountLongRange: number;
  previewsCountMediumRange: number;
}

export const ConversationsPreviewList: Function = ({
  previews,
  isLoading,
  previewsCount,
  previewsCountLongRange,
  previewsCountMediumRange,
}: IProps) => {
  const hasPreviews = !!previews?.length;
  const filteredPreviews = previews?.filter((preview) => !!preview);

  // if (isLoading) {
  //   return <div className="flex justify-center items-center grow">
  //     <Loader size="lg" speed="slow" />
  //   </div>
  // }

  return (
    // <div data-testid="preview-conversation-list" className={`max-h-[calc(100%-48px)] bg-slate-50 flex flex-col`}>
    // To Do: restore narrative section when data issues are resolved
    <div data-testid="preview-conversation-list" className={`h-[calc(100%-48px)] bg-slate-50 flex flex-col`}>
      <SightlyEmptyState />
      {/* <TotalPreviewsCard
        title={'Total Narratives'}
        isLoading={isLoading}
        previewsCount={previewsCount}
        previewsCountMediumRange={previewsCountMediumRange}
        previewsCountLongRange={previewsCountLongRange}
      />

      {(hasPreviews && <div data-testid="nl-preview-conversations-list" className={`overflow-scroll bg-slate-50 h-full max-h-[calc(200px - 12px)]`}>
        {filteredPreviews.map((preview) => {
          return <ConversationPreviewCard
            key={preview.name}
            preview={preview}
          />
        })}
        <p className="my-4">
          Displaying {filteredPreviews.length} narratives
        </p>
      </div>)}

      {(!hasPreviews && <div className='flex mt-20 mr-8 justify-center'>
        <NoFoundMessage
          title="No narratives found"
          subtitle="Please try updating with a different board prompt or adding ideas to lean into"
        />
      </div>)} */}
    </div>
  )
}
