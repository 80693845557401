import { api } from "@/api/api"
import { logError } from "@/utils"
import { useQuery } from "@tanstack/react-query"


export function useTopic() {
  const topicsQuery = useQuery(
    ['topicsQuery'],
    async () => {
      return await api.topics.get()
    },
    {
      retry: false,
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 10,
      onError: (err) => {
        logError(err, { info: 'Error getting topics' })
      }
    }
  )

  return {
    topicsQuery: topicsQuery.data,
    isLoadingTopics: topicsQuery.isLoading,
  }
}

export function useTopicSearch(search?: string) {
  const topicsQuery = useQuery(
    ['topicsQuery', search],
    async () => {
      return await api.topics.get(search)
    },
    {
      retry: false,
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 10,
      onError: (err) => {
        logError(err, { info: 'Error getting topics' })
      }
    }
  )

  return {
    topicsQuery: topicsQuery.data,
    isLoadingTopics: topicsQuery.isLoading,
  }
}

export function useTopicById(topicId: string) {
  const topicQuery = useQuery(
    ['topicQuery', topicId],
    async () => {
      return await api.topics.getById(topicId)
    },
    {
      retry: 1,
      onError: (err) => {
        logError(err, { info: 'Error getting topic' })
      }
    }
  )

  return {
    topicQuery: topicQuery
  }
}

export function useGetTopicPeople(topicId: string) {
  const peopleQuery = useQuery(
    ['topicPeopleQuery', topicId],
    async () => {
      return await api.topics.getPeople(topicId)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'Error getting people' })
      }
    }
  )

  return {
    peopleQuery
  }
}

export function useGetTopicBrands(topicId: string) {
  const brandsQuery = useQuery(
    ['topicBrandsQuery', topicId],
    async () => {
      return await api.topics.getBrands(topicId)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'Error getting brands' })
      }
    }
  )

  return {
    brandsQuery
  }
}

export function useGetTopicNarratives(topicId: string) {
  const narrativesQuery = useQuery(
    ['topicNarrativesQuery', topicId],
    async () => {
      return await api.topics.getNarratives(topicId)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'Error getting narratives' })
      }
    }
  )

  return {
    narrativesQuery
  }
}

export function useGetTopicMoments(topicId: string) {
  const momentsQuery = useQuery(
    ['topicMomentsQuery', topicId],
    async () => {
      return await api.topics.getMoments(topicId)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'Error getting moments' })
      }
    }
  )

  return {
    momentsQuery
  }
}

export function useGetTopicArticles(topicId: string) {
  const articlesQuery = useQuery(
    ['topicArticlesQuery', topicId],
    async () => {
      return await api.topics.getArticles(topicId)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'Error getting moments' })
      }
    }
  )

  return {
    articlesQuery
  }
}

export function useGetSankeyData(topicId: string) {
  const sankeyQuery = useQuery(
    ['sankeyQuery', topicId],
    async () => {
      return await api.topics.getSankeyData(topicId)
    },
    {
      retry: 1,
      cacheTime: 0,

      onError: (err) => {
        logError(err, { info: 'Error fetching topic data' })
      }
    }
  )

  return {
    sankeyQuery
  }
}

export function useGetTopicMomentTimeline(topicId: string) {
  const momentTimelineQuery = useQuery(
    ['topicMomentTimelineQuery', topicId],
    async () => {
      return await api.topics.getMomentTimeline(topicId)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'Error getting moment timeline' })
      }
    }
  )

  return {
    momentTimelineQuery
  }
}

export function useGetTopicSocialPosts(topicId: string) {
  const socialPostsQuery = useQuery(
    ['topicSocialPostsQuery', topicId],
    async () => {
      return await api.topics.getSocialPosts(topicId)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'Error getting social posts' })
      }
    }
  )

  return {
    socialPostsQuery
  }
}

export function useGetTopicArticleMetrics(topicId: string) {
  const articleMetricsQuery = useQuery(
    ['topicArticleMetricsQuery', topicId],
    async () => {
      return await api.topics.getArticleMetrics(topicId)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'Error getting article metrics' })
      }
    }
  )

  return {
    articleMetricsQuery
  }
}
export function useGetTopicSentimentScore(topicId: string) {
  const sentimentScoreQuery = useQuery(
    ['topicSentimentScoreQuery', topicId],
    async () => {
      return await api.topics.getSentimentScore(topicId)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'Error fetching trend articles metrics' })
      }
    }
  )

  return {
    sentimentScoreQuery
  }
}
