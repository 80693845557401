import React from 'react'
import SlideOver from '@/components/SlideOver'
import { IMomentTimeline } from '@/views/Trends/types'
import { formatDateToShortMonthDayYear, isRecentDate } from '@/utils'
import { LoadingPage } from '@/components/LoadingPage'

interface IDetailTimelineProps {
  timelineData?: IMomentTimeline
}

const DetailTimeline: React.FC<IDetailTimelineProps> = ({ timelineData }) => {
  const [showSlideOver, setShowSlideOver] = React.useState(false);

  const TimelineContent = ({ timelineData }: IDetailTimelineProps) => (
    <div className="timeline">
      {timelineData!.map((item, index) => (
        <div
          key={index}
          className="timeline-item mb-4"
        >
          <div className="flex items-center">
            <div className="inline-block rounded-full ring-2 ring-white">
              <div className="rounded-full overflow-hidden w-12 h-12 bg-white">
                <img src={item.imageUrl || ''} className="w-full h-full object-cover" alt="" data-testid="timeline-item-image" />
              </div>
            </div>

            <div className="ml-4">
              <h3 className="text-sm font-semibold text-left" data-testid="timeline-item-title">
                {item.name}
              </h3>
              <div className="flex flex-row gap-2">
                <p className="text-sm text-gray-500" data-testid="timeline-item-date">{formatDateToShortMonthDayYear(item.maxPublishedAt)}</p>
                {isRecentDate(item.maxPublishedAt) && (<span className="bg-blue-100 text-blue-600 text-xs px-2 py-1 rounded-full" data-testid="timeline-item-badge">New Moment</span>)}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )

  return (
    <div>
      <SlideOver
        show={showSlideOver}
        onHide={() => setShowSlideOver(false)}
        headerTitle="Trend Timeline"
        headerForegroundColor="text-black"
        headerBackgroundColor="bg-white"
        showToggleFullScreenButton={false}
        maxOffset="60%"
      >
        <div className="pl-10 overflow-y-auto">
          {!timelineData || timelineData?.length === 0 ? <LoadingPage message='Loading timeline' />
            : <TimelineContent timelineData={timelineData} />}
        </div>
      </SlideOver>

      <div className='flex justify-between'>
        <h5 className="text-gray">Trend Timeline</h5>
        <a
          href="#"
          className="text-blue-500 font-semibold"
          onClick={(e) => {
            e.preventDefault()
            setShowSlideOver(true)
          }}
          data-testid="view-full-timeline"
        >
          View full Timeline
        </a>
      </div>
      <div className='mt-3'>
        {!timelineData || timelineData?.length === 0 ? <LoadingPage message='Loading timeline' />
          : <TimelineContent timelineData={timelineData.slice(0, 3)} />}
      </div>
    </div>
  )
}

export default DetailTimeline
