import React from 'react'
import UnderConstruction  from '@/assets/img/UnderConstruction.svg'

export const SightlyEmptyState = () => {
    return (
      <div
        data-testid="empty-state-display"
        className="h-full w-full flex flex-col items-center justify-center text-center">
          <h1 className="text-3xl w-full mb-8">This page is under construction</h1>
          <img src={UnderConstruction} className="h-48 mb-8" />
          <p className="text-lg font-semibold w-full">
            This feature is currently under construction as we work on enhancements. Stay tuned for updates!
          </p>
      </div>
    )
}
