import { api } from "@/api/api"
import { logError } from "@/utils"
import { useQuery } from "@tanstack/react-query"

export function useTopicTrendMoments(search?: string) {

  const momentsQuery = useQuery(
    ['momentsQuery', search],
    async () => {
      return await api.moments.get(search)
    },
    {
      retry: false,
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 10, 
      onError: (err) => {
        logError(err, { info: 'Error fetching trends data' });
      }
    }
  )

  return {
    momentsQuery: momentsQuery.data,
    isLoadingMoments: momentsQuery.isLoading,
  }
}

export function useTopicTrendMomentsById(momentId: number) {
  const momentsQuery = useQuery(
    ['momentsQuery', momentId],
    async () => {
      return await api.moments.getById(momentId)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'Error getting topic' })
      }
    }
  )

  return {
    momentsQuery
  }
}