import { MessageTailwind } from '@/components/MessageTailwind';
import { Link } from '@tanstack/react-location';
import { PATH_ACTIVATIONS, routes } from '@/routes'
import React from 'react';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';
import useUser from '@/hooks/useUser';
import { perms } from '@/staticData/permissions';
import { products } from '@/staticData/products';

interface ActionMessagesProps {
  downloadingTrends: boolean
  noIOsLinkedToBrandProfile: boolean
  setNoIOsLinkedToBrandProfile: Function
  brandProfileId: number | undefined
  showTargetSuccess: boolean
  setShowTargetSuccess: Function
  isTargeting: boolean
  targetError: string
  setTargetError: Function
  showBlockKeywordsSuccess: boolean
  setShowBlockKeywordsSuccess: Function
  showBlockPlacementsSuccess: boolean
  setShowBlockPlacementsSuccess: Function
  blockingKeywords: boolean
  blockingPlacements: boolean
  blockPlacementsError: string
  setBlockPlacementsError: Function
  blockKeywordsError: string
  setBlockKeywordsError: Function
}

export default function ActionMessages(props:ActionMessagesProps) {
  const {
    downloadingTrends,
    noIOsLinkedToBrandProfile,
    setNoIOsLinkedToBrandProfile,
    brandProfileId,
    showTargetSuccess,
    setShowTargetSuccess,
    isTargeting,
    targetError,
    setTargetError,
    showBlockKeywordsSuccess,
    setShowBlockKeywordsSuccess,
    showBlockPlacementsSuccess,
    setShowBlockPlacementsSuccess,
    blockingKeywords,
    blockingPlacements,
    blockPlacementsError,
    setBlockPlacementsError,
    blockKeywordsError,
    setBlockKeywordsError
  } = props;

  const { userPermissions, userProducts } = useUser();

  return <>
    <div className="flex flex-col w-full gap-2 px-0">
      <MessageTailwind
        show={downloadingTrends}
        message="Downloading moments"
        type="loading"
      />
      <MessageTailwind
        show={

                noIOsLinkedToBrandProfile &&
                userCanPermissionProductQuota({
                  requiredPermissionValue: perms.TRENDS_TARGET,
                  requiredProductValue: products.BOARDS,
                  userPermissions: userPermissions,
                  userProducts: userProducts,
                  checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK
                })

        }
        message={
          <>
            There are no insertion orders linked to this Brand Profile for targeting and
            blocking.&nbsp;
            <Link
                to={`${routes.app.brandProfiles.path}/brandProfile/${brandProfileId}/targetview`}
            >
                Update now
            </Link>
          </>
        }
        type="warning"
        handleClose={() => setNoIOsLinkedToBrandProfile(false)}
      />
      <MessageTailwind
        show={showTargetSuccess}
        handleClose={() => setShowTargetSuccess(false)}
        message={
          <div className="flex gap-4">
            <div>Keywords/Videos added to queue for targeting</div>
            <Link to={PATH_ACTIVATIONS}>View List</Link>
          </div>
        }
        type="success"
      />
      <MessageTailwind
        show={isTargeting}
        message="Adding Keywords/Videos to queue for targeting"
        type="loading"
      />
      <MessageTailwind
        show={targetError.length > 0}
        handleClose={() => setTargetError('')}
        message={targetError}
        type="error"
      />
      <MessageTailwind
        show={showBlockKeywordsSuccess}
        handleClose={() => setShowBlockKeywordsSuccess(false)}
        message="Keywords added to queue for blocking"
        type="info"
      />
      <MessageTailwind
        show={showBlockPlacementsSuccess}
        handleClose={() => setShowBlockPlacementsSuccess(false)}
        message="Videos added to queue for blocking"
        type="info"
      />
      <MessageTailwind
        show={blockingKeywords}
        message="Adding keywords to queue for blocking"
        type="loading"
      />
      <MessageTailwind
        show={blockingPlacements}
        message="Adding videos to queue for blocking"
        type="loading"
      />
      <MessageTailwind
        show={blockPlacementsError.length > 0}
        handleClose={() => setBlockPlacementsError('')}
        message={blockPlacementsError}
        type="error"
      />
      <MessageTailwind
        show={blockKeywordsError.length > 0}
        handleClose={() => setBlockKeywordsError('')}
        message={blockKeywordsError}
        type="error"
      />
  </div>
  </>;
}
