import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ArrowDownTrayIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { ClusterType, MomentScenarioType } from '@/views/Discover/Moments/v2/types';
import LikeDislikeButton from '@/components/LikeDislikeButton';
import { useIsMutating, useMutation, useQueryClient } from '@tanstack/react-query';
import { rqKeys } from '@/views/Discover/Moments/v2/rqKeys';
import { api, RecActionFeedbackProps } from '@/views/Discover/Moments/v2/api';
import toast from 'react-hot-toast';
import { logError } from '@/utils';
import {BrandProfile } from '@/classes/brandProfile';
import { ActionBox } from '@/views/Discover/Moments/v2/components/ActionBox';
import MomentArticles from '@/views/Discover/Moments/v2/MomentModal/MomentArticles';
import MomentVideos from '@/views/Discover/Moments/v2/MomentModal/MomentVideos';
import { perms } from '@/staticData/permissions';
import MomentKeywords from '@/views/Discover/Moments/v2/MomentModal/MomentKeywords';
import { SideNavigation } from '@/views/Discover/Moments/v2/components/MomentSlideOver/SideNavigation'
import { ScenariosSection }  from '@/views/Discover/Moments/v2/components/MomentSlideOver/ScenariosSection'
import { QueriesSection }  from '@/views/Discover/Moments/v2/components/MomentSlideOver/QueriesSection'
import { CategoriesSection }  from '@/views/Discover/Moments/v2/components/MomentSlideOver/CategoriesSection'
import { UnsafeContentSection }  from '@/views/Discover/Moments/v2/components/MomentSlideOver/UnsafeContentSection';
import {
  useActionJustifications,
  useArticles,
  useKeywords,
  useScenarioOptions, useScenarios,
  useVideos,
} from '@/views/Discover/Moments/v2/components/MomentSlideOver/useMoment';
import { BrandProfileLink } from '@/views/Discover/Moments/v2/components/BrandProfileLink';
import { useFlag } from '@unleash/proxy-client-react';
import config from '@/config';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import Divider from 'rsuite/lib/Divider';
import useUser from '@/hooks/useUser';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';
import SightlyGradientCard from '@/components/Sightly/SightlyGradientCard';

interface IProps {
  show: boolean
  close: (val: boolean) => void
  moment: ClusterType
  brandProfileId: BrandProfile['brandProfileId'] | undefined
  postTrendRecActionFeedback: (args: RecActionFeedbackProps) => void
  postTrendKeywordsFeedback: (args: RecActionFeedbackProps) => void
  invalidateMomentsQuery: any
}

export const MomentSlideOver: React.FC<IProps> = ({ show, close, moment, brandProfileId, postTrendRecActionFeedback, postTrendKeywordsFeedback, invalidateMomentsQuery }: IProps) => {
  const limit = 5;
  const enableMomentSummary = useFlag(`enable_moment_summary_${config.environment}`);
  const defaultActiveTab = 'Overview';

  const queryClient = useQueryClient()
  const { userPermissions } = useUser()
  const isMutating = useIsMutating()

  const [adding, setAdding] = React.useState<boolean>(false)
  const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false)
  const [activeTab, setActiveTab] = React.useState<string>(defaultActiveTab)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [videos, setVideos] = useState<any[]>([])

  const [currentArtPage, setCurrentArtPage] = useState<number>(1)
  const [articles, setArticles] = useState<any[]>([])

  const articlesQuery = useArticles({brandProfileId, moment, page: currentArtPage, limit })
  const keywordsQuery = useKeywords({brandProfileId, moment})
  const scenariosQuery = useScenarios({brandProfileId, moment, setHasUnsavedChanges})
  const scenariosOptionsQuery = useScenarioOptions({brandProfileId, moment})
  const actionJustifications = useActionJustifications({brandProfileId, moment})
  const videosQuery = useVideos({ brandProfileId, moment, page: currentPage, limit })

  useEffect(()=>{
    keywordsQuery.refetch()
  },[moment.action])
  // Adding the current moment as QueryData would fix https://sightly.atlassian.net/browse/EN-14723
  // The modal shouldn't close even if the recommended action is not in the action filter in the boards page
  useEffect(() => {
      moment && queryClient.setQueryData(rqKeys.momentInViewSlideOver(brandProfileId, moment.clusterId),(prev:any)=> moment)
      setVideos([]);
      setCurrentPage(1);
      setArticles([]);
      setCurrentArtPage(1);
  }, [moment]);

    useEffect(() => {
      if (videosQuery.data) {
          setVideos(prevVideos => {
              const newVideos = videosQuery.data.filter(video =>
                  !prevVideos.some(prevVideo => prevVideo.videoId === video.videoId)
              );
              if (newVideos.length === 0) return prevVideos;
              return [...prevVideos, ...newVideos];
          });
      }
    }, [videosQuery.data]);

    useEffect(() => {
      setVideos(prevVideos => {
        const newVideos = videosQuery.data.filter(video =>
            !prevVideos.some(prevVideo => prevVideo.videoId === video.videoId)
        );
        if (newVideos.length === 0) return prevVideos;
        return [...prevVideos, ...newVideos];
    });
    }, [show]);

  const handleLoadMore = () => {
      if ((currentPage * videosQuery.count) < (videosQuery.total ?? 0)) {
          setCurrentPage(prevPage => prevPage + 1);
      }
  };

  useEffect(() => {
    if (articlesQuery.data) {
      setArticles(prevArticles => {
            const newArticles = articlesQuery.data;
            if (newArticles.length === 0) return prevArticles;
            return [...prevArticles, ...newArticles];
        });
    }
  }, [articlesQuery.data]);

  useEffect(() => {
    setArticles(prevArticles => {
      const newArticles = articlesQuery.data.filter(article =>
          !prevArticles.some(prevArticle => prevArticle.url === article.url)
      );
      if (newArticles.length === 0) return prevArticles;
      return [...prevArticles, ...newArticles];
  });
  }, [show]);

  const handleLoadMoreArticles = () => {
      if ((currentArtPage * articlesQuery.count) < (articlesQuery.total ?? 0)) {
        setCurrentArtPage(prevPage => prevPage + 1);
      }
  };

  const handleAddMomentScenario = async (scenarioId: number) => {
    setHasUnsavedChanges(true)
    setAdding(false)
    if (!brandProfileId || !moment.clusterId) {
      return Promise.reject('invalid input')
    }
    const existingScenariosOptions: MomentScenarioType[] | undefined = queryClient.getQueryData(
      rqKeys.momentScenariosOptionsKey(brandProfileId, moment.clusterId)
    )

    if (!existingScenariosOptions) {
      return Promise.reject('invalid input')
    }

    const existingScenarios: MomentScenarioType[] | undefined = queryClient.getQueryData(
      rqKeys.momentScenariosKey(brandProfileId, moment.clusterId)
    )

    const addedScenario: MomentScenarioType | undefined = existingScenariosOptions.filter(
      (s) => s.scenarioId === scenarioId
    )[0]
    if (!addedScenario) {
      return Promise.reject('invalid input')
    }

    queryClient.setQueryData(
      rqKeys.momentScenariosKey(brandProfileId, moment.clusterId),
      existingScenarios?.concat(addedScenario)
    )
  }

  const handlePatchMomentScenarios = useMutation(
    async () => {
      setHasUnsavedChanges(true)
      if (!brandProfileId || !moment.clusterId) {
        return Promise.reject('invalid input')
      }

      const existingScenarios: MomentScenarioType[] | undefined = queryClient.getQueryData(
        rqKeys.momentScenariosKey(brandProfileId, moment.clusterId)
      )

      return await api.moments.scenarios.patch({
        brandProfileId,
        clusterId: moment.clusterId,
        scenarios: existingScenarios
      })
    },
    {
      onMutate: () => {
        setHasUnsavedChanges(false)
      },
      onSettled: () => {
        if (!moment.clusterId) return
        queryClient.invalidateQueries(rqKeys.momentScenariosKey(brandProfileId, moment.clusterId))
        invalidateMomentsQuery()
      },
      onSuccess: (response) => {
        toast.success('Changes saved')
        queryClient.setQueryData(rqKeys.momentInViewSlideOver(brandProfileId, moment.clusterId), (prevMoment:any)=>{
          const resp:any = response.data.data
          let action = prevMoment.action
          if('recommendedAction' in resp) {
            action = resp.recommendedAction
          }
          return {...prevMoment, action}
        })
      },
      onError: (err) => {
        logError(err, { info: 'error patching moment scenario' })
        toast.error('There was an error patching moment scenarios.')
      }
    }
  )

  const handleRemoveMomentScenario = (scenarioId: number) => {
    setHasUnsavedChanges(true)
    if (!brandProfileId || !moment.clusterId) {
      return Promise.reject('invalid input')
    }

    const existingScenarios: MomentScenarioType[] | undefined = queryClient.getQueryData(
      rqKeys.momentScenariosKey(brandProfileId, moment.clusterId)
    )

    queryClient.setQueryData(
      rqKeys.momentScenariosKey(brandProfileId, moment.clusterId),
      existingScenarios?.filter((s) => s.scenarioId !== scenarioId)
    )
  }

  const handleClose = () => {
    close(false);
    setAdding(false);
    setActiveTab(defaultActiveTab);
    setVideos([]);
    setCurrentPage(1);
    setArticles([]);
    setCurrentArtPage(1);
  }

  const handleDownload = async () => {
    if (brandProfileId === undefined) return
    const args = {
        brandProfileId,
        clusterIds: [moment.clusterId]
    }
    try {
        await api.moments.download(args)
    } catch (err) {
        toast.error('There was an error downloading your moments.')
    }
  }

  const showUnsafeContentSection = actionJustifications.data?.categoryActionJustificationWithGarm
    && (actionJustifications.data?.categoryActionJustificationWithGarm.length > 0);

    return (
      <Transition.Root
        show={show}
        as={Fragment}
      >
         <Dialog
            as="div"
            className="relative z-10"
            onClose={handleClose}
        >
        <div className="fixed inset-0 overflow-hidden c">
          <div className="absolute inset-0 overflow-hidden">
            <div className="fixed inset-y-0 right-0 flex max-w-full pointer-events-none">
              <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
              >
                <Dialog.Panel className="w-screen max-w-6xl border pointer-events-auto">
                  <div className="flex flex-col h-full divide-y divide-gray-200">

                    {/*Header*/}
                    <div className="px-4 pt-6 pb-6 bg-white sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="pt-6 pl-6 text-lg font-large">
                        <div
                              data-testid="moment-headline"
                              className="flex items-start"
                            >
                           <div
                              data-testid="moment-headline"
                              className="flex items-start"
                            >
                              <span className="mr-2">{moment.clusterName}</span>
                               <button
                                 className="pl-7 focus:outline-none "
                                 data-testid="moment-download-button"
                                 onClick={handleDownload}
                               >
                                 <ArrowDownTrayIcon
                                   className="w-6 h-6 text-indigo-600"
                                   aria-hidden="true"
                                 />
                               </button>
                            </div>
                            </div>

                            <div className="flex h-18">
                              <ActionBox text={moment.action} />
                              <div className="self-center pl-4">
                                <LikeDislikeButton
                                  textColor={null}
                                  handleDownClick={undefined}
                                  text={'What do you think about the recommended action?'}
                                  buttonState={moment.recommendedActionFeedback}
                                  handleClick={(val: string) => {
                                    postTrendRecActionFeedback({
                                      clusterId: moment.clusterId,
                                      feedback: val,
                                      brandProfileId: Number(brandProfileId)
                                    })
                                  }}
                                  disabled={isMutating > 0}
                                />
                              </div>
                            </div>
                          </Dialog.Title>
                          <div className="flex items-center">
                            <button
                              data-testid="moment-close-button"
                              type="button"
                              className="text-black rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                              onClick={handleClose}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="w-6 h-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>

                        </div>
                      </div>

                      {/*Body*/}
                      <div className="grid grid-cols-6 bg-gray-100">
                        <SideNavigation
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                        />
                        <div className="col-span-5 m-4">
                        {activeTab === 'Overview' && (
                            <div className="p-6 mb-6 overflow-auto bg-white rounded-xl">
                              <div className="max-h-[calc(65vh)] overflow-auto">
                                {enableMomentSummary && moment.clusterSummary && (
                                  <div className='mb-6'>
                                    <SightlyGradientCard>
                                      <p data-testid="moment-slideover-article-cluster-summary" className="text-base">
                                        {moment.clusterSummary}
                                      </p>
                                    </SightlyGradientCard>
                                  </div>
                                )}
                              {moment.articleCountPerDay && (
                                <>
                                  <h4 className="mb-2 ml-8">Article Count</h4>
                                  <div data-testid="moment-slideover-article-count-chart-div">
                                    <LineChart
                                      width={700}
                                      height={300}
                                      data={moment.articleCountPerDay}
                                      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                                    >
                                      <CartesianGrid strokeDasharray="3 3" />
                                      <XAxis dataKey="day" tickCount={5} />
                                      <YAxis dataKey="story_count" tickCount={5} />
                                      <Tooltip
                                        formatter={(value, name, props) => [value, 'Article count']}
                                      />
                                      <Line type="monotone" dataKey="story_count" stroke="#7748f9" />
                                      </LineChart>
                                    </div>
                                </>
                              )}

                              </div>
                            </div>
                          )}

                          {activeTab === 'Brand Mentality' && (
                            <div className="h-[calc(75vh)] overflow-auto rounded-xl">
                              {showUnsafeContentSection && (
                                <UnsafeContentSection
                                  brandProfileId={brandProfileId}
                                  isLoading={actionJustifications.isLoading}
                                  categories={actionJustifications.data?.categoryActionJustificationWithGarm}
                                />
                              )}

                              <div className="grid grid-cols-12 gap-4 pb-4">
                                <div className="col-span-5">
                                  <QueriesSection
                                    brandProfileId={brandProfileId}
                                    isLoading={actionJustifications.isLoading}
                                    queries={actionJustifications.data?.queryActionJustifications}
                                  />
                                </div>
                                <div className="col-span-7">
                                  <CategoriesSection
                                    brandProfileId={brandProfileId}
                                    isLoading={actionJustifications.isLoading}
                                    categories={actionJustifications.data?.categoryActionJustificationWithoutGarm}
                                  />
                                </div>
                              </div>
                              <div className="p-4 bg-white rounded-xl">
                                <div className="flex justify-between">
                                  <h4 className="mb-2">Scenario Questions</h4>
                                  <BrandProfileLink dataTestId="brand-profile-scenarios-link" brandProfileId={brandProfileId} section="scenarios"/>
                                </div>
                                {/*<h4>Scenario Questions</h4>*/}
                                <ScenariosSection
                                  isLoading={scenariosQuery.isLoading}
                                  scenariosOptions={scenariosOptionsQuery.data || []}
                                  adding={adding}
                                  setAdding={setAdding}
                                  scenarios={scenariosQuery.data || []}
                                  moment={moment}
                                  handleAddMomentScenario={handleAddMomentScenario}
                                  handleRemoveMomentScenario={handleRemoveMomentScenario}
                                  handlePatchMomentScenarios={handlePatchMomentScenarios}
                                  hasUnsavedChanges={hasUnsavedChanges}
                                />
                              </div>
                            </div>
                          )}

                        {activeTab === 'Articles' && (
                          <div className="p-6 bg-white max-h-5/6 rounded-xl">
                          <h4>Articles</h4>
                          <div className="max-h-[calc(65vh)] overflow-auto">
                             <MomentArticles
                                moment={moment}
                                articles={articles || []}
                                isLoading={articlesQuery.isLoading}
                                page={currentArtPage}
                                totalItems={articlesQuery.total || 0}
                                pageSize={limit}
                                handlePageChange={handleLoadMoreArticles}
                              />
                          </div>
                          </div>
                        )}

                          {activeTab === 'Videos' && (
                            <div className="p-6 bg-white max-h-5/6 rounded-xl">
                              <h4>Videos</h4>
                              <div className="max-h-[calc(65vh)] overflow-auto">
                                 <MomentVideos
                                    moment={moment}
                                    videos={videos || []}
                                    isLoading={videosQuery.isLoading}
                                    page={currentPage}
                                    totalItems={videosQuery.total || 0}
                                    pageSize={limit}
                                    handlePageChange={handleLoadMore}
                                  />
                              </div>
                            </div>
                          )}

                          {activeTab === 'Keywords' && userCanPermissionProductQuota({
                            requiredPermissionValue: perms.AYLIEN_MOMENT_KEYWORDS_READ,
                            userPermissions: userPermissions,
                            checkType: PermissionCheckType.PERMISSION_CHECK
                          }) && (
                            <div className="p-6 overflow-auto bg-white max-h-5/6 rounded-xl">
                              <h4>Keywords</h4>
                              <div className="max-h-[calc(65vh)] overflow-auto">
                                <MomentKeywords
                                  moment={moment}
                                  postTrendKeywordsFeedback={postTrendKeywordsFeedback}
                                  keywords={keywordsQuery.data}
                                  isLoading= {keywordsQuery.isLoading}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
}
