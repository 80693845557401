import NoImageFoundPath from '@/assets/img/NoImageFound.svg';
import SearchBox from "@/components/SearchBox";
import SightlyButton from "@/components/Sightly/SightlyButton";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "@tanstack/react-location";
import { Divider, Loader, Tooltip, Whisper } from "rsuite";
import { trendTopicController } from "../controllers/TrendTopicController";
import { useGetTrendsByTopicId } from "../hooks/useTopics";
import { IMoment, INarrative } from '@/views/Trends/types'
import { PATH_TREND_DETAILS } from '@/routes';

interface MomentViewSlideOverProps {
  topicId?: string;
  dataType: "Moments" | "Narratives";
}

const TopicMomentViewSlideOver: React.FC<MomentViewSlideOverProps> = ({
  topicId,
  dataType,
}) => {
  const { topicsQuery } = useGetTrendsByTopicId(topicId!);
  const [expandedTrends, setExpandedTrends] = useState<string[]>([]);
  const [dataPages, setDataPages] = useState<Record<string, number>>({});
  const [dataByTrend, setDataByTrend] = useState<Record<string, { data: any[]; totalPages: number }>>({});
  const [loading, setLoadingState] = useState<boolean>(false);
  const [search, setSearch] = React.useState<string>();

  const navigate = useNavigate();

  const handlePageChange = (trendId: string, newPage: number) => {
    setDataPages((prev) => ({ ...prev, [trendId]: newPage }));
    if (dataType === "Moments") {
      trendTopicController.getMomentsMethod(trendId, search, newPage, setDataByTrend, setLoadingState);
    } else {
      trendTopicController.getNarrativesMethod(trendId, search, newPage, setDataByTrend, setLoadingState);
    }
  };

  useEffect(() => {
  if (topicsQuery.data) {
    setDataByTrend({});

    topicsQuery.data.forEach(async (trend) => {
      if (dataType === "Moments") {
        trendTopicController.getMomentsMethod(trend.id, search, 1, setDataByTrend, setLoadingState);
      } else {
        trendTopicController.getNarrativesMethod(trend.id, search, 1, setDataByTrend, setLoadingState);
      }
      setDataPages((prev) => ({ ...prev, [trend.id!]: 1 }));
    });
  }
}, [topicsQuery.data, search]);


  const handleTrendNavigate = (trendId: string) => {
    navigate({to: `${PATH_TREND_DETAILS}/${trendId}`})
  } 

  const tooltip = (name: string) => (
   <Tooltip
      placement="top"
      className="max-w-[250px] p-2 rounded-md">
        {name}
    </Tooltip>
  );

  return (
    <div className="mt-[-2vh] pr-5">
      <Divider className="mb-[2vh]" />
      <div className="flex flex-col gap-4">
        <SearchBox
          dataTestId="trend-moment-search-box"
          handleSearch={setSearch}
          placeholder="Search by name"
          className="w-[40%]"
        />

        <div className="my-3 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg min-w-full max-h-[70vh] overflow-scroll">
          { dataType === "Moments" ? (
            <table className="table-fixed bg-gray-50 w-full divide-y divide-gray-300">
              <thead className="text-[0.8rem] w-full">
                <tr className="text-gray-400 uppercase w-full">
                  <th className="py-3.5 w-2/12"></th>
                  <th className="text-left py-3.5 w-4/12">Trend {'>'} Moments</th>
                  <th className="py-3.5 w-2/12">Articles</th>
                  <th className="py-3.5 w-2/12">Keywords</th>
                  <th className="py-3.5 w-2/12">Videos</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {topicsQuery.data && !loading ? (
                  topicsQuery.data.map((trend) => {
                    const retrievedData = dataByTrend[trend.id] || { data: [], totalPages: 0 };
                    return (
                  <React.Fragment key={trend.id}>
                    <tr
                      className="fade-in animate-in cursor-pointer table-row"
                      onClick={() =>
                        setExpandedTrends((prev) =>
                          prev.includes(trend.id)
                            ? prev.filter((id) => id !== trend.id)
                            : [...prev, trend.id]
                        )
                      }
                    >
                      <td className="py-3.5 flex justify-center">
                        <ChevronDownIcon
                          className={`text-gray-400 w-5 transform transition-transform ${expandedTrends.includes(trend.id) ? "rotate-180" : ""}`}
                        />
                      </td>
                      <td className="truncate pr-[0.5vw]">
                      <Whisper placement="top" controlId="control-id-hover-trend" trigger="hover" speaker={tooltip(trend.name)}>
                      <span onClick={() => handleTrendNavigate(trend.id)} className="cursor-pointer ">
                          {trend.name}
                        </span>
                      </Whisper>
                      </td>
                    </tr>

                    {expandedTrends.includes(trend.id) && (
                      <tr>
                        <td colSpan={5}>
                          <div className="max-h-[30vh] overflow-y-auto w-full">
                            <table className="table-fixed w-full">
                              <tbody className="divide-y divide-gray-200 w-full">
                                {retrievedData?.data.map((item: IMoment) => (
                                  <tr key={item.id} className="w-full">
                                    <td className="py-3.5 flex justify-center">
                                      <img
                                        src={item.imageUrl || NoImageFoundPath}
                                        alt={item.name}
                                        className="w-[4vw] aspect-square rounded-full object-cover"
                                        onError={(e) => {
                                          const target = e.target as HTMLImageElement;
                                          target.onerror = null;
                                          target.src = NoImageFoundPath;
                                        }}
                                      />

                                    </td>
                                    <td className="text-left pl-0.5 py-3.5 w-4/12">
                                    <Whisper placement="top" controlId="control-id-focus" trigger="hover" speaker={tooltip(item.name)}>
                                      <div className="line-clamp-2 block" data-tip={item.name}>
                                        {item.name}
                                      </div>
                                    </Whisper>
                                    </td>
                                    <td className="text-center py-3.5 w-2/12">
                                      {item.articleCount || 0}
                                    </td>
                                    <td className="text-center py-3.5 w-2/12">
                                      {item.keywordCount || 0}
                                    </td>
                                    <td className="text-center py-3.5 w-2/12">
                                      {item.videoCount || 0}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div className="flex justify-center p-4">
                              { dataByTrend[trend.id]?.data.length > 0 ? (
                                <SightlyButton
                                  datatestid="load-more-moments-or-narratives-button"
                                  block
                                  id={`loadmorebutton-${trend.id}`}
                                  text="Load More"
                                  handleClick={() =>
                                    handlePageChange(trend.id, dataPages[trend.id] + 1)
                                  }
                                  loading={loading}
                                  disabled={loading || dataPages[trend.id] >= retrievedData.totalPages}
                                />
                              ) : (
                                <div className="text-gray-400">No data found</div>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={5}>
                      <div className="h-full flex items-center justify-center">
                        <Loader size="md" content="Loading..." center />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          ) : (
            <table className="table-fixed bg-gray-50 w-full divide-y divide-gray-300">
              <thead className="text-[0.5rem] w-full">
                <tr className="text-gray-400 uppercase w-full">
                  <th className="py-3.5 w-1/12"></th>
                  <th className="text-left py-3.5 w-5/12">Narrative name</th>
                  <th className="py-3.5 w-2/12">Posts</th>
                  <th className="py-3.5 w-1/12">Platforms</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {topicsQuery.data ? (
                  topicsQuery.data.map((trend) => {
                    const retrievedData = dataByTrend[trend.id] || { data: [], totalPages: 0 };
                    return (
                  <React.Fragment key={trend.id}>
                    <tr
                      className="fade-in animate-in cursor-pointer table-row"
                      onClick={() =>
                        setExpandedTrends((prev) =>
                          prev.includes(trend.id)
                            ? prev.filter((id) => id !== trend.id)
                            : [...prev, trend.id]
                        )
                      }
                    >
                      <td className="py-3.5 flex justify-center">
                        <ChevronDownIcon
                          className={`text-gray-400 w-5 transform transition-transform ${expandedTrends.includes(trend.id) ? "rotate-180" : ""}`}
                        />
                      </td>
                      <td className="truncate pr-[0.5vw]">
                      <Whisper placement="top" controlId="control-id-hover-trend" trigger="hover" speaker={tooltip(trend.name)}>
                      <span onClick={() => handleTrendNavigate(trend.id)} className="cursor-pointer ">
                          {trend.name}
                        </span>
                      </Whisper>
                      </td>
                    </tr>
                      
                    {expandedTrends.includes(trend.id) && (
                      <tr>
                        <td colSpan={4}>
                          <div className="overflow-y-auto w-full">
                            <table className="table-fixed w-full">
                              <tbody className="divide-y divide-gray-200 w-full max-h-[30vh] ">
                                {retrievedData?.data.map((item: INarrative) => (
                                  <tr key={item.id} className="w-full">
                                    <td className="text-left pl-[5%] py-3.5 w-4/12 truncate">
                                      {item.name}
                                    </td>
                                    <td className="text-left pl-0.5 py-3.5 w-4/12 truncate"> 
                                    <Whisper placement="top" controlId="control-id-focus" trigger="hover" speaker={tooltip(item.name)}>
                                      <div className="line-clamp-2 block" data-tip={item.name}>
                                        {item.name}
                                      </div>
                                    </Whisper></td>
                                    <td className="text-center py-3.5 w-2/12">
                                      {item.postCount || 0}
                                    </td>
                                    <td className="text-center py-3.5 w-2/12">
                                      {item.sources.length || 0}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div className="flex justify-center p-4">
                              <SightlyButton
                                datatestid="load-more-moments-or-narratives-button"
                                block
                                id={`loadmorebutton-${trend.id}`}
                                text="Load More"
                                handleClick={() =>
                                  handlePageChange(trend.id, dataPages[trend.id] + 1)
                                }
                                loading={loading}
                                disabled={loading || dataPages[trend.id] >= retrievedData.totalPages}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={4}>
                      <div className="h-full flex items-center justify-center">
                        <Loader size="md" content="Loading..." center />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopicMomentViewSlideOver;
