import { MyLocationGenerics } from '@/classes/utils';
import { InfoPage } from '@/components/InfoPage';
import { LoadingPage } from '@/components/LoadingPage';
import SightlyButton from '@/components/Sightly/SightlyButton';
import { formatDateyyyyMMdd } from '@/utils';
import SocialNarrativeSlideOver from '@/views/Discover/Boards/NarrativesTab/SocialNarrativePanel/SocialNarrativeSlideOver';
import { MomentSlideOver } from '@/views/Discover/Moments/v2/components/MomentSlideOver/MomentSlideOver';
import { ClusterType } from '@/views/Discover/Moments/v2/types';
import { useMoments } from '@/views/Discover/Moments/v2/useMoments';
import { getLast24Hrs } from '@/views/ReportGenerator/components/formSections/boardsDateRange-types';
import ResultCard from '@/views/Trends/SearchResults/components/ResultCard';
import { IMoment, INarrative, ISearchResult, ITopic, ITrend } from '@/views/Trends/types';
import { useNavigate, useSearch } from '@tanstack/react-location';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import ResultMomentCard from './ResultMomentCard';
import ResultSocialNarrativeCard from './ResultNarrativeCard';
import { routes } from '@/routes';

interface IProps {
  results: ISearchResult[];
  type: 'topic' | 'trend' | 'moment' | 'narrative';
  isLoading: boolean;
  page: number;
  pageSize: number;
  totalItems: number;
  handlePageChange: (newPage: number) => void;
  brandProfileId?: number
}

const ResultCardList = ({ results, type, handlePageChange, isLoading, page, pageSize, totalItems, brandProfileId }: IProps) => {

  const navigate = useNavigate()

  const [panelResult, setPanelResult] = useState<ISearchResult | null>(null);
  const [openPanel, setOpenPanel] = useState(false);

  const startingRange = getLast24Hrs();
  const startingDateString = formatDateyyyyMMdd(startingRange[0]);
  const endingDateString = formatDateyyyyMMdd(startingRange[1]);

  const [actions, setActions] = React.useState(['Target', 'Monitor', 'Block', 'No Action']);
  const [activationStatus, setActivationStatus] = React.useState<string[]>([]);

  const {
    boardIds,
    startDate = startingDateString,
    endDate = endingDateString,
    sortBy = 'ranking',
    searchTerm = ''
  } = useSearch<MyLocationGenerics>();

  const {
    postTrendRecActionFeedback,
    postTrendKeywordsFeedback,
    invalidateMomentsQuery,
  } = useMoments({
    showTargetSuccess: false,
    brandProfileId: brandProfileId,
    boardIds: boardIds || [],
    startDate,
    endDate,
    searchTerm: searchTerm.toString(),
    sortBy,
    actions,
    activationStatus
  });

  const handleResultSelect = (result: ISearchResult) => {
    if (brandProfileId) {
      setPanelResult(result);
      setOpenPanel(true);
    } else {
      toast.error('Please select a brand profile first')
    }
  };

  const handleResultTopicTrendSelect = (result: ISearchResult) => {
    navigate({ to: type === 'topic' ? `${routes.app.trends.topicDetails.path}/${result.id}` : `${routes.app.trends.trendDetails.path}/${result.id}` })
  };

  const parseToClusterType = (moment: IMoment) => {
    const cluster: ClusterType = {
      clusterId: Number(moment.id),
      clusterName: moment.name,
      originalRecommendedAction: 'no action',
      action: 'no action',
      activationStatuses: null,
      editedFromAction: null,
      editedBy: null,
      editedDateTime: null,
      blockKeywordsCount: -1,
      targetKeywordsCount: -1,
      articleCount: moment.articleCount,
      videoCount: moment.videoCount,
      imageUrl: moment.imageUrl || undefined,
      feedback: null,
      keywordsFeedback: null,
      recommendedActionFeedback: null,
      targetList: undefined,
      garmFlagged: false,
      minPublishedAt: dayjs(moment.minPublishedAt.toString()).format('MMMM D, YYYY'),
      maxPublishedAt: dayjs(moment.maxPublishedAt.toString()).format('MMMM D, YYYY'),
      clusterUpdatedAt: dayjs(moment.maxPublishedAt.toString()).format('MMMM D, YYYY'),
      articleCountPerDay: moment.articleCountPerDay?.map((a) => ({ day: a.date, story_count: a.count })),
      clusterSummary: '',
      brandRelevanceScore: null,
      relevanceClassification: null,
    };
    return cluster
  };

  const renderResults = () => {
    switch (type) {
      case 'topic':
      case 'trend':
        const castedResults = type === 'topic' ? results as ITopic[] : results as ITrend[]
        return castedResults.map((result) => (
          <ResultCard
            key={`search-${type}-result-${result.id}`}
            data-testid={`search-${type}-result-${result.id}`}
            result={result}
            handleSelect={handleResultTopicTrendSelect}
          />
        ));
      case 'moment':
        return results.map((result) => {
          return <ResultMomentCard
            key={`search-moment-result-${result.id}`}
            data-testid={`search-moment-result-${result.id}`}
            moment={result}
            handleSelect={handleResultSelect}
          />;
        });

      case 'narrative':
        return (results as INarrative[]).map((result) => (
          <ResultSocialNarrativeCard
            key={`search-narrative-result-${result.id}`}
            data-testid={`search-narrative-result-${result.id}`}
            narrative={result}
            toggleSocialNarrativePanel={handleResultSelect}
          />
        ));
      default:
        return null;
    }
  };

  if (isLoading) {
    return (
      <LoadingPage message={`Fetching ${type}s`} />
    )
  }

  if (!results) {
    return (
      <InfoPage message={`There was an unknown error fetching ${type}s. Please refresh the page to try again. If the problem persists, please contact support.`} />
    )
  }

  if (results.length === 0) {
    return (
      <InfoPage message={`There are no ${type}s for your filters`} />
    )
  } else {
    return (
      <div className="flex flex-col gap-4">
        {renderResults()}
        {totalItems > pageSize * page && (

          <div className="flex justify-center h-10">
            <SightlyButton
              datatestid="load-more-trend-result-button"
              block
              id="loadmorebutton"
              text="Load More"
              handleClick={() => handlePageChange(page + 1)}
              loading={isLoading}
            />
          </div>)}

        {(openPanel && panelResult) && (
          type === 'narrative' ? (
            <SocialNarrativeSlideOver
              openSocialNarrativePanel={openPanel}
              setOpenSocialNarrativePanel={setOpenPanel}
              selectedSocialClusterId={(panelResult as INarrative).id}
            />
          ) : type === 'moment' ? (
            <MomentSlideOver
              show={openPanel}
              close={setOpenPanel}
              moment={parseToClusterType(panelResult as IMoment)}
              brandProfileId={brandProfileId}
              postTrendRecActionFeedback={postTrendRecActionFeedback}
              postTrendKeywordsFeedback={postTrendKeywordsFeedback}
              invalidateMomentsQuery={invalidateMomentsQuery}
            />
          ) : null
        )}
      </div>
    )
  };
};

export default ResultCardList;
