import { z } from 'zod';

export interface ICategoryOption {
    label: string,
    value: string
}

export interface ISortByOption {
    label: string,
    value: string
}

export interface IDateOption {
    label: string,
    value: string
}

export interface IStatusOption {
    label: string,
    value: string
}

export interface IHashtagsStatus {
    hashtag_id: string,
    status: string,
    campaign_names: [string]
}

export interface IHashtag {
    dateRange: string,
    countryCode: string,
    categoryName: string,
    hashtagId: string,
    hashtagName: string,
    rankPosition: string,
    rankChange: string,
    views: number,
    viewsGlobalLifetime: number,
    posts: number,
    postsGlobalLifetime: number,
    topCountryList: string,
    status: string,
    createdAt: string  
}

const HashtagSchema = z.object({
    dateRange: z.string(),
    countryCode: z.string(),
    categoryName: z.string(),
    hashtagId: z.string(),
    hashtagName: z.string(),
    rankPosition: z.string(),
    rankChange: z.string(),
    views: z.number(),
    viewsGlobalLifetime: z.number(),
    posts: z.number(),
    postsGlobalLifetime: z.number(),
    topCountryList: z.string(),
    status: z.string(),
    createdAt: z.string()
})

export const GetHashtagsSchema = z.object({
    config: z.object({
        sortByOptions: z.array(z.object({
            label: z.string(),
            value: z.string()
        })),
        categoryOptions: z.array(z.object({
            label: z.string(),
            value: z.string()
        })),
        dateOptions: z.array(z.object({
            label: z.string(),
            value: z.string()
        })),
        statusOptions: z.array(z.object({
            label: z.string(),
            value: z.string()
        }))
    }),
    hashtags: z.object({
        totalCount: z.number(),
        hasNext: z.boolean(),
        result: z.array(HashtagSchema)
    })
})

export interface IAgeAudience {
    age: string
    percentage: number
}

const AudienceAgesSchema = z.object({
    age: z.string(),
    percentage: z.number()
})

export const GetHashtagAudienceSchema = z.object({
    audienceAges: z.array(AudienceAgesSchema)
})

export type SelectedHashtagType = {
    hashtagId : string,
    hashtagName: string,
    categoryName: string,
}

export type ActivationStatusType = {
    type: 'SUCCESS' | 'FAILURE' | 'PARTIAL_FAILURE',
    message: string
}[]