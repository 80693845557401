import { accentColor } from '@/assets/jss/colorConstants'
import ChartTooltip from '@/views/Discover/Boards/components/conversations/ChartTooltip'
import PlatformIcon from "@/views/Discover/Boards/components/conversations/PlatformIcon"
import { INarrative, IPostCountPerDay } from '@/views/Trends/types'
import { Link } from '@tanstack/react-location'
import React from 'react'
import { Area, AreaChart, Tooltip as RechartTooltip, ResponsiveContainer, XAxis } from 'recharts'

interface IProps {
  narrative: INarrative,
  toggleSocialNarrativePanel: Function
}

function parseAreaChartData(data: IPostCountPerDay[]) {
  const sortedData = data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())

  return sortedData.map((d, index, array) => {
    const previousPostCount = index > 0 ? array[index - 1].count : null
    const growth = previousPostCount ? (d.count - previousPostCount) / previousPostCount : null

    return {
      date: new Date(d.date).toLocaleDateString('en-US', { year: '2-digit', month: '2-digit', day: '2-digit' }),
      posts: d.date,
      growth: growth ? Number((growth * 100).toFixed(0)) : null
    }
  })
}


const ResultSocialNarrativeCard: Function = React.memo(
  ({ narrative, toggleSocialNarrativePanel }: IProps) => {
    const top3Platforms = narrative.postCountPerSource?.sort((a, b) => b.count - a.count).slice(0, 3)

    return (
      <div
        data-testid="anticipation-boards-social-narrative-card"
        className="hover:z-50 animate-in fade-in rounded-xl bg-white px-2 xl:px-0 py-4 border-[0.5px] border-gray-200 drop-shadow-lg grid min-w-fit w-full grid-cols-1 divide-y xl:grid-cols-5 xl:divide-y-0 xl:divide-x divide-slate-300 h-full"
      >
        {/* Left section */}
        <div className="col-span-3 flex flex-row gap-6 py-2 xl:py-0 px-4">
          <div className="flex flex-col gap-2 cursor-pointer"
            onClick={() => toggleSocialNarrativePanel(narrative.id)}
          >
            <div>
              <span data-testid="anticipation-boards-social-narrative-card-title" className="text-xs xl:text-sm font-bold line-clamp-1">{narrative.name}</span>
            </div>
            <div className='grow flex flex-col justify-between'>
              <div
                data-testid="anticipation-boards-social-narrative-card-top-post"
                className="grow grid grid-cols-[1.75rem_minmax(0,_1fr)] gap-2">
                {narrative.topPost.source && (
                  <PlatformIcon platformName={narrative.topPost.source} width="100%"></PlatformIcon>
                )}
                <p data-testid="anticipation-boards-social-narrative-card-top-post-description" className="line-clamp-2 lg:line-clamp-3 text-gray-500 md:text-[.7rem] lg:text-[.8rem]">
                  {narrative.topPost.post}
                </p>
              </div>
              <Link
                className='mt-2'
                data-testid="anticipation-boards-social-narrative-card-view-more-posts"
                style={{ color: accentColor, fontWeight: 600, fontSize: 10 }}
                to={''}
              >
                View more posts
              </Link>
            </div>
          </div>
        </div>
        <div className="col-span-2 grid grid-cols-3 py-2 xl:py-0 px-8">
          {/* Middle section */}
          <div data-testid="anticipation-boards-social-narrative-card-total-posts" className="col-span-2 flex flex-col px-4 justify-between">
            <div>
              <p className="font-bold text-gray-500 text-[.8rem]">Total posts<br></br><span className="text-xl font-bold text-gray-500">{narrative.postCount}</span></p>
            </div>
            <div className='aspect-[1/0.25]'>
              {narrative.postCountPerDay ?
                <ResponsiveContainer>
                  <AreaChart
                    margin={{ bottom: -10 }}
                    data={parseAreaChartData(narrative.postCountPerDay)}
                  >
                    <XAxis
                      dataKey="date"
                      axisLine={false}
                      tickLine={false}
                      style={{
                        fontSize: '8px'
                      }}
                    />
                    <Area
                      type="linear"
                      dataKey="posts"
                      stroke={'#651AEB'}
                      fill={'#ECE3FC'}
                    />
                    <RechartTooltip content={<ChartTooltip />} allowEscapeViewBox={{ x: true, y: true }} wrapperStyle={{ zIndex: 1000 }} />
                  </AreaChart>
                </ResponsiveContainer>
                :
                <p className='text-slate-400'>No data to show</p>
              }
            </div>
          </div>
          {/* Right section */}
          <div data-testid="anticipation-boards-social-narrative-card-top-platforms" className="col-span-1">
            <p className="font-bold text-gray-500 text-[.8rem]">Top platforms</p>
            <p></p>
            <div className="flex flex-col font-bold text-gray-500 text-xs gap-2">
              {top3Platforms && top3Platforms.length > 0 ? (
                top3Platforms.map((topPlatform, index) => (
                  <div key={index} className="flex flex-row gap-4 items-center">
                    {topPlatform.source && (
                      <PlatformIcon platformName={topPlatform.source} width="1.25rem" />
                    )}
                    <p
                      data-testid={`anticipation-boards-social-narrative-card-top-platform-${topPlatform.source?.replace(
                        /[^a-zA-Z0-9]/g,
                        ''
                      )}-post-count`}
                    >
                      {topPlatform.count} posts
                    </p>
                  </div>
                ))
              ) : (
                <p className="text-slate-400">No data to show</p>
              )}
            </div>

          </div>
        </div>
      </div>
    )
  }
)

export default ResultSocialNarrativeCard
