import React, { useState } from 'react'
import { Layer, Rectangle, Sankey, Tooltip, TooltipProps } from 'recharts';

interface IProps {
    data: any
}

const SankeyGraphComponent = ({ data }: IProps) => {
    const SANKEY_BASE_NODE_COLOR = 'rgba(79, 70, 229, 1)';
    const SANKEY_GREEN_NODE_COLOR = 'rgba(72, 194, 148, 1)';

    const SANKEY_BASE_FILL_COLOR = 'rgba(0, 136, 254, 0.5)';
    const SANKEY_BASE_FILL_COLOR_HOVER = 'rgba(0, 125, 204, 0.5)';

    const SANKEY_BASE_FILL_COLOR_GREEN = 'rgba(72, 194, 148, 0.3)';
    const SANKEY_BASE_FILL_COLOR_HOVER_GREEN = 'rgba(72, 194, 148, 0.5)';
    
    interface NodeProps {
        x: number;
        y: number;
        width: number;
        height: number;
        index: number;
        type: string;
        payload: {
            name: string;
            value: number;
        };
        containerWidth: number;
    }

    interface CustomLinkProps {
        sourceX: number;
        targetX: number;
        sourceY: number;
        targetY: number;
        sourceControlX: number;
        targetControlX: number;
        linkWidth: number;
        index: number;
        type: string;
    }

    const Link: React.FC<CustomLinkProps> = ({
        sourceX,
        targetX,
        sourceY,
        targetY,
        sourceControlX,
        targetControlX,
        linkWidth,
        index,
        type
    }) => {
        let baseColor = SANKEY_BASE_FILL_COLOR;
        let hoverColor = SANKEY_BASE_FILL_COLOR_HOVER;
    
        switch (type) {
            case 'Topic':
                baseColor = SANKEY_BASE_FILL_COLOR;  
                hoverColor = SANKEY_BASE_FILL_COLOR_HOVER; 
                break;
            case 'Trend':
                baseColor = SANKEY_BASE_FILL_COLOR; 
                hoverColor = SANKEY_BASE_FILL_COLOR_HOVER;
                break;
            case 'Moment':
                baseColor = SANKEY_BASE_FILL_COLOR_GREEN; 
                hoverColor = SANKEY_BASE_FILL_COLOR_HOVER_GREEN; 
                break;
            default:
                baseColor = SANKEY_BASE_FILL_COLOR; 
                hoverColor = SANKEY_BASE_FILL_COLOR_HOVER;
                break;
        }
    
        const [fill, setFill] = useState(baseColor);

        return (
            <Layer key={`CustomLink${index}`}>
                <path
                    d={`
            M${sourceX},${sourceY + linkWidth / 2}
            C${sourceControlX},${sourceY + linkWidth / 2}
              ${targetControlX},${targetY + linkWidth / 2}
              ${targetX},${targetY + linkWidth / 2}
            L${targetX},${targetY - linkWidth / 2}
            C${targetControlX},${targetY - linkWidth / 2}
              ${sourceControlX},${sourceY - linkWidth / 2}
              ${sourceX},${sourceY - linkWidth / 2}
            Z
          `}
                    fill={fill}
                    strokeWidth="0"
                    onMouseEnter={() => setFill(hoverColor)}
                onMouseLeave={() => setFill(baseColor)}
                />
            </Layer>
        );
    };


    const Node: React.FC<NodeProps> = ({
        x,
        y,
        width,
        height,
        index,
        type,
        containerWidth,
    }) => {
        const isOut = x + width + 6 > containerWidth;

        let baseColor = SANKEY_BASE_NODE_COLOR;
    
        switch (type) {
            case 'Topic':
                baseColor = SANKEY_BASE_NODE_COLOR;
                break;
            case 'Trend':
                baseColor = SANKEY_BASE_NODE_COLOR;
                break;
            case 'Moment':
                baseColor = SANKEY_GREEN_NODE_COLOR;
                break;
            default:
                break;
        }

        return (
            <Layer key={`CustomNode${index}`}>
                <Rectangle
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    fill={baseColor}
                    fillOpacity="1"
                />
                <text
                    textAnchor={isOut ? "end" : "start"}
                    x={isOut ? x - 6 : x + width + 6}
                    y={y + height / 2 + 13}
                    fontSize="12"
                    stroke="#333"
                    strokeOpacity="0.5"
                >
                </text>
            </Layer>
        );
    };

    return (
        <div className='flex flex-row justify-center w-full '>
            <Sankey
                width={670}
                height={520}
                margin={{ top: 20, bottom: 20 }}
                data={data}
                nodeWidth={20}
                nodePadding={50}
                linkCurvature={0.5}
                iterations={30}
                link={(props) => {
                    return (<Link
                        {...props}
                        linkWidth={Math.min(props.payload.source.dy, props.payload.target.dy)}
                        type={props.payload.target.type}
                        opacity={0.7}
                    />
                    )
                }}
                node={(props) => {
                    return (
                        <Node
                            {...props}
                            type={props.payload.type}
                            containerWidth={960}
                            className="fill-[#696cff] stroke-white stroke-2"
                        >
                            <text
                                x={props.x0 + 6}
                                y={(props.y0 + props.y1) / 2}
                                dy="0.35em"
                                textAnchor="start"
                                fill="#333"
                                fontSize="12"
                            >
                                {props.name}
                            </text>
                        </Node>
                    )
                }}
            >
                <Tooltip content={<CustomTooltip />} />
            </Sankey>
        </div>
    )
}

const CustomTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload }) => {

    if (active && payload && payload.length) {
        let label = '';
        let color = 'bg-[#5192ca]';

        switch (payload[0].payload.payload.type) {
            case 'Topic':
                label = 'Topic:'
                color = 'bg-[#5192ca]';
                break;
            case 'Trend':
                label = 'Trend:'
                color = 'bg-[#5192ca]';
                break;
            case 'Moment':
                label = 'Moment:'
                color = 'bg-[#48C294]';
                break;
            default:
                label = ''
                break;
        }
        return (
            <div className='flex flex-row items-center bg-[#f9f9f9] rounded-lg p-2.5 shadow-md'>
                <div className={`w-4 h-4 ${color} rounded-full m-2`}></div>
                <div className='flex flex-row max-w-md flex-wrap'>
                    <p className='mr-2 font-bold underline'>
                        {label}
                    </p>
                    <p className='m-0'>{payload[0].name}</p>
                </div>
            </div>
        );
    }

    return null;
};

export default SankeyGraphComponent