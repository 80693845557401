import React from 'react'
export const LoadingPage = ({ message }: { message: string }) => {
    return (
        <div
            className="animate-in fade-in"
            data-testid="loading-spinner"
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100%',
                width: '100%'
            }}
        >
            <div className="loader">
                {message}
                <span className="loader__dot">.</span>
                <span className="loader__dot">.</span>
                <span className="loader__dot">.</span>
            </div>
        </div>
    )
}
