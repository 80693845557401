import {
  ReportTemplate,
  REPORT_TYPE,
  Option,
  TEMPLATE_TYPE,
  templateGenericDimensionOptions,
  templateMetricOptions,
  templateConversionOptions,
  GENERIC_DIMENSION,
  METRIC, GenericDimensionOption, CONVERSION
} from "@/views/ActivationReports/types/activation-report.types";

import personaIcon from '@/assets/img/persona.svg';

export enum AUDIENCE_DIMENSION {
    AUDIENCE_NAME = 'audienceName',
    AUDIENCE_TYPE = 'audienceType',
}

interface AudienceDimensionOption extends Omit<GenericDimensionOption, 'value'> {
  name: string
  value: GENERIC_DIMENSION | AUDIENCE_DIMENSION
}


export interface AudienceReport extends Omit<ReportTemplate, 'columns' | 'dimensions'> {
  columns: (GENERIC_DIMENSION | AUDIENCE_DIMENSION | METRIC | CONVERSION)[],
  dimensions: (GenericDimensionOption | AudienceDimensionOption)[]
}

export const dimensionOptions: Option<AudienceDimensionOption> = {
    [AUDIENCE_DIMENSION.AUDIENCE_NAME]: {
      name: 'Audience Name',
      value: AUDIENCE_DIMENSION.AUDIENCE_NAME
    },
    [AUDIENCE_DIMENSION.AUDIENCE_TYPE]: {
      name: 'Audience Type',
      value: AUDIENCE_DIMENSION.AUDIENCE_TYPE
    },
}

export const baseAudienceReport: AudienceReport = {
  name: 'Audience Report',
  description: '',
  type: REPORT_TYPE.DETAIL,
  templateType: TEMPLATE_TYPE.AUDIENCE,
  columns: [],
  dimensions: [
    ...templateGenericDimensionOptions,
    ...Object.keys(dimensionOptions).map(key => dimensionOptions[key])
  ],
  metrics: templateMetricOptions,
  conversions: templateConversionOptions
}


export const personaAudienceReportTemplate: AudienceReport = {
    ...baseAudienceReport,
    name: 'Persona Audience Report',
    columns: [
      METRIC.STAT_DATE,
      GENERIC_DIMENSION.CUSTOMER_ID,
      GENERIC_DIMENSION.INSERTION_ORDER_NAME,
      GENERIC_DIMENSION.PERSONA,
      GENERIC_DIMENSION.AD_FORMAT,
      AUDIENCE_DIMENSION.AUDIENCE_TYPE,
      AUDIENCE_DIMENSION.AUDIENCE_NAME,
      METRIC.IMPRESSIONS,
      METRIC.CPM,
      METRIC.VIEWS,
      METRIC.CLICKS,
      METRIC.CTR,
      METRIC.COST,
      METRIC.VCR,
      METRIC.QUARTILE_25,
      METRIC.QUARTILE_50,
      METRIC.QUARTILE_75,
      METRIC.QUARTILE_100,
    ],
    icon: personaIcon
}
