import sparklesAISvg from '@/assets/img/AI/SparklesAI.svg'
import { MyLocationGenerics } from '@/classes/utils'
import { InfoPage } from '@/components/InfoPage'
import { LoadingPage } from '@/components/LoadingPage'
import SightlyButton from '@/components/Sightly/SightlyButton'
import AnalyzeSlidedeover from '@/views/Trends/components/AnalyzeSlideover'
import DetailDisplaySections from '@/views/Trends/components/DetailDisplaySections'
import DetailOverview from '@/views/Trends/components/DetailOverview'
import DetailTimeline from '@/views/Trends/components/DetailTimeline'
import TimelineChartWrapper from '@/views/Trends/components/TimelineChartWrapper'
import {
  useGetSankeyData,
  useGetTopicArticles,
  useGetTopicMomentTimeline,
  useGetTopicSocialPosts,
  useTopicById
} from '@/views/Trends/hooks/useTopics'
import { IMetricsPerDay, ITopic } from '@/views/Trends/types'
import { ArrowDownTrayIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { useMatch, useNavigate } from '@tanstack/react-location'
import { useFlag } from '@unleash/proxy-client-react'
import React, { useEffect, useState } from 'react'

export const TopicDetails = () => {
  const showRelevance = false;

  const navigate = useNavigate()
  const trendsEnabled = useFlag('enable_trends_feature')
  if (!trendsEnabled) navigate({ to: '/app/discover/moments/v2' })
  const downloadEnabled = useFlag('enable_trends_feature_download')

  const { params } = useMatch<MyLocationGenerics>()
  const topicId = params.topicId;

  const [topicData, setTopicData] = useState<ITopic>()
  const [lineChartData, setLineChartData] = useState<{
    socialPosts: IMetricsPerDay[],
    articles: IMetricsPerDay[]
  }>()

  const { topicQuery } = useTopicById(topicId)

  useEffect(() => {
    if (topicQuery.data) {
      const data = topicQuery.data.data
      setTopicData(data)
      setLineChartData({
        socialPosts: data.metrics.narrative.postCountPerDay || [],
        articles: data.metrics.moment.articleCountPerDay || []
      })
    }
  }, [topicQuery.data])

  const topBrands: { id: number; name: string }[] = []

  const topPeople: { id: number; name: string }[] = []

  const { momentTimelineQuery } = useGetTopicMomentTimeline(topicId)
  const momentTimeline = momentTimelineQuery?.data || []

  const { articlesQuery } = useGetTopicArticles(topicId)
  const articles = articlesQuery?.data || []

  const { socialPostsQuery } = useGetTopicSocialPosts(topicId)
  const socialPosts = socialPostsQuery?.data || []

  const { sankeyQuery } = useGetSankeyData(topicId)
  const sankeyData = sankeyQuery?.data

  const sankeyTopicData = sankeyData?.momentNodes && sankeyData.momentNodes.length > 0
    ? {
      nodes: [...sankeyData.momentNodes],
      links: [...sankeyData.momentLinks]
    }
    : undefined;

  const [showAnalyzeSlideover, setShowAnalyzeSlideover] = React.useState(false)

  return (
    <div className="flex flex-col p-6">
      <AnalyzeSlidedeover
        showSlideOver={showAnalyzeSlideover}
        setShowSlideOver={setShowAnalyzeSlideover}
        entityData={topicData}
      />
      <div
        className="flex flex-row justify-between items-center pb-4"
        data-testid="topic-details-header"
      >
        <div className="flex flex-row gap-2">
          <h4 data-testid="topic-details-title">{topicData?.name || 'Topic...'}</h4>
        </div>
        <div className="flex flex-row gap-4 items-center">
          <div className="flex flex-row gap-2">
            {downloadEnabled && <div className="h-10">
              <SightlyButton
                datatestid="topic-detail-download-btn"
                handleClick={() => { }}
                id="downloadActivation"
                text="Download"
                type="transparent"
                disabled={false}
                col={true}
                icon={
                  <ArrowDownTrayIcon
                    className="h-4 w-4 text-purple-600"
                    aria-hidden="true"
                  />
                }
              />
            </div>}

            <div className="border-l border-gray-300 mx-6"></div>

            <div className="h-10 flex justify-center">
              <SightlyButton
                id="aiAnalyzeButton"
                handleClick={() => setShowAnalyzeSlideover(true)}
                text="Analyze"
                type="gradient-ai"
                icon={
                  <img
                    data-testid={'ai-analyze-image'}
                    src={sparklesAISvg}
                    className=" h-4 w-4 text-grey-900"
                  />
                }
              />
            </div>
          </div>

          <div className="flex items-center justify-center w-10 h-10 rounded-full border-[1.1px] border-[rgba(212,217,217,1)] bg-gray-200">
            <button
              data-testid="moment-close-button"
              type="button"
              className="text-black rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              onClick={() => { }}
            >
              <span className="sr-only">Close panel</span>
              <XMarkIcon
                className="w-6 h-6"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-between gap-4 mt-8">
        <div className="w-3/5">
          {topicQuery.error ?
            <div className='mb-4'>
              <InfoPage message="There was an error fetching topic details. Please contact support for help." />
            </div>
            : topicQuery.isLoading || !topicData ?
              <div className='mb-4'>
                <LoadingPage message="Loading topic overview" />
              </div>
              : <DetailOverview
                overviewText={topicData.overview || ''}
                overviewBullets={topicData.bulletsText || []}
                totalMoments={topicData.metrics?.moment?.count || 0}
                totalNarratives={topicData.metrics?.narrative?.count || 0}
                totalArticles={topicData.metrics?.moment?.articleCount || 0}
                totalSocialPosts={topicData.metrics?.narrative?.postCount || 0}
                sentimentScore={topicData.metrics?.sentimentScore || 0}
                sentimentType={
                  topicData.metrics?.sentimentScore === 0 || !topicData.metrics?.sentimentScore
                    ? ''
                    : topicData.metrics?.sentimentScore > 0
                      ? 'Positive'
                      : 'Negative'
                }
                topBrands={topBrands}
                topPeople={topPeople}
              />
          }
        </div>

        <div className="border-l border-gray-300 mx-6"></div>

        <div className="flex flex-col w-2/5 gap-4 pb-5">
          <DetailTimeline timelineData={momentTimeline} />

          <div className="border-t border-black-300"></div>

          <TimelineChartWrapper
            lineChartData={lineChartData}
          />
        </div>
      </div>

      <div className="border-t border-black-300"></div>

      <DetailDisplaySections articles={articles} socialPosts={socialPosts} sanKeyData={sankeyTopicData && sankeyTopicData.nodes.length > 0 ? sankeyTopicData : undefined} />
    </div>
  )
}
