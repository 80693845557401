import NoImageFoundPath from '@/assets/img/NoImageFound.svg'
import ChartTooltip from '@/views/Discover/Boards/components/conversations/ChartTooltip'
import { RelevanceClassificationBadge } from '@/views/Discover/Moments/v2/components/RelevanceClassificationBadge'
import Growth from '@/views/Trends/SearchResults/components/Growth'
import { ITopic, ITrend } from '@/views/Trends/types'
import React from 'react'
import { Area, AreaChart, Tooltip as RechartTooltip, ResponsiveContainer, XAxis } from 'recharts'

interface IProps {
  result: ITopic | ITrend
  handleSelect: (result: ITopic | ITrend) => void
}

const ResultCard = ({ result, handleSelect }: IProps) => {
  const totals = [
    { name: 'Total Moments', value: result.metrics.moment.count },
    { name: 'Total Social Posts', value: result.metrics.narrative.count }
  ]

  if (result?.metrics?.trend?.count !== undefined) {
    totals.push({ name: 'Total Trends', value: result.metrics.trend.count })
  }

  return (
    <div
      className="cursor-pointer relative grid grid-cols-11 gap-2 h-fit rounded-md border border-gray-200 bg-white hover:z-50 animate-in fade-in"
      onClick={() => handleSelect(result)}
    >
      {/* Image section */}
      <div
        className="col-span-2 content-center"
        data-testid="result-image"
      >
        <img
          src={result.imageUrl}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null // prevents looping
            currentTarget.src = NoImageFoundPath
          }}
          alt={`image:${result.name}`}
          className="w-full h-full object-cover rounded-md"
          data-testid="result-image-src"
        />
      </div>
      <div className="col-span-9 px-2 xl:px-0 py-4 grid grid-cols-1 gap-4 divide-y xl:grid-cols-5 xl:divide-y-0 xl:divide-x divide-slate-300 ">
        {/* Middle section */}
        <div className="xl:col-span-3 flex flex-col gap-7 pl-5">
          <div data-testid="result-card-title">
            <span className="text-xs xl:text-sm font-bold line-clamp-1">{result.name}</span>
            <p data-testid="result-card-overview">{result.overview}</p>
          </div>
          <div className="flex flex-row justify-between">
            {totals.map(({ name, value }, index) => (
              <div
                className="flex flex-col gap-3"
                key={`trend-topic-result-card-${index}`}
                data-testid={`result-card-totals-${index}`}
              >
                <span
                  className="text-xs"
                  data-testid={`result-card-totals-${index}-name`}
                >
                  {name}
                </span>
                <span
                  className="font-bold text-lg text-sightlyPurple"
                  data-testid={`result-card-totals-${index}-value`}
                >
                  {value}
                </span>
              </div>
            ))}
          </div>
        </div>
        {/* Graph section */}
        <div
          className="xl:col-span-2 px-4 flex flex-col justify-between"
          data-testid="result-card-graph-section"
        >
          <RelevanceClassificationBadge
            relevanceClassification={result.relevance}
            background
          />
          <Growth
            growth={result.growth ?? 0}
            data-testid="result-card-growth"
          />
          <ResponsiveContainer
            width="100%"
            height="45%"
            minHeight={100}
          >
            <AreaChart
              margin={{ bottom: -10 }}
              data={result.metrics?.moment.articleCountPerDay}
              data-testid="result-card-graph"
            >
              <XAxis
                dataKey="date"
                axisLine={false}
                tickLine={false}
                style={{
                  fontSize: '8px'
                }}
              />
              <Area
                type="linear"
                dataKey="count"
                stroke={'#651AEB'}
                fill={'#ECE3FC'}
              />
              <RechartTooltip
                content={<ChartTooltip />}
                allowEscapeViewBox={{ x: false, y: true }}
                wrapperStyle={{ zIndex: 1000 }}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  )
}

export default ResultCard
