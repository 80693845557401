import React from 'react'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { INarrative } from '@/views/Trends/types'
import PlatformIcon from '@/views/Discover/Boards/components/conversations/PlatformIcon'
dayjs.extend(localizedFormat)

interface SocialPostCardProps {
  narrative: INarrative
}

const SocialPostResultCard = ({ narrative }: SocialPostCardProps) => {
  return (
    <div
      className="bg-white rounded-lg shadow-md overflow-hidden p-4 w-80 h-[184px] inline-block mr-4 mb-4"
      data-testid="social-post-result-card"
    >
      <div className="flex flex-col">
        <div className="flex justify-between mb-4">
          <div className="inline-block">
            {narrative.sourceTypeList.map((sourceType, index) => (
              <PlatformIcon
                key={`platform-icon-${sourceType}-${index}`}
                platformName={sourceType}
                height="24px"
                data-testid="social-post-result-card-platform-icon"
              ></PlatformIcon>
            ))}
          </div>
          <span
            className="text-gray-400 text-sm ml-1"
            data-testid="social-post-result-card-date"
          >
            {dayjs(narrative.createdAt).format('L')}
          </span>
        </div>
        <p
          className="text-gray-600"
          data-testid="social-post-result-card-content"
        >
          <span>{narrative.name}</span>
        </p>
      </div>
    </div>
  )
}

export default SocialPostResultCard
