import { formatDateyyyyMMdd } from '@/utils';
import { DateRangeType, ranges, getLast24Hrs, getLast60Days } from '@/utils/dateRanges';
import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'rsuite';
const { combine, before, afterToday } = DateRangePicker;
import { ValueType } from 'rsuite/lib/DateRangePicker';

interface IProps {
    onSelectDateRange: (selectedRange: any) => void
    onCalendarStateChange?: (open: boolean) => void
    selectableRanges?: DateRangeType[]
    rangeValue?: [Date, Date]
}

const DateRangeSection = ({
    onSelectDateRange,
    onCalendarStateChange,
    selectableRanges = ranges,
    rangeValue = getLast24Hrs() }: IProps) => {

    const [value, setValue] = useState(rangeValue)

    useEffect(() => {
        if (rangeValue && (rangeValue[0] !== value[0] || rangeValue[1] !== value[1])) {
            setValue(rangeValue);
        }
    }, [rangeValue]);

    const predefinedRanges = new Map(
        (selectableRanges || ranges).map((range) => {
            const value = range.value();
            return [range.label, value[0]];
        })
    );

    const handleCalendarStateChange = (open: boolean) => {
        if (onCalendarStateChange)
            onCalendarStateChange(open);
    };

    const handleDateRangeChange = (dateRange: any) => {
        setValue(dateRange)
        onSelectDateRange(dateRange);
    };

    const renderValue = (value: ValueType, _: string) => {
        const key = Array.from(predefinedRanges.entries()).find(([_, v]) => 
            v.getDate() === value[0]?.getDate() &&
            v.getMonth() === value[0]?.getMonth() &&
            v.getFullYear() === value[0]?.getFullYear()
        )?.[0];

        if (key) return key
        return formatDateyyyyMMdd(value[0]) + ' ~ ' + formatDateyyyyMMdd(value[1])
    }

    return (
        <DateRangePicker
            onChange={handleDateRangeChange}
            ranges={selectableRanges}
            cleanable={false}
            onOpen={() => handleCalendarStateChange(true)}
            onClose={() => handleCalendarStateChange(false)}
            value={value}
            preventOverflow={true}
            renderValue={renderValue}
        />
    );
};

export default DateRangeSection;
