import React from 'react'
import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { TrashIcon } from '@heroicons/react/24/outline'

const HeaderGroup = ({
  campaigns,
  expandedCampaigns,
  selectedCampaigns,
  campaignsCount,
  handleExpandAll,
  handleDeleteSelected,
  handleSelectAll
}: {
  campaigns: any;
  expandedCampaigns: Set<number> | null;
  selectedCampaigns: Set<number>;
  campaignsCount: number;
  handleExpandAll: () => void;
  handleDeleteSelected: () => void;
  handleSelectAll: () => void;
}) => {
  return (
    <thead className="bg-gray-50">
      <tr>
        <th
          scope="col"
          className="relative w-16"
        >
          <div className="flex gap-1 pl-2">
          <div
            className="cursor-pointer"
            data-testid="campaign-group-row-expand-btn"
            id="campaign-group-row-expand-btn"
            onClick={handleExpandAll}
              >
                { expandedCampaigns && expandedCampaigns?.size === campaigns.size ? (
                  <ChevronDownIcon
                    height={25}
                    width={25}
                  />
                ) : (
                  <ChevronRightIcon
                    height={25}
                    width={25}
                  />
                )}
          </div>
            <SightlyCheckbox
                dataTestId="campaign-select-all-checkbox"
                handleChange={handleSelectAll}
                id="selectAllCampaigns"
                checked={selectedCampaigns.size === campaignsCount}
              />
          </div>
        </th>
        <th
          scope="col"
          className="py-3 text-sm font-semibold text-left text-gray-600"
        >
          Id
        </th>
        <th
          scope="col"
          className="py-3 text-sm font-semibold text-left text-gray-600"
        >
          Campaign Name
        </th>
        <th
          scope="col"
          className="py-3 text-sm font-semibold text-left text-gray-600"
        >
          Advertisers
        </th>
        <th
          scope="col"
          className="py-3 text-sm font-semibold text-left text-gray-600 w-28"
        >
          Create time
        </th>
        <th
          scope="col"
          className="py-3 text-sm font-semibold text-left text-gray-600 w-14"
        >
          Status
        </th>

        <th
          scope="col"
          className="relative w-12 py-3 pl-3 pr-4 sm:pr-6"
        >
          {selectedCampaigns.size > 0 && (
            <TrashIcon
              className="h-4 w-4 text-gray-600 cursor-pointer"
              data-testid="delete-selected-campaigns"
              id="delete-selected-campaigns"
              onClick={handleDeleteSelected}
            />
          )}
        </th>
      </tr>
    </thead>
  )
}

export default HeaderGroup
