import { api } from '@/api/api'
import { logError } from '@/utils'
import { useQuery } from '@tanstack/react-query'
import { AnalyzeTrendParams } from '../types'

export function useTrend() {

  const trendsQuery = useQuery(
    ['trendsQuery'],
    async () => {
      return await api.trends.get();
    },
    {
      retry: false,
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 10, 
      onError: (err) => {
        logError(err, { info: 'Error fetching trends data' });
      }
    }
  );

  return {
    trendsData: trendsQuery.data?.data,
    isLoadingTrends: trendsQuery.isLoading,
  };
}

export function useTrendSearch(search?: string) {

  const trendsQuery = useQuery(
    ['trendsQuery', search],
    async ({ signal }) => {
      return await api.trends.get(search, signal);
    },
    {
      retry: false,
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 10, 
      enabled: !!search
    }
  );

  return {
    trendsData: trendsQuery.data?.data,
    trendsError: trendsQuery.error,
    isLoadingTrends: trendsQuery.isLoading,
  };
}

export function useTrendById(trendId: string) {
  const trendQuery = useQuery(
    ['trendQuery', trendId],
    async () => {
      return await api.trends.getById(trendId)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'Error fetching trend by id' })
      }
    }
  )

  return {
    trendQuery: trendQuery
  }
}

export function useGetTrendPeople(trendId: string) {
  const peopleQuery = useQuery(
    ['trendPeopleQuery', trendId],
    async () => {
      return await api.trends.getPeople(trendId)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'Error fetching trend people' })
      }
    }
  )

  return {
    peopleQuery
  }
}

export function useGetTrendBrands(trendId: string) {
  const brandsQuery = useQuery(
    ['trendBrandsQuery', trendId],
    async () => {
      return await api.trends.getBrands(trendId)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'Error fetching trend brands' })
      }
    }
  )

  return {
    brandsQuery
  }
}

export function useGetTrendNarratives(trendId: string, page?: number, pageSize?: number) {
  const narrativesQuery = useQuery(
    ['trendNarrativesQuery', trendId, page, pageSize],
    async () => {
      return await api.trends.getNarratives(trendId, undefined, page, pageSize)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'Error fetching trend narratives' })
      }
    }
  )
    
  return {
    narrativesQuery
  }
}

export function useGetTrendMoments(trendId: string, search?: string, page?: number, pageSize?: number) {
  const momentsQuery = useQuery(
    ['trendMomentsQuery', trendId, search, page, pageSize],
    async () => {
      return await api.trends.getMoments(trendId, search, page, pageSize)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'Error fetching trend moments' })
      }
    }
  )

  return {
    momentsQuery,
  }
}

export function useGetTrendArticles(trendId: string) {
  const articlesQuery = useQuery(
    ['trendArticlesQuery', trendId],
    async () => {
      return await api.trends.getArticles(trendId)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'Error fetching trend moments' })
      }
    }
  )

  return {
    articlesQuery
  }
}

export function useGetSankeyData(trendId: string) {
  const sankeyQuery = useQuery(
    ['sankeyQuery', trendId],
    async () => {
      return await api.trends.getSankeyData(trendId)
    },
    {
      retry: 1,
      cacheTime: 0,

      onError: (err) => {
        logError(err, { info: 'Error fetching trend moments' })
      }
    }
  )

  return {
    sankeyQuery
  }
}

export function useGetTrendMomentTimeline(trendId: string) {
  const momentTimelineQuery = useQuery(
    ['trendMomentTimelineQuery', trendId],
    async () => {
      return await api.trends.getMomentTimeline(trendId)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'Error fetching trend moment timeline' })
      }
    }
  )

  return {
    momentTimelineQuery
  }
}

export function useGetTrendSocialPosts(trendId: string) {
  const socialPostsQuery = useQuery(
    ['trendSocialPostsQuery', trendId],
    async () => {
      return await api.trends.getSocialPosts(trendId)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'Error fetching trend social posts' })
      }
    }
  )

  return {
    socialPostsQuery
  }
}

export function useGetTrendArticlesMetrics(trendId: string) {
  const articleMetricsQuery = useQuery(
    ['trendArticlesMetricsQuery', trendId],
    async () => {
      return await api.trends.getArticlesMetrics(trendId)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'Error fetching trend articles metrics' })
      }
    }
  )

  return {
    articleMetricsQuery
  }
}

export function useGetTrendSentimentScore(trendId: string) {
  const sentimentScoreQuery = useQuery(
    ['trendSentimentScoreQuery', trendId],
    async () => {
      return await api.trends.getSentimentScore(trendId)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'Error fetching trend articles metrics' })
      }
    }
  )

  return {
    sentimentScoreQuery
  }
}

export function useAnalyzeTrend(params: AnalyzeTrendParams) {
  const analyzeTrendQuery = useQuery(
    ['analyzeTrendQuery', params],
    async () => {
      return await api.trends.analyzeTrend(params)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'Error analyzing trend' })
      },
      enabled: !!params.brandName && !!params.title
    }
  )

  return {
    analyzeTrendQuery
  }
}

