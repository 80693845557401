import ResultCardList from "@/views/Trends/SearchResults/components/ResultCardList";
import { IMoment } from "@/views/Trends/types";
import React, { useEffect, useState } from "react";

interface MomentTabProps {
    data: IMoment[];
    isLoading: boolean;
    page: number;
    pageSize: number;
    setPage: Function
    brandProfileId: number
}

export const MomentTab: React.FC<MomentTabProps> = ({
    data,
    isLoading,
    page,
    pageSize,
    setPage,
    brandProfileId
}) => {

    const [results, setResults] = useState<IMoment[]>([]);

    useEffect(() => {
        if (data.length > 0) {
            setResults((prevResults: IMoment[]) => [...prevResults, ...data]);
        }
    }, [data]);

    const handleLoadMore = () => {
        setPage((prevPage: number) => prevPage + 1);
    };

    return (
        <ResultCardList
            results={results}
            type="moment"
            isLoading={isLoading}
            page={page}
            totalItems={results.length}
            pageSize={pageSize}
            handlePageChange={handleLoadMore}
            brandProfileId={brandProfileId}
        />
    );
};