import { formatDateToFullMonthDayYear } from '@/utils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import React, { useEffect } from 'react'
import { DatePicker, RadioGroup, Radio, SelectPicker } from 'rsuite'
import { TIMEZONE_OPTIONS } from '@/views/ReportGenerator/constants'


dayjs.extend(utc);
dayjs.extend(timezone);

function ScheduleDateTime(props: { 
  startDate: Date,
  setStartDate: React.Dispatch<React.SetStateAction<Date>>,
  selectedTimezone: string,
  setSelectedTimezone: React.Dispatch<React.SetStateAction<string>>,
  meridian: 'AM' | 'PM',
  setMeridian: React.Dispatch<React.SetStateAction<'AM' | 'PM'>>
}) {
  const { startDate, setStartDate, selectedTimezone, setSelectedTimezone, meridian, setMeridian } = props

  useEffect(() => {
  const updatedDate = new Date(startDate);
  if (meridian === 'PM' && updatedDate.getHours() < 12) {
    updatedDate.setHours(updatedDate.getHours() + 12);
  } else if (meridian === 'AM' && updatedDate.getHours() >= 12) {
    updatedDate.setHours(updatedDate.getHours() - 12);
  }
  setStartDate(updatedDate);
  }, [meridian])

  const utcToTimezone = (utcDate: Date): Date => {
    return dayjs.tz(utcDate, selectedTimezone).toDate();
  };

  const handleDateRangeChange = (date: Date) => {
    const selectedDate = dayjs.utc(date).toDate();
    selectedDate.setHours(startDate.getHours());
    selectedDate.setMinutes(startDate.getMinutes());
    selectedDate.setSeconds(startDate.getSeconds());
    
    setStartDate(selectedDate)
  }

  const handleTimeChange = (time: Date) => {
      const updatedDate = dayjs.tz(startDate, selectedTimezone)
          .hour(dayjs.tz(time, selectedTimezone).hour())
          .minute(dayjs.tz(time, selectedTimezone).minute())
          .second(dayjs.tz(time, selectedTimezone).second())
          .utc()
          .toDate();

      updatedDate.setDate(startDate.getDate());
      updatedDate.setMonth(startDate.getMonth());
      updatedDate.setFullYear(startDate.getFullYear());

      setStartDate(updatedDate);
      
      const newMeridian = updatedDate.getHours() >= 12 ? 'PM' : 'AM';
      setMeridian(newMeridian);
  }

  const handleTimezoneChange = (newTimezone: string) => {
    setSelectedTimezone(newTimezone);
  }

  const handleMeridianChange = (value: 'AM' | 'PM') => {
    setMeridian(value);
  }

  return (
    <div
      className="mb-9 sm:px-1 text-grey-900 w-full"
      data-testid="schedule-date-time"
    >
      <div className="mb-2 font-medium" id="pendo-act-mgr-assignees-filter">
        Start Date and Time
      </div>
      <div
        className="flex flex-row items-center gap-2"
        data-testid="date-time-container"
      >
        <DatePicker
          value={utcToTimezone(startDate)}
          onChange={handleDateRangeChange}
          placeholder="Select Date"
          placement="autoVerticalStart"
          renderValue={(value) => formatDateToFullMonthDayYear(value)}
          cleanable={false}
          className="mr-2"
          showMeridian
          data-testid="date-picker"
        />

        <DatePicker
          value={startDate}
          onChange={handleTimeChange}
          placeholder="Select Time"
          placement="autoVerticalStart"
          format="HH:mm"
          cleanable={false}
          ranges={[]}
          className="mr-2"
          appearance="default"
          data-testid="time-picker"
        />

        <div
          className="flex flex-row items-center space-x-4"
          data-testid="meridian-container"
        >
          <RadioGroup
            name="meridian"
            inline
            value={meridian}
            onChange={handleMeridianChange}
          >
            <Radio
              value="AM"
              data-testid="am-radio"
              className="text-gray-700 accent-purple-700 !important" // Tailwind class for purple accent
            >
              AM
            </Radio>
            <Radio
              value="PM"
              data-testid="pm-radio"
              className="text-gray-700 accent-purple-700 !important"
            >
              PM
            </Radio>
          </RadioGroup>
        </div>


        <SelectPicker
          className="w-full"
          cleanable={false}
          data={TIMEZONE_OPTIONS}
          placeholder="Select Timezone"
          placement="autoVerticalStart"
          onChange={handleTimezoneChange}
          value={selectedTimezone}
          data-testid="timezone-picker"
        />
      </div>
    </div>
  )
}

export default ScheduleDateTime