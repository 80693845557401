import { BrandProfile } from '@/classes/brandProfile';
import { InfoPage } from '@/components/InfoPage';
import { LoadingItems } from '@/components/LoadingItems';
import { LoadingPage } from '@/components/LoadingPage';
import SightlyButton from '@/components/Sightly/SightlyButton';
import { PATH_MOMENT_DETAILS, PATH_NARRATIVE_DETAILS, PATH_TOPIC_DETAILS, PATH_TO_ONBOARDING_HUB, PATH_TREND_DETAILS } from '@/routes';
import { MomentSlideOver } from '@/views/Discover/Moments/v2/components/MomentSlideOver/MomentSlideOver';
import { ClusterType } from '@/views/Discover/Moments/v2/types';
import ResultCard from '@/views/Trends/SearchResults/components/ResultCard';
import ResultMomentCard from '@/views/Trends/SearchResults/components/ResultMomentCard';
import ResultSocialNarrativeCard from '@/views/Trends/SearchResults/components/ResultNarrativeCard';
import { useTopicTrendMomentById } from '@/views/Trends/hooks/useTopicTrendMoments';
import { IMoment, INarrative, ISearchResult, ITopic, ITrend, SearchResult } from '@/views/Trends/types';
import { useNavigate } from '@tanstack/react-location';
import React, { useEffect, useState } from 'react';

interface IProps {
  results?: ISearchResult[];
  type: SearchResult;
  isLoading: boolean;
  page: number;
  pageSize: number;
  totalItems: number;
  handlePageChange: (newPage: number) => void;
  brandProfileId?: number
  brandProfileSelectOptions?: BrandProfile[]
}

const ResultCardList = ({ results, type, handlePageChange, isLoading,
  page, pageSize, totalItems,
  brandProfileId, brandProfileSelectOptions }: IProps) => {

  const navigate = useNavigate()

  const [openPanel, setOpenPanel] = useState(false);

  const [narrativeId, setNarrativeId] = React.useState<string>();
  const [narrativeTab, setNarrativeTab] = React.useState<string>();

  const [momentId, setMomentId] = React.useState<number>();
  const [moment, setMoment] = React.useState<ClusterType>();
  const { momentsQuery } = useTopicTrendMomentById(momentId, brandProfileId)

  useEffect(() => {
    if (!momentsQuery.data) return

    setOpenPanel(true);
    setMoment(momentsQuery.data);
  }, [momentsQuery.data])

  const handleResultSelect = (result: ISearchResult, tab?: string) => {
    let path: string = PATH_TO_ONBOARDING_HUB
    let params = {}
    switch (type) {
      case 'topic':
        path = PATH_TOPIC_DETAILS + '/' + result.id
        break;
      case 'trend':
        path = PATH_TREND_DETAILS + '/' + result.id
        break;
      case 'moment':
          setOpenPanel(true)
          setMomentId(Number(result.id))
        break;
      case 'narrative':
        path = PATH_NARRATIVE_DETAILS + '/' + result.id
        params = {
          tab
        }
        break;
    }
    
    if (type !== 'moment') {
      navigate({ 
        to: path, 
        search: (prev) => ({
          ...prev,
          ...params
        }) 
      });
    }
  };

  const renderResults = (): React.ReactNode => {
    if (!results) return null;
  
    let selectedTypeResults: (ITopic | ITrend)[] = [];
  
    switch (type) {
      case 'topic':
        selectedTypeResults = results as ITopic[];
        break;
      case 'trend':
        selectedTypeResults = results as ITrend[];
        break;
      case 'moment':
        return (results as IMoment[]).map((result) => (
          <ResultMomentCard
            key={`search-moment-result-${result.id}`}
            moment={result}
            handleSelect={handleResultSelect}
          />
        ));
      case 'narrative':
        return (results as INarrative[]).map((result) => (
          <ResultSocialNarrativeCard
            key={`search-narrative-result-${result.id}`}
            narrative={result}
            handleSelect={handleResultSelect}
          />
        ));
    }
  
    return selectedTypeResults.map((result) => (
      <ResultCard
        key={`search-${type}-result-${result.id}`}
        result={result}
        handleSelect={handleResultSelect}
      />
    ));
  };
  

  if (isLoading) {
    return (
      <div className='flex flex-col w-full'>
        <LoadingPage message={`Fetching ${type}s`} />
        <LoadingItems />
      </div>
    )
  }

  if (!results) {
    return (
      <InfoPage message={`There was an unknown error fetching ${type}s. Please refresh the page to try again. If the problem persists, please contact support.`} />
    )
  }

  if (results.length === 0) {
    return (
      <InfoPage message={`There are no ${type}s for your filters`} />
    )
  } else {
    return (
      <div className="flex flex-col gap-4">
        { renderResults() }
        {
          totalItems > pageSize * page && (
            <div className="flex justify-center h-10">
              <SightlyButton
                datatestid="load-more-trend-result-button"
                block
                id="loadmorebutton"
                text="Load More"
                handleClick={() => handlePageChange(page + 1)}
                loading={isLoading}
              />
            </div>
          )
        }
        { openPanel && (
           type === 'moment' && moment ? (
            <MomentSlideOver
              show={openPanel}
              close={setOpenPanel}
              moment={moment}
              brandProfileId={brandProfileId}
              brandProfileSelectOptions={brandProfileSelectOptions}
            />
          ) : null
        )}
      </div>
    )
  };
};

export default ResultCardList;
